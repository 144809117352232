import React from "react";
import { connect } from 'react-redux';
import { Well, Panel } from "react-bootstrap";
import JSONTree from 'react-json-tree'

const Debugger = ({ userProfile, models, children }) => {
  const isDebugging = userProfile.IsDebugging | false

  const renderModels = () => {
    if (!models) return

    const modelObj = typeof models === "object" ? models : { model: models }
    console.log("Rendering debugging for models: ", modelObj)

    return Object.entries(modelObj).map(([key, value], index) => (
      <Panel key={index} bsStyle="info">
        <Panel.Heading>
          <Panel.Title componentClass="h3">{key}</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <JSONTree data={value} theme="default" />
        </Panel.Body>
      </Panel>
    ))
  }

  return !isDebugging ? null : (
    <Well>
      {renderModels()}
      {children}
    </Well>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    userProfile: state.userProfile,
  }
}

export default connect(
  mapStateToProps,
)(Debugger)