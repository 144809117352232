import React, { useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./Login.css";
import { Auth } from "aws-amplify";
import { loadSessionCacheFromAuthenticatedUser } from '../sessionCache'
import store from "../store";
import { setCognitoUsername, setUserProfile } from "../actions";
import { API } from "aws-amplify";
import logger from '../logger'
import ButtonWithTooltip from "../components/ButtonWithTooltip";
import queryString from 'query-string'

export default function Login(props) {
  console.debug("Rendering Login", props)
  const queryValues = queryString.parse(props.location.search)

  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: (queryValues && queryValues.email) || "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      await Auth.currentSession().then(user => {
        store.dispatch(setCognitoUsername(user.idToken.payload['email']))
      });
      loadSessionCacheFromAuthenticatedUser();
      props.userHasAuthenticated(true);

      API.get("apigw", "/profile").then(response => {
        console.info("Profile fetch response: ", response)
        store.dispatch(setUserProfile(response))
        // Send to the contact list (which refreshes data from the server)
        props.history.push("/studios");
      }).catch(error => {
        alert("Profile fetch failed. Please see the console.")
        logger.error("Profile fetch failed with API call error", error)
      })
    } catch (e) {
      alert(e.message);
    }
  }

  async function handleForgottenPassword() {
    console.log("Forgotten password for", fields.email)
    try {
      setIsLoading(true);
      await Auth.forgotPassword(fields.email);
      props.history.push("/reset");
    } catch (e) {
      console.error(e)
      alert(e.message)
    }
    setIsLoading(false);
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        {queryValues.from === "reset" ? <p>
          Your password has just been successfully reset. Please login with your new credentials.
        </p> : null}
        {queryValues.from === "signup" ? <p>
          You are now all signed up and ready to go! Please login with your new credentials.
        </p> : null}
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <div className="extras">
          <ButtonWithTooltip
            className="forgoten-password"
            bsStyle="link"
            disabled={!fields.email.length}
            onClick={handleForgottenPassword}
            tooltipCondition={!fields.email.length}
            tooltipMessage="You must enter an email address first."
          >
            I've forgotten my password!
          </ButtonWithTooltip>
        </div>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </form>
    </div>
  );
}
