import React from "react";
import Rating from 'react-rating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarFull } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import './RatingStars.css'

export default ({ value }) => {

  const stars = (icon) => {
    const star = (className) => {
      return <FontAwesomeIcon className={className} icon={icon} size="2x" />
    }

    return [star("low"), star("low"), star("medium"), star("high"), star("high")]
  }

  return (
    <span className="rating-stars">
      <Rating
        readonly
        initialRating={value}
        fractions={2}
        stop={5}
        placeholderSymbol={stars(faStarFull)}
        fullSymbol={stars(faStarFull)}
        emptySymbol={stars(faStarEmpty)}
      />
    </span>
  )
}
