import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import DataAppliedRoute from "./components/DataAppliedRoute";
import WeaverRoute from "./components/WeaverRoute";
import Home from "./containers/Home";
import PrivacyPolicy from "./containers/PrivacyPolicy"
import Signup from "./containers/Signup";
import SignupValidation from "./containers/SignupValidation";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import Profile from "./containers/Profile";
import StudioSelecter from "./containers/StudioSelecter"
import Contacts from "./containers/Contacts"
import Contact from "./containers/Contact"
import ProjectList from "./containers/ProjectList"
import Sourcing from "./containers/Sourcing"
import WeaverHome from "./containers/weaver/Home"
import WeaverProjects from "./containers/weaver/Projects"
import WeaverProjectSupply from "./containers/weaver/ProjectSupply"
import WeaverProjectAreaConverter from "./containers/weaver/ProjectAreaConverter"
import WeaverSyncSupplyProfessionals from "./containers/weaver/SyncSupplyProfessionals"
import WeaverSyncSupplyBudgetAddressAreas from "./containers/weaver/SyncSupplyBudgetAddressAreas"
import WeaverShowProfessionalProfile from "./containers/weaver/ShowProfessionalProfile"
import StandaloneProfessionalProfile from "./containers/standalone/StandaloneProfessionalProfile"
import NotFound from "./containers/NotFound";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <AppliedRoute path="/privacy" exact component={PrivacyPolicy} appProps={appProps} />
      <AppliedRoute path="/signup" exact component={Signup} appProps={appProps} />
      <AppliedRoute path="/signup/validate" exact component={SignupValidation} appProps={appProps} />
      <AppliedRoute path="/login" exact component={Login} appProps={appProps} />
      <AppliedRoute path="/reset" exact component={ResetPassword} appProps={appProps} />
      <AppliedRoute path="/profile" exact component={Profile} appProps={appProps} />
      <AppliedRoute path="/studios" exact component={StudioSelecter} appProps={appProps} />
      <AppliedRoute path="/contacts" exact component={Contacts} appProps={appProps} />
      <AppliedRoute path="/contact/:id" exact component={Contact} appProps={appProps} />
      <DataAppliedRoute path="/projects" exact component={ProjectList} appProps={appProps} />
      <DataAppliedRoute path="/sourcing/:id" exact component={Sourcing} appProps={appProps} />
      <WeaverRoute path="/weaver/" exact component={WeaverHome} appProps={appProps} />
      <WeaverRoute path="/weaver/projects" exact component={WeaverProjects} appProps={appProps} />
      <WeaverRoute path="/weaver/projects/:id/supply" exact component={WeaverProjectSupply} appProps={appProps} />
      <WeaverRoute path="/weaver/project-areas" exact component={WeaverProjectAreaConverter} appProps={appProps} />
      <WeaverRoute path="/weaver/sync-supply-professionals" exact component={WeaverSyncSupplyProfessionals} appProps={appProps} />
      <WeaverRoute path="/weaver/sync-supply-budget-address-areas" exact component={WeaverSyncSupplyBudgetAddressAreas} appProps={appProps} />
      <WeaverRoute path="/weaver/show-professional-profile" exact component={WeaverShowProfessionalProfile} appProps={appProps} />
      <AppliedRoute path="/_/pro/:id" exact component={StandaloneProfessionalProfile} appProps={{ ...appProps, full: false }} />
      <AppliedRoute path="/_/pro-full/:id" exact component={StandaloneProfessionalProfile} appProps={{ ...appProps, full: true }} />
      { /* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
