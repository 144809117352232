import * as Sentry from '@sentry/browser';

const logger = (() => {
  return {
    error: (title, error) => {
      console.error(title, error)
      Sentry.captureException(error)
    }
  }
})()

export default logger
