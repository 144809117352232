import React, { useEffect, useContext, useState } from "react";
import { connect } from 'react-redux';
import { AnalyticsContext } from '../services/Analytics';
import { withRouter } from 'react-router-dom';
import { PanelGroup, Panel, Glyphicon, Button, ButtonToolbar, Modal, FormGroup, ControlLabel, FormControl, ListGroup, ListGroupItem } from "react-bootstrap";
import { ProjectStageContext, RoleGroupContext, RoleContext, AddressAreaContext, BudgetRangeContext, StudioContext, ProjectContext, ProfessionalContext, SourcingContext, ProjectDescriptionContext } from "../model"
import { lookupContextById, lookupContextByIds } from "../model"
import { ProjectForm } from "../components/ProjectForm"
import Spinner from "../components/Spinner";
import Debugger from '../components/Debugger'
import "./ProjectList.css";
import ButtonWithTooltip from "../components/ButtonWithTooltip";

export const architectLeadGlyph = "asterisk"
export const architectOtherGlyph = "user"

// COMPONENT

const ArchitectListItem = ({ mappedUser, onClick }) => {
  const getBsStyle = (mappedUser) => {
    switch (mappedUser.state) {
      case "LEAD": return "success"
      case "OTHER": return "info"
      default: return "default"
    }
  }

  return (
    <ListGroupItem bsStyle={getBsStyle(mappedUser)}>
      <span className="text">
        <span className="name">{mappedUser.user.FirstName || ""} {mappedUser.user.LastName || ""}</span>
        <span className="role">{mappedUser.state === "LEAD" ? "Lead Architect" : null}{mappedUser.state === "OTHER" ? "Team Member" : null}</span>
      </span>
      <ButtonToolbar bsClass="btn-toolbar pull-right">
        <Button
          className="architectOtherButton pull-right"
          onClick={(event, thing) => {
            console.log("CLICKED LEAD BUTTON", event.target)
            onClick(mappedUser, "OTHER")
          }}
          active={mappedUser.state === "OTHER"}
        >
          <Glyphicon glyph={architectOtherGlyph} />
        </Button>
        <Button
          className="architectLeadButton pull-right"
          onClick={() => { onClick(mappedUser, "LEAD") }}
          active={mappedUser.state === "LEAD"}
        >
          <Glyphicon glyph={architectLeadGlyph} />
        </Button>
      </ButtonToolbar>
    </ListGroupItem>
  )
}

const ArchitectsEditor = ({ contextIds, close }) => {
  console.debug("Rendering ArchitectsEditor", contextIds, close)
  const isShowing = contextIds !== null && contextIds.projectId !== null
  const [isSaving, setIsSaving] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)

  const [architectList, setArchitectList] = useState([])
  // const onChange = (event) => {
  //   setFields({ ...fields, [event.target.id]: event.target.value })
  // }

  // Initialise when the contextIds change
  const projects = useContext(ProjectContext)
  const studio = useContext(StudioContext)
  useEffect(() => {
    // Reset to default if no contextIds passed, otherwise, load the fields from the projects data by ID
    if (!contextIds) {
      setArchitectList([])
      return
    }

    const project = projects.getById(contextIds.projectId)
    const mappedUsers = studio.data.users.map(user => {
      return {
        user: user,
        state: project.architectLead === user.id ? "LEAD" : project.architectOthers.includes(user.id) ? "OTHER" : null,
      }
    })
    setArchitectList(mappedUsers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextIds])

  const abort = () => {
    if (!hasChanged || window.confirm("You have unsaved changes which will be lost if you continue. Are you sure?")) close()
  }

  const onSave = (e) => {
    e.preventDefault()

    if (architectList.filter(each => each.state === "LEAD" || each.state === "OTHER").length === 0 &&
      !window.confirm("You can use the buttons on the right to build your architect team. Are you sure you want to continue without doing this?"))
      return

    setIsSaving(true)

    const project = projects.getById(contextIds.projectId)
    const fields = {
      ...project,
      architectLead: architectList.filter(each => each.state === "LEAD").map(each => each.user.id)[0],
      architectOthers: architectList.filter(each => each.state === "OTHER").map(each => each.user.id),
    }

    projects
      .updateProjectFields(fields)
      .then(setIsSaving(false))
      .then(close)
  }

  const onClick = (clickedUser, clickedState) => {
    setHasChanged(true)
    setArchitectList(architectList
      // Find the user changed and either toggle or set the state
      .map(each => {
        return each.user.id !== clickedUser.user.id ? each : {
          ...each,
          state: clickedUser.state === clickedState ? null : clickedState,
        }
      })
      // If I clicked on a lead toggle, make sure everyone but the clickedUser is not a lead (reset them if they are)
      .map(each => {
        return clickedState !== "LEAD" || each.user.id === clickedUser.user.id || each.state !== "LEAD" ? each : {
          ...each,
          state: null,
        }
      })
    )
  }

  return (
    <Modal show={isShowing} onHide={abort}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Architects: {contextIds ? contextIds.projectId : null}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup bsClass="architectListGroup">
          {architectList.map(mappedUser => <ArchitectListItem mappedUser={mappedUser} onClick={onClick} />)}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSave} bsStyle="success" disabled={isSaving}>Save</Button>
        <Button onClick={abort} bsStyle="link" disabled={isSaving}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

// COMPONENT

const TeamMemberCreator = ({ contextIds, close }) => {
  console.debug("Rendering TeamMemberCreator", contextIds, close)
  const isShowing = contextIds !== null && contextIds.projectId !== null
  const [isSaving, setIsSaving] = useState(false)

  const [roleId, setRoleId] = useState("")

  // Initialise when the contextIds change
  const projects = useContext(ProjectContext)
  const roles = useContext(RoleContext)
  const roleList = contextIds ? roles.data.filter(each => each.roleGroupId === contextIds.roleGroupId) : []

  useEffect(() => {
    setRoleId(roleList && roleList[0] ? roleList[0].id : "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextIds])

  const onChange = (e) => {
    setRoleId(e.target.value)
  }

  const onSave = (e) => {
    e.preventDefault()
    setIsSaving(true)

    const newMember = {
      id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
      roleId: roleId,
      sourcingId: null,
      professionalId: null,
    }
    projects
      .addTeamMember(contextIds.projectId, newMember)
      .then(setIsSaving(false))
      .then(close)
  }

  return (
    <Modal show={isShowing} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Add Role to Team {contextIds ? contextIds.roleGroupId : null}: {contextIds ? contextIds.projectId : null}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSave}>
          <FormGroup controlId="ref" bsSize="large">
            <ControlLabel>Reference</ControlLabel>
            <FormControl controlId="role" componentClass="select" value={roleId} onChange={onChange}>
              {roleList.map(each => <option value={each.id}>{each.name}</option>)}
            </FormControl>
          </FormGroup>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSave} bsStyle="success" disabled={isSaving}>Save</Button>
        <Button onClick={close} bsStyle="link" disabled={isSaving}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

// COMPONENT

const Field = ({ title, titleExtra, value }) => {
  console.debug("Rendering Field", title, titleExtra, value)

  return (
    <div className="field">
      <span className="title">{title}{titleExtra ? ` ${titleExtra}` : ""}:</span><span className="value">{value}</span>
    </div>
  )
}

// COMPONENT

const FieldList = ({ title, list, componentClass: C, onClickCard, onClickEdit, glyph }) => {
  console.debug("Rendering FieldList", title, list, C)

  return (
    <div className="field">
      <div className="title fieldListTitle">{title}: <Button className="editButton pull-right" onClick={onClickEdit}>{glyph}</Button></div>
      <div className="fieldListContainer">
        <div className="fieldList">
          {Array.isArray(list) && list.length ? list.map((each, i) => <C key={i} value={each} onClick={onClickCard} />) : <C />}
        </div>
      </div>
    </div>
  )
}

// COMPONENT

const CardArchitect = ({ value }) => {
  console.debug("Rendering CardArchitect", value)

  return (
    <div className={`card cardArchitect${value ? ` ${value.state}` : " empty"}`}>
      {value ? <>
        <Glyphicon glyph={value.state === "LEAD" ? architectLeadGlyph : architectOtherGlyph} />
        <span className="name">{value.fullName}</span>
      </> : "No Architects"}
    </div>
  )
}

// COMPONENT

const CardTeamMember = withRouter(({ value, onClick, history }) => {
  console.debug("Rendering CardTeamMember", value)
  if (!value) return (
    <div className="card cardRole empty">
      <div className="noTeamMembers">No Team Members</div>
    </div>
  )

  const { roleGroup, role, member, professional } = value

  const analytics = useContext(AnalyticsContext)
  const sourcing = useContext(SourcingContext)
  const onClickCardBody = () => {
    // Do I already have a sourcing ID?
    if (member.sourcingId) {
      history.push(`/sourcing/${member.sourcingId}`)
      return
    }

    // Do I already have a professional?
    if (professional) return

    // This is vacant then - so let's create a sourcing, and try again
    if (analytics && analytics.event) analytics.event("Project List", "Create Team Member Sourcing")
    sourcing.createForTeamMember(member)
      .then(({ member, sourcing }) => {
        console.log("Sourcing created, redirecting", member, sourcing)
        history.push(`/sourcing/${member.sourcingId}`)
      })
  }

  const projects = useContext(ProjectContext)
  const status = projects.getTeamMemberStatus(member.id)
  const memberStatusClassName = (() => {
    switch (status) {
      case "VACANT": return "sourcing-vacant"
      case "UNDERWAY": return "sourcing-underway"
      case "COMPLETE": return "sourcing-complete"
      default: return "sourcing-vacant"
    }
  })()

  return (
    <div className={`card cardRole ${memberStatusClassName}`} onClick={onClickCardBody}>
      {/* This is a massive hack! Putting an invisible button on the left so the text of the field is still balanced in the center! */}
      <Button bsClass="pull-left invisible">
        <Glyphicon glyph="remove" />
      </Button>
      <Button
        onClick={!onClick ? null : (event, data) => { onClick(event, { ...data, member, role, roleGroup, professional }) }}
        bsClass="pull-right"
      >
        <Glyphicon glyph="remove" />
      </Button>
      <div className="field">
        <span className="title">{role.name}</span>
      </div>
      {status !== "VACANT" ? null : <div className="field">Vacant</div>}
      {status !== "UNDERWAY" ? null : <div className="field">Sourcing Underway</div>}
      {status !== "COMPLETE" ? null : !professional ? <div className="field">Completed with Unknown Professional</div> :
        <>
          <div className="field">{professional.company}</div>
          {roleGroup.showProfessionalName ?
            <div className="field">{professional.firstName} {professional.lastName}</div>
            : null
          }
        </>
      }
    </div>
  )
})

// COMPONENT

const ConfirmDeleteTeamMember = ({ member, close }) => {
  const projects = useContext(ProjectContext)
  const memberStatus = projects.getTeamMemberStatus(member && member.id)

  return (
    <Modal show={member !== null} onHide={() => close(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {memberStatus === "UNDERWAY" ? <p>You are currently sourcing a professional for this role.</p> : null}
        {memberStatus === "COMPLETE" ? <p>You have already selected a professional for this role.</p> : null}
        <p>Are you sure you want to remove this role from your team?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => close(true)} bsStyle="success">Archive</Button>
        <Button onClick={() => close(false)} bsStyle="link">Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

// COMPONENT

const Project = ({ project }) => {
  console.debug("Rendering Project", project)

  const analytics = useContext(AnalyticsContext)
  const roleGroups = useContext(RoleGroupContext)
  const roles = useContext(RoleContext)
  const studio = useContext(StudioContext)
  const professionals = useContext(ProfessionalContext)
  const addressAreas = useContext(AddressAreaContext)
  const budgetRanges = useContext(BudgetRangeContext)
  const projectDescriptions = useContext(ProjectDescriptionContext)

  // Create projectTeam which breaks down into the roleGroups (empty list if none)
  const projectTeam = roleGroups.data.reduce((result, roleGroup) => {
    const rolesInGroup = roles.data.filter(role => role.roleGroupId === roleGroup.id)
    const membersInGroup = project.team.filter(member => rolesInGroup.map(e => e.id).includes(member.roleId))
    return {
      ...result,
      [roleGroup.id]: {
        roleGroup: roleGroup,
        rolesInGroup: rolesInGroup,
        members: membersInGroup,
        teamMemberCards: membersInGroup.map(member => {
          return {
            member: member,
            roleGroup: roleGroup,
            role: roles.data.filter(role => member.roleId === role.id)[0],
            // TODO: lookup weaverProfessionalId accordingly
            professional: member.studioProfessionalId ? professionals.data.filter(professional => member.studioProfessionalId === professional.id)[0] :
              member.weaverProfessionalId ? professionals.data.filter(professional => member.weaverProfessionalId === professional.id)[0] : null,
          }
        }),
      }
    }
  }, {})

  // Modal State Models
  const [modalEditProjectId, setModalEditProjectId] = useState(null)
  const [modalEditArchitectsId, setModalEditArchitectsId] = useState(null)
  const [modalAddTeamMemberId, setModalAddTeamMemberId] = useState(null)
  const modalEditClose = setModelEdit => () => setModelEdit(null)
  const modalEditProjectClose = modalEditClose(setModalEditProjectId)
  const modalEditArtchitectsClose = modalEditClose(setModalEditArchitectsId)
  const modalAddTeamMemberClose = modalEditClose(setModalAddTeamMemberId)

  const [teamMemberToDelete, setTeamMemberToDelete] = useState(null)

  // Support removing project team members
  const projects = useContext(ProjectContext)
  const onClickTeamDelete = (event, { member }) => {
    event.stopPropagation()
    if (analytics && analytics.event) analytics.event("Project List", "Remove Team Member")
    // If we have selected a professional, let's make sure!
    const memberStatus = projects.getTeamMemberStatus(member.id)
    if (memberStatus === "UNDERWAY" || memberStatus === "COMPLETE") {
      setTeamMemberToDelete(member)
    } else {
      projects.removeTeamMember(project.id, member.id)
    }
  }
  const onClickTeamDeleteConfirm = confirmed => {
    if (confirmed) {
      if (analytics && analytics.event) analytics.event("Project List", "Confirm Remove Team Member")
      projects.removeTeamMember(project.id, teamMemberToDelete.id)
    }
    setTeamMemberToDelete(null)
  }
  const onClickEditProject = () => {
    console.info("EDIT PROJECT", project)
    if (analytics && analytics.event) analytics.event("Project List", "Edit Project Dialog")
    // Show the modal with this project data
    setModalEditProjectId({
      projectId: project ? project.id : null,
    })
  }
  const onClickEditArchitects = () => {
    console.info("EDIT ARCHITECTS", project)
    if (analytics && analytics.event) analytics.event("Project List", "Edit Architects Dialog")
    // Show the modal with this project data
    setModalEditArchitectsId({
      projectId: project ? project.id : null,
    })
  }
  const onClickAddTeamMember = team => () => {
    console.info(`ADD TEAM MEMBER`, team, project)
    if (analytics && analytics.event) analytics.event("Project List", "Add Team Member Dialog")

    setModalAddTeamMemberId({
      projectId: project ? project.id : null,
      roleGroupId: team && team.roleGroup ? team.roleGroup.id : null,
    })
  }

  const architectCards = [
    !project.architectLead ? null : {
      architectId: project.architectLead,
      state: "LEAD",
      fullName: studio.getFullNameByUserId(project.architectLead),
    },
    ...project.architectOthers.map(eachArchitect => ({
      architectId: eachArchitect,
      state: "OTHER",
      fullName: studio.getFullNameByUserId(eachArchitect),
    })),
  ]

  return (
    <>
      <ConfirmDeleteTeamMember member={teamMemberToDelete} close={onClickTeamDeleteConfirm} />
      <ProjectForm projectId={modalEditProjectId && modalEditProjectId.projectId} isShowing={!!(modalEditProjectId && modalEditProjectId.projectId)} close={modalEditProjectClose} />
      <ArchitectsEditor contextIds={modalEditArchitectsId} close={modalEditArtchitectsClose} />
      <TeamMemberCreator contextIds={modalAddTeamMemberId} close={modalAddTeamMemberClose} />
      <Panel bsStyle="default" eventKey={project.id}>
        <Debugger models={{ models: { project, architectCards, professionals, projectDescriptions } }} />
        <div className="project">
          <Panel.Body>
            <div className="fields row">
              <div className="col left">
                <Field title="Project" titleExtra={project.ref} value={project.name} />
                <Field title="Project Description" value={lookupContextByIds(projectDescriptions, project.description).map(each => each.name || "Unknown").join(", ")} />
                <Field title="Budget" value={`£${typeof project.budget === "number" ? project.budget.toLocaleString() : project.budget}`} />
                <Field title="Budget Range" value={lookupContextById(budgetRanges, project.budgetRangeId).name || "Unknown"} />
              </div>
              <div className="col right">
                <Field title="Address" value={project.address ? project.address.replace(/\n/g, ", ") : ""} />
                <Field title="Postcode" value={project.addressPostcode} />
                <Field title="Geographic Area" value={lookupContextById(addressAreas, project.addressAreaId).name || "Unknown"} />
              </div>
              <Button className="editButton pull-right" onClick={() => onClickEditProject(project)}><Glyphicon glyph="pencil" /></Button>
            </div>
            <div className="fields row buttons">
              <Panel.Toggle componentClass="button"><Glyphicon glyph="chevron-down" /></Panel.Toggle>
            </div>
          </Panel.Body>
          <Panel.Body collapsible>
            <div className="architects">
              <FieldList title="Architects" list={architectCards} componentClass={CardArchitect} onClickEdit={onClickEditArchitects} glyph={<Glyphicon glyph="pencil" />} />
            </div>
          </Panel.Body>
          {Object.values(projectTeam).map(team =>
            <Panel.Body key={team.roleGroup.id} collapsible>
              <div className="team">
                <FieldList title={`Team ${team.roleGroup.name}`} list={team.teamMemberCards} componentClass={CardTeamMember} onClickCard={onClickTeamDelete} onClickEdit={onClickAddTeamMember(team)} glyph={<Glyphicon glyph="plus" />} />
              </div>
            </Panel.Body>
          )}
        </div>
      </Panel>
    </>
  )
}

// COMPONENT

const ProjectStage = ({ projectStage, projects }) => {
  console.debug("Rendering Project Stage", projectStage, projects)

  const projectStageProjects = projects.data.filter(p => p.projectStageId === projectStage.id)
  if (!projectStageProjects.length) return null

  return (
    <>
      <h3 className="projectStage">Project Stage {projectStage.number}: {projectStage.title}</h3>
      <PanelGroup accordian="accordian" id="project-accordian" defaultActiveKey={projects && projects.length ? projects[0].id : null}>
        {projectStageProjects.map(project => <Project key={project.id} project={project} />)}
      </PanelGroup>
    </>
  )
}

// COMPONENT

const ProjectList = () => {
  console.debug("Rendering ProjectList")

  const analytics = useContext(AnalyticsContext)
  const projectStages = useContext(ProjectStageContext)
  const projects = useContext(ProjectContext)

  useEffect(() => {
    console.info("Project Stages", projectStages)
    console.info("Projects", projects)
  })

  const [modalCreateProject, setModalCreateProject] = useState(false)
  const onClickNewProject = () => {
    if (analytics && analytics.event) analytics.event("Project List", "New Project")
    setModalCreateProject(true)
  }

  return (
    <>
      {projects.isLoading && <Spinner />}
      {!projects.isLoading && !projects.data.length &&
        (<>
          <Panel bsStyle="info">
            <Panel.Heading>
              <Panel.Title componentClass="h3">Get started now!</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              You appear to have no projects! To get started, click on the <Button className="inline-text-link" bsStyle="link" onClick={onClickNewProject}>New Project</Button> button and enter a short project summary, then start building your project team!
          </Panel.Body>
          </Panel>
        </>)}
      {!projects.isLoading && projects && projects.data.length === 1 &&
        (<>
          <Panel bsStyle="info">
            <Panel.Heading>
              <Panel.Title componentClass="h3">Why not add your other live projects?</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              You appear have only one project! You can add more by clicking on the <Button className="inline-text-link" bsStyle="link" onClick={onClickNewProject}>New Project</Button> button and enter a short project summary, then start building your project team for more of your projects.
          </Panel.Body>
          </Panel>
        </>)}
      <ProjectForm isShowing={modalCreateProject} close={() => setModalCreateProject(false)} />
      <ButtonToolbar bsClass="btn-toolbar pull-right">
        <Button onClick={onClickNewProject} bsStyle="success">New Project</Button>
      </ButtonToolbar>
      {projectStages.data.map(projectStage => <ProjectStage key={projectStage.id} projectStage={projectStage} projects={projects} />)}
      {projects && projects.data.length ?
        <ButtonToolbar bsClass="btn-toolbar pull-right">
          <Button onClick={onClickNewProject} bsStyle="success">New Project</Button>
        </ButtonToolbar>
        : null}
    </>
  )
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectList)