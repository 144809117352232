import React, { useState, useContext } from "react";
import { Panel } from "react-bootstrap";
import { ProfessionalContext, DataModelRedux } from "../../model"
import { FullProfessionalProfile } from "../SourcingProfessionalProfile"
import Debugger from "../../components/Debugger";
import Select from 'react-select';

const ShowProfessionalProfile = () => {
  console.log("Rendering ShowProfessionalProfile")
  const [professional, setProfessional] = useState({});
  const professionals = useContext(ProfessionalContext)

  return (
    <DataModelRedux>
      <Panel>
        <Panel.Heading>
          <Panel.Title>Show Professional Profile</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Debugger models={{ models: { professional, professionals, len: professionals.data.length } }} />
          <b>Select the Weaver Professional you want to see the profile for:</b>
          <Select
            options={professionals.data.filter(each => each.system === "WEAVER").map(each => ({ label: `${each.company} - ${each.id}`, value: each }))}
            onChange={(change, action) => {
              console.debug(`Select change: `, change, action)
              setProfessional(change.value)
            }}
          />
        </Panel.Body>
      </Panel>
      {professional && professional.id ? <FullProfessionalProfile professional={professional} isShowing={(typeof professional.id === "string")} close={() => setProfessional({})}/> : null}
    </DataModelRedux>
  )
}

const DataModelReduxWrapper = () => {
  console.log("Rendering DataModelReduxWrapper")
  return (
    <DataModelRedux>
      <ShowProfessionalProfile />
    </DataModelRedux>
  )
}
export default DataModelReduxWrapper
