export const SET_COGNITO_USERNAME = 'SET_COGNITO_USERNAME'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const SET_USER_STUDIOS = 'SET_USER_STUDIOS'
export const SET_CURRENT_STUDIO = 'SET_CURRENT_STUDIO'
export const SET_CONTACTS = 'SET_CONTACTS'
export const SET_CONTACT_FORM = 'SET_CONTACT_FORM'
export const UPDATE_CONTACT_FORM = 'UPDATE_CONTACT_FORM'

export const setCognitoUsername = username => ({
  type: SET_COGNITO_USERNAME,
  username
})

export const setUserProfile = userProfile => ({
  type: SET_USER_PROFILE,
  userProfile
})

export const setUserStudios = userStudios => ({
  type: SET_USER_STUDIOS,
  userStudios
})

export const setCurrentStudio = selectedStudio => ({
  type: SET_CURRENT_STUDIO,
  selectedStudio
})

export const setContacts = contacts => ({
  type: SET_CONTACTS,
  contacts
})

export const setContactForm = contactForm => ({
  type: SET_CONTACT_FORM,
  contactForm
})

export const updateContactForm = change => ({
  type: UPDATE_CONTACT_FORM,
  change
})


// Weaver Tools

export const SET_WEAVERTOOLS_PROJECTS = 'SET_WEAVERTOOLS_PROJECTS'

export const setWeaverToolsProjects = projects => ({
  type: SET_WEAVERTOOLS_PROJECTS,
  projects
})
