import React, { useState, useContext } from "react";
import { Modal, FormGroup, ControlLabel, FormControl, Button } from "react-bootstrap";
import Debugger from "./Debugger";
import { ProfessionalContext, SourcingContext, SourcingInviteContext } from "../model";
import { ThisSourcingContext } from "../containers/Sourcing";
import { AnalyticsContext } from "../services/Analytics";

const QuickAddProfessional = ({ isShowing, close, role }) => {
  console.debug("Rendering QuickAddProfessional", isShowing, close, role)
  const [isSaving, setIsSaving] = useState(false)
  const professionals = useContext(ProfessionalContext)
  const thisSourcing = useContext(ThisSourcingContext)
  const sourcing = useContext(SourcingContext)
  const sourcingInvites = useContext(SourcingInviteContext)
  const analytics = useContext(AnalyticsContext)

  const defaultFields = {
    rolesId: [role && role.id],
  }
  const [fields, setFields] = useState(defaultFields)
  const setFieldValue = (name, value) => setFields({ ...fields, [name]: value })
  const onFieldChange = event => setFieldValue(event.target.id, event.target.value)

  const save = () => {
    console.log("Save professional", fields)
    setIsSaving(true)
    if (analytics && analytics.event) analytics.event('Sourcing', 'Quick Add Professional')

    professionals.quickCreateInStudio(fields)
      .then(savedProfessional => {
        return sourcing.addInvites(thisSourcing.sourcingId, [{ professional: savedProfessional }])
      })
      .then(async savedInvites => {
        // This works, however the vacant tender seat isnt filled with the favourite until you refresh
        // I think this is because the sourcingInvites context hasn't updated yet and I dont know how to get the latest
        // We're effectively writing over the old changes - I don't know how to defer this until it's updated with this model
        const promises = savedInvites.map(invite => sourcingInvites.toggleInvitePreferredByObject(invite))
        await Promise.all(promises)
        // Because of the above, we're just going to go ahead and download all the invite data from the server again
        sourcingInvites.reload()
      })
      .then(() => {
        setFields(defaultFields)
        setIsSaving(false)
        close()
      })
  }

  return isShowing && (<Modal show={isShowing} onHide={close} dialogClassName="quick-add-professional-modal">
    <Modal.Header closeButton>
      <Modal.Title>Add a Professional to your Studio</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Debugger models={{ info: { isShowing, close, role, fields } }} />
      <p>Fill out the form and we'll create a skeleton Professional in your studio, then add them to the shortlist as a favourite Professional.</p>
      <form onSubmit={save}>
        <FormGroup controlId="company" bsSize="large">
          <ControlLabel>Company Name</ControlLabel>
          <FormControl autoFocus type="text" placeholder="Enter the company name" value={fields.company || ''} onChange={onFieldChange} disabled={isSaving} />
        </FormGroup>
        <FormGroup controlId="rolesId" bsSize="large">
          <ControlLabel>Roles</ControlLabel>
          <FormControl type="text" value={role && role.name} disabled />
        </FormGroup>
      </form>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => save()} bsStyle="success">Create &amp; Shortlist &amp; Favourite</Button>
      <Button onClick={() => close()} bsStyle="link">Cancel</Button>
    </Modal.Footer>
  </Modal>)
}
export default QuickAddProfessional
