import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Debugger from "../../components/Debugger";
import { API } from "aws-amplify";
import WeaverProjectSupplyOverview from "../../components/WeaverProjectSupplyOverview";
import WeaverProjectSupplyList from "../../components/WeaverProjectSupplyList";

const ProjectSupply = ({ cognitoUsername, weaverToolsProject }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [supply, setSupply] = useState(null);
  const [supplyFormula, setSupplyFormula] = useState(null);

  useEffect(() => {
    console.log("Using effect")

    function loadSupply() {
      console.log("Loading the supply for project: ", weaverToolsProject)
      return API.get("apigw", `/weavertools/projects/${weaverToolsProject.id}/supply`);
    }

    async function onLoad() {
    console.log("On load...")
      try {
        const supply = await loadSupply()
        console.log("Fetched Supply: ", supply)

        setSupplyFormula(supply.formula)

        // Take the array of supply returned from the server and mutate it as necessary
        const mutated = supply.supply.map((supply, i) => {
          const _firstName = supply.FirstName ? supply.FirstName : ""
          const _lastName = supply.LastName ? supply.LastName : ""

          return {
              ...supply,
              LondonAreas: supply.LondonAreas ? supply.LondonAreas : [],
              NationwideAreas: supply.NationwideAreas ? supply.NationwideAreas : [],
              _Name: _firstName && _lastName ? `${_firstName} ${_lastName}` : `${_firstName}${_lastName}`,
            }
          })
          // Convert the [supply1, supply2] into { [supply1.id]: supply1, [supply2.id]: supply2, ... }
          .reduce((acc, supply) => {
            return { ...acc, [supply.id]: supply }
          }, {})

        setSupply(mutated);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUsername, weaverToolsProject.id]);



  return (
    <div className="ProjectSupply">
      <Debugger models={{ weaverToolsProject, supplyFormula, supply }} />
      <WeaverProjectSupplyOverview project={weaverToolsProject}/>
      { !isLoading && (<WeaverProjectSupplyList project={weaverToolsProject} supply={supply}/>) }
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  console.debug("mapStateToProps for Project Supply - url param id: ", ownProps.match.params.id, state.weaverToolsProjects[ownProps.match.params.id], state)

  return {
    weaverToolsProject: state.weaverToolsProjects[ownProps.match.params.id] ? state.weaverToolsProjects[ownProps.match.params.id] : {},
    weaverToolsProjects: state.weaverToolsProjects,
  } 
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSupply)
