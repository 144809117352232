import React, { useState } from "react";
import { connect } from 'react-redux';
import { PageHeader, Badge, ButtonToolbar, Button } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { API } from "aws-amplify";
import './WeaverProjectSupplyList.css'

const SupplyList = ({ project, supply }) => {
  const [table, setTable] = useState(null);

  console.log("SupplyList for ", project, supply)

  const listOfBadges = highlightedValue => (cell, row) => {
    return cell.map(area => (<Badge key={area} bsClass={area === highlightedValue ? "badge badge-success" : "badge"}>{area}</Badge>))
  }

  const columns = [
    {
      text: 'Company',
      dataField: 'Company',
      headerClasses: 'header-class-company',
    },
    {
      text: 'Name',
      dataField: '_Name',
      headerClasses: 'header-class-name',
    },
    {
      text: 'Last Lead',
      dataField: 'LastLead',
      headerClasses: 'header-class-lead-last',
    },
    {
      text: 'Lead vs Tender Ratio',
      dataField: 'LeadsVsTenderRatio',
      headerClasses: 'header-class-lead-ratio',
      formatter: (cell, row) => {
        return Math.round(cell * 100) + "%"
      },
    },
    {
      text: 'Vetting Funnel',
      dataField: 'VettingFunnel',
      headerClasses: 'header-class-vetting',
    },
    {
      text: 'Budget Range',
      dataField: 'BudgetRange',
      headerClasses: 'header-class-budget',
      formatter: listOfBadges(project.BudgetRange),
    },
    {
      text: 'London Areas',
      dataField: 'LondonAreas',
      headerClasses: 'header-class-london-areas',
      formatter: listOfBadges(project.LondonArea),
    },
    {
      text: 'Nationwide Areas',
      dataField: 'NationwideAreas',
      headerClasses: 'header-class-nationwide-areas',
      formatter: listOfBadges(project.NationwideArea),
    },
  ]

  const selectRow = {
    mode: 'checkbox', // multiple row selection
    selected: Object.values(supply).slice(0, 3 * project.NumberOfWeaverBuildersTendering).map(each => each.id),
  }

  const sendLeads = () => {
    console.log("Sending leads: ", table.selectionContext.selected)
    API.put("apigw", `/weavertools/projects/${project.id}/supply`, {
      body: table.selectionContext.selected,
    })
      .then(result => {
        console.log("Leads sent result: ", result)
      })
  }

  return (
    <>
      <PageHeader>Supply List</PageHeader>
      <ButtonToolbar bsClass="btn-toolbar pull-left">
        <Button onClick={sendLeads} bsStyle="success">Send Leads</Button>
      </ButtonToolbar>
      <BootstrapTable ref={setTable} striped hover keyField="id" columns={columns} data={Object.values(supply)} selectRow={selectRow} headerClasses="header-class" />
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

export default connect(
  mapStateToProps,
)(SupplyList)
