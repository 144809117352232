import React, { useContext } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { loadContacts } from "./Contacts";
import { setContacts } from "../actions";
import { Image, Glyphicon, Button, Grid, Row, Col, Modal, Label } from "react-bootstrap";
import { StudioContext, SourcingInviteContext, ProfessionalTagContext, AddressAreaContext, BudgetRangeContext } from "../model"
import Debugger from "../components/Debugger";
import RatingStars from "../components/RatingStars";
import TimeAgo from 'react-timeago'
import './SourcingProfessionalProfile.css'
import { ThisSourcingContext } from "./Sourcing";
import { StandaloneContext } from "./standalone/StandaloneProfessionalProfile";
import { AnalyticsContext } from "../services/Analytics";

const asMoney = value => `£${typeof value === "number" ? value.toLocaleString() : value}`
const asDate = value => typeof value === "object" ? value.toLocaleDateString() : value

const StudioProfessionalProfile = connect(
  state => ({ currentStudio: state.currentStudio }),
  dispatch => ({ setContacts: (model) => dispatch(setContacts(model)) })
)(withRouter(({ invite, professional, history, currentStudio, setContacts }) => {
  console.debug("Rendering StudioProfessionalProfile")

  const professionalTags = useContext(ProfessionalTagContext)
  const addressAreas = useContext(AddressAreaContext)
  const budgetRanges = useContext(BudgetRangeContext)
  const studio = useContext(StudioContext)
  const studioOwnerName = studio.getFullNameByUserId(professional.ownerArchitect)

  const onClickEdit = async () => {
    await loadContacts({ currentStudio, setContacts })
    history.push(`/contact/${professional.id}`)
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="titleEdit"><Button className="editButton" onClick={onClickEdit}><Glyphicon glyph="pencil" /></Button></span>
          <span className="titleName">{professional.company}</span>
          <span className="titleRating pull-right">Average Rating: <RatingStars value={professional.averageRating} /></span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Debugger models={{ info: { invite, professional } }} />
        <Grid>
          <Row className="summary">
            <Col md={9}>
              <Row>
                <Col md={9}>{professional.tagsId && professional.tagsId.map(each => <><Label key={each}>{professionalTags.getNameById && professionalTags.getNameById(each) || "Unknown"}</Label> </>)}</Col>
              </Row>
              <Row>
                <Col md={3}><b>Time as Studio Pro:</b></Col><Col md={6}><TimeAgo date={professional.dateAdded} /></Col>
              </Row>
              <Row>
                <Col md={3}><b># Studio Tenders:</b></Col><Col md={6}>{professional.tenderCount}</Col>
              </Row>
              <Row>
                <Col md={3}><b>Company Established:</b></Col><Col md={6}>{professional.companyEstablishedYear || "Unknown"}</Col>
              </Row>
              <Row>
                <Col md={3}><b>Insurance:</b></Col>
                <Col md={6}>
                  <Row><Col md={5}><b>Public Liability:</b> </Col><Col md={4}>{asMoney(professional.insurancePublicLiabilityLimit) || "Unknown"}</Col></Row>
                  <Row><Col md={5}><b>Employer Liability:</b> </Col><Col md={4}>{asMoney(professional.insuranceEmployerLiabilityLimit) || "Unknown"}</Col></Row>
                  <Row><Col md={5}><b>Renewal Date:</b> </Col><Col md={4}>{asDate(professional.insuranceRenewalDate) || "Unknown"}</Col></Row>
                </Col>
              </Row>
              <Row>
                <Col md={3}><b>Vetting:</b></Col>
                <Col md={6}>
                  <Row><Col md={9}><Glyphicon glyph={professional.vettingCompanyDirectorBankruptcy ? "check" : "unchecked"} /> Company Director Bankruptcy</Col></Row>
                  <Row><Col md={9}><Glyphicon glyph={professional.vettingCompanyDebt ? "check" : "unchecked"} /> Company Debt</Col></Row>
                </Col>
              </Row>
            </Col>
            <Col md={3} className="contact">
              <div className="field">
                <div className="fieldName">Contact</div>
                <div className="fieldValue">{professional.firstName} {professional.lastName}</div>
              </div>
              <div className="field">
                <div className="fieldName">Website</div>
                <div className="fieldValue"><a href={professional.website} target="_blank" rel="noopener noreferrer">{professional.website}</a></div>
              </div>
              <div className="field">
                <div className="fieldName">Studio Owner</div>
                <div className="fieldValue">{studioOwnerName}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h3>Notes</h3>
              {professional.notes ? <pre>{professional.notes}</pre> : <pre><em>No notes found.</em></pre>}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3>Ratings</h3>
              <Row><Col md={3}>On Schedule:</Col><Col md={4}><RatingStars value={professional.timingRating} /></Col></Row>
              <Row><Col md={3}>Within Budget:</Col><Col md={4}><RatingStars value={professional.budgetRating} /></Col></Row>
              <Row><Col md={3}>Communication:</Col><Col md={4}><RatingStars value={professional.commsRating} /></Col></Row>
              <Row><Col md={3}>Project Management:</Col><Col md={4}><RatingStars value={professional.projectManagementRating} /></Col></Row>
            </Col>
            <Col md={6}>
              <h3>Geographic Areas</h3>
              {(professional.addressAreasId && professional.addressAreasId.map(each => <><Label key={each}>{addressAreas.getNameById && addressAreas.getNameById(each) || "Unknown"}</Label> </>)) || <p><em>None</em></p>}
              <h3>Budget Ranges</h3>
              {(professional.budgetRangesId && professional.budgetRangesId.map(each => <><Label key={each}>{budgetRanges.getNameById && budgetRanges.getNameById(each) || "Unknown"}</Label> </>)) || <p><em>None</em></p>}
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
    </>
  )
}))

const WeaverOverviewProfessionalProfile = ({ invite, professional }) => {
  console.debug("Rendering WeaverOverviewProfessionalProfile")

  const professionalTags = useContext(ProfessionalTagContext)
  const addressAreas = useContext(AddressAreaContext)
  const budgetRanges = useContext(BudgetRangeContext)
  const standalone = useContext(StandaloneContext)

  const getNameById = (id, primaryModel, secondaryModel) =>
    primaryModel.getNameById ? primaryModel.getNameById(id) :
      secondaryModel.getNameById ? secondaryModel.getNameById(id) : null
  const lookupProfessionalTag = id => getNameById(id, professionalTags, standalone.professionalTags)
  const lookupAddressArea = id => getNameById(id, addressAreas, standalone.addressAreas)
  const lookupBudgetRange = id => getNameById(id, budgetRanges, standalone.budgetRanges)

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="titleLogo"><img className="logo" alt="" src="/logo-cropped.png" /></span>
          <span className="titleName">Pro #: {professional.id}</span>
          <span className="titleRating pull-right">Average Rating: <RatingStars value={professional.averageRating} /></span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Debugger models={{ info: { invite, professional } }} />
        <Grid>
          <Row className="summary">
            <Col md={12}>
              <Row>
                <Col md={12}>{professional.tagsId && professional.tagsId.map((each, i) => <><Label key={i}>{lookupProfessionalTag(each) || "Unknown"}</Label> </>)}</Col>
              </Row>
              <Row>
                <Col md={2}><b>Time as Weaver Pro:</b></Col><Col md={10}><TimeAgo date={professional.dateAdded} /></Col>
              </Row>
              <Row>
                <Col md={2}><b># Weaver Tenders:</b></Col><Col md={10}>{professional.tenderCount}</Col>
              </Row>
              <Row>
                <Col md={2}><b>Company Established:</b></Col><Col md={6}>{professional.companyEstablishedYear || "Unknown"}</Col>
              </Row>
              <Row>
                <Col md={2}><b>Insurance:</b></Col>
                <Col md={6}>
                  <Row><Col md={5}><b>Public Liability:</b> </Col><Col md={4}>{asMoney(professional.insurancePublicLiabilityLimit) || "Unknown"}</Col></Row>
                  <Row><Col md={5}><b>Employer Liability:</b> </Col><Col md={4}>{asMoney(professional.insuranceEmployerLiabilityLimit) || "Unknown"}</Col></Row>
                  <Row><Col md={5}><b>Renewal Date:</b> </Col><Col md={4}>{asDate(professional.insuranceRenewalDate) || "Unknown"}</Col></Row>
                </Col>
              </Row>
              <Row>
                <Col md={2}><b>Vetting:</b></Col>
                <Col md={6}>
                  <Row><Col md={9}><Glyphicon glyph={professional.vettingCompanyDirectorBankruptcy ? "check" : "unchecked"} /> Company Director Bankruptcy</Col></Row>
                  <Row><Col md={9}><Glyphicon glyph={professional.vettingCompanyDebt ? "check" : "unchecked"} /> Company Debt</Col></Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h3>Professional Endorsements</h3>
              {(!professional.reference1 && !professional.reference2) ? <pre><em>No references found.</em></pre> : null}
              {professional.reference1 ? <pre>{professional.reference1}</pre> : null}
              {professional.reference2 ? <pre>{professional.reference2}</pre> : null}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3>Ratings</h3>
              <Row><Col md={3}>On Schedule:</Col><Col md={4}><RatingStars value={professional.timingRating} /></Col></Row>
              <Row><Col md={3}>Within Budget:</Col><Col md={4}><RatingStars value={professional.budgetRating} /></Col></Row>
              <Row><Col md={3}>Communication:</Col><Col md={4}><RatingStars value={professional.commsRating} /></Col></Row>
              <Row><Col md={3}>Project Management:</Col><Col md={4}><RatingStars value={professional.projectManagementRating} /></Col></Row>
            </Col>
            <Col md={6}>
              <h3>Geographic Areas</h3>
              {(professional.addressAreasId && professional.addressAreasId.map((each, i) => <><Label key={i}>{lookupAddressArea(each) || "Unknown"}</Label> </>)) || <p><em>None</em></p>}
              <h3>Budget Ranges</h3>
              {(professional.budgetRangesId && professional.budgetRangesId.map((each, i) => <><Label key={i}>{lookupBudgetRange(each) || "Unknown"}</Label> </>)) || <p><em>None</em></p>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="porfolioImages">
              <h3>Portfolio Images</h3>
              {(!professional.portfolioImage1 && !professional.portfolioImage2 && !professional.portfolioImage3 && !professional.portfolioImage4) ? <pre><em>No porfolio images found.</em></pre> : null}
              <Image src={professional.portfolioImage1} rounded />
              <Image src={professional.portfolioImage2} rounded />
              <Image src={professional.portfolioImage3} rounded />
              <Image src={professional.portfolioImage4} rounded />
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
    </>
  )
}

const WeaverFullProfessionalProfile = ({ invite, professional }) => {
  console.debug("Rendering WeaverOverviewProfessionalProfile")

  const professionalTags = useContext(ProfessionalTagContext)
  const addressAreas = useContext(AddressAreaContext)
  const budgetRanges = useContext(BudgetRangeContext)
  const standalone = useContext(StandaloneContext)

  const getNameById = (id, primaryModel, secondaryModel) =>
    primaryModel.getNameById ? primaryModel.getNameById(id) :
      secondaryModel.getNameById ? secondaryModel.getNameById(id) : null
  const lookupProfessionalTag = id => getNameById(id, professionalTags, standalone.professionalTags)
  const lookupAddressArea = id => getNameById(id, addressAreas, standalone.addressAreas)
  const lookupBudgetRange = id => getNameById(id, budgetRanges, standalone.budgetRanges)


  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="titleLogo"><img className="logo" alt="" src="/logo-cropped.png" /></span>
          <span className="titleName">{professional.company}</span>
          <span className="titleRating pull-right">Average Rating: <RatingStars value={professional.averageRating} /></span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Debugger models={{ info: { invite, professional } }} />
        <Grid>
          <Row className="summary">
            <Col md={9}>
              <Row>
                <Col md={9}>{professional.tagsId && professional.tagsId.map(each => <><Label key={each}>{lookupProfessionalTag(each) || "Unknown"}</Label> </>)}</Col>
              </Row>
              <Row>
                <Col md={3}><b>Time as Weaver Pro:</b></Col><Col md={6}><TimeAgo date={professional.dateAdded} /></Col>
              </Row>
              <Row>
                <Col md={3}><b># Weaver Tenders:</b></Col><Col md={6}>{professional.tenderCount}</Col>
              </Row>
              <Row>
                <Col md={3}><b>Company Established:</b></Col><Col md={6}>{professional.companyEstablishedYear || "Unknown"}</Col>
              </Row>
              <Row>
                <Col md={3}><b>Insurance:</b></Col>
                <Col md={6}>
                  <Row><Col md={5}><b>Public Liability:</b> </Col><Col md={4}>{asMoney(professional.insurancePublicLiabilityLimit) || "Unknown"}</Col></Row>
                  <Row><Col md={5}><b>Employer Liability:</b> </Col><Col md={4}>{asMoney(professional.insuranceEmployerLiabilityLimit) || "Unknown"}</Col></Row>
                  <Row><Col md={5}><b>Renewal Date:</b> </Col><Col md={4}>{asDate(professional.insuranceRenewalDate) || "Unknown"}</Col></Row>
                </Col>
              </Row>
              <Row>
                <Col md={3}><b>Vetting:</b></Col>
                <Col md={6}>
                  <Row><Col md={9}><Glyphicon glyph={professional.vettingCompanyDirectorBankruptcy ? "check" : "unchecked"} /> Company Director Bankruptcy</Col></Row>
                  <Row><Col md={9}><Glyphicon glyph={professional.vettingCompanyDebt ? "check" : "unchecked"} /> Company Debt</Col></Row>
                </Col>
              </Row>
            </Col>
            <Col md={3} className="contact">
              <div className="field">
                <div className="fieldName">Contact</div>
                <div className="fieldValue">{professional.firstName} {professional.lastName}</div>
              </div>
              <div className="field">
                <div className="fieldName">Website</div>
                <div className="fieldValue"><a href={professional.website} target="_blank" rel="noopener noreferrer">{professional.website}</a></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h3>Professional Endorsements</h3>
              {(!professional.reference1 && !professional.reference2) ? <pre><em>No references found.</em></pre> : null}
              {professional.reference1 ? <pre>{professional.reference1}</pre> : null}
              {professional.reference2 ? <pre>{professional.reference2}</pre> : null}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3>Ratings</h3>
              <Row><Col md={3}>On Schedule:</Col><Col md={4}><RatingStars value={professional.timingRating} /></Col></Row>
              <Row><Col md={3}>Within Budget:</Col><Col md={4}><RatingStars value={professional.budgetRating} /></Col></Row>
              <Row><Col md={3}>Communication:</Col><Col md={4}><RatingStars value={professional.commsRating} /></Col></Row>
              <Row><Col md={3}>Project Management:</Col><Col md={4}><RatingStars value={professional.projectManagementRating} /></Col></Row>
            </Col>
            <Col md={6}>
              <h3>Geographic Areas</h3>
              {(professional.addressAreasId && professional.addressAreasId.map(each => <><Label key={each}>{lookupAddressArea(each) || "Unknown"}</Label> </>)) || <p><em>None</em></p>}
              <h3>Budget Ranges</h3>
              {(professional.budgetRangesId && professional.budgetRangesId.map(each => <><Label key={each}>{lookupBudgetRange(each) || "Unknown"}</Label> </>)) || <p><em>None</em></p>}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="porfolioImages">
              <h3>Portfolio Images</h3>
              {(!professional.portfolioImage1 && !professional.portfolioImage2 && !professional.portfolioImage3 && !professional.portfolioImage4) ? <pre><em>No porfolio images found.</em></pre> : null}
              <Image src={professional.portfolioImage1} rounded />
              <Image src={professional.portfolioImage2} rounded />
              <Image src={professional.portfolioImage3} rounded />
              <Image src={professional.portfolioImage4} rounded />
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
    </>
  )
}

export const OverviewProfessionalProfile = ({ invite, professional, stage, disabled, isShowing, close, system }) => {
  console.debug("Rendering OverviewProfessionalProfile")

  const analytics = useContext(AnalyticsContext)
  const thisSourcing = useContext(ThisSourcingContext)
  const sourcingInvites = useContext(SourcingInviteContext)

  return (
    <Modal show={isShowing} onHide={close} dialogClassName="sourcingProfessionalProfile-modal shortlisting">
      {professional.system === "STUDIO" ? <StudioProfessionalProfile {...{ invite, professional }} /> : <WeaverOverviewProfessionalProfile {...{ invite, professional }} />}
      <Modal.Footer>
        {professional.system === "STUDIO" || (thisSourcing && thisSourcing.userHasWeaverTools) ?
          <Button bsStyle="success" className="acceptButton" disabled={disabled} onClick={() => {
            if (analytics && analytics.event) analytics.event("ProfessionalProfile", "Accept Invite")
            sourcingInvites.acceptInvite(invite.id).then(close)
          }}><Glyphicon glyph="ok" /> Interest Confirmed</Button> : null}
        <Button bsStyle="default" className="preferredButton" disabled={disabled} active={invite.preferred} onClick={() => {
          if (analytics && analytics.event) analytics.event("ProfessionalProfile", "Toggle Preferred")
          sourcingInvites.toggleInvitePreferred(invite.id).then(close)
        }}><Glyphicon glyph="star" /> Favourite</Button>
        <Button bsStyle="danger" className="rejectButton" disabled={disabled} onClick={() => {
          if (analytics && analytics.event) analytics.event("ProfessionalProfile", "Reject Invite")
          sourcingInvites.rejectInvite(invite.id).then(close)
        }}><Glyphicon glyph="remove" /> Reject</Button>
        <Button onClick={() => close()} bsStyle="link">Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export const FullProfessionalProfile = ({ invite, professional, stage, disabled, isShowing, close, system }) => {
  console.debug("Rendering FullProfessionalProfile")

  return (
    <Modal show={isShowing} onHide={close} dialogClassName="sourcingProfessionalProfile-modal tendering">
      {professional.system === "STUDIO" ? <StudioProfessionalProfile {...{ invite, professional }} /> : <WeaverFullProfessionalProfile {...{ invite, professional }} />}
      <Modal.Footer>
        <Button onClick={() => close()} bsStyle="link">Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export const WeaverOverviewProfessionalProfileModal = ({ invite, professional, isShowing, close }) => {
  console.debug("Rendering WeaverOverviewProfessionalProfileModal")

  return (
    <Modal show={isShowing} onHide={close} dialogClassName="sourcingProfessionalProfile-modal tendering">
      <WeaverOverviewProfessionalProfile {...{ invite, professional }} />
      <Modal.Footer>
        <Button onClick={() => close()} bsStyle="link">Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
