import React from "react";
import AppliedRoute from "./AppliedRoute";
import './WeaverRoute.css'

export default function WeaverRoute(props) {
  return (
    <div className="WeaverRoute">
      <AppliedRoute {...props} />
    </div>
  );
}
