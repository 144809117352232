import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Airtable from 'airtable';

export const AnalyticsContext = React.createContext({});

const Analytics = ({ children }) => {
  const [state, setState] = useState({ init: false });
  console.log("[Analytics] Rendering analytics ", { state })

  useEffect(() => {
    async function onLoad() {
      if (!state.init) {
        Airtable.configure({ apiKey: 'keyyYLBV3nqLFQhxV' });

        // const ip = await fetch(`http://ifconfig.io/ip`);
        const session = await Auth.currentSession();

        setState({
          ...state,
          init: true,
          setState,
          event: (activityType, extraData) => {
            Airtable.base('appQ90j8891fnM7Ps')('Data').create({
              Timestamp: new Date(),
              BrowserUserAgent: navigator.userAgent,
              UserEmail: session.idToken.payload.email,
              ActivityType: activityType,
              ExtraData: extraData ? JSON.stringify(extraData) : extraData,
            });
          },
        });
        return null;
      }
    }
    onLoad();
  }, []);

  return (
    <AnalyticsContext.Provider value={state}>
      {children}
    </AnalyticsContext.Provider>
  );
};
export default Analytics;