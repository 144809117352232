import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './store';
import './index.css';
import App from './App';
import RedirectToV2 from './RedirectToV2';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { Auth } from "aws-amplify";
import config from './config';

if (config && config.sentry && config.sentry.DSN)
  Sentry.init({
    dsn: config.sentry.DSN,
    environment: config.sentry.ENV,
    release: `myweaver-frontend@${config.sentry.VER}`,
    // TODO: Figure out how to do this in a non intruisive way... it's pretty noisy!
    // beforeSend(event, hint) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception) {
    //     Sentry.showReportDialog({ eventId: event.event_id });
    //   }
    //   return event;
    // }
  });

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "apigw",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        // https://aws-amplify.github.io/docs/js/api#cognito-user-pools-authorization
        custom_header: async () => {
          // With Cognito User Pools use this: 
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
      {
        name: "apigw_anon",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ]
  }
});

TagManager.initialize({ gtmId: config.gtm.GOOGLE_TAG_MANAGER })

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <RedirectToV2 />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
