import { Cache } from 'aws-amplify'
import { combineReducers } from 'redux'
import {
  SET_COGNITO_USERNAME,
  SET_USER_PROFILE,
  SET_USER_STUDIOS,
  SET_CURRENT_STUDIO,
  SET_CONTACTS,
  SET_CONTACT_FORM,
  UPDATE_CONTACT_FORM,
  SET_WEAVERTOOLS_PROJECTS,
} from '../actions'

const cognitoUsername = (state = null, action) => {
  switch (action.type) {
    case SET_COGNITO_USERNAME:
      console.info("REDUCER: Setting the cognito username.", state, action)
      Cache.setItem("cognitoUsername", action.username);
      return action.username;
    default:
      return state;
  }
}

const _userProfile_default = {
  id: null,
  FirstName: null,
  LastName: null,
  Phone: null,
  SuggestedStudioName: null,
  ProfileImage: null,
  IsDebugging: false,
}
const userProfile = (state = { ..._userProfile_default }, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      console.info("REDUCER: Setting the user profile.", state, action)
      const newState = {
        ...state,
        ...action.userProfile
      }
      Cache.setItem("userProfile", newState);
      return newState
    default:
      return state;
  }
}

const userStudios = (state = [], action) => {
  switch (action.type) {
    case SET_USER_STUDIOS:
      console.info("REDUCER: Setting the list of user studios.", state, action)
      return action.userStudios ? action.userStudios : [];
    default:
      return state;
  }
}

const currentStudio = (state = null, action) => {
  switch (action.type) {
    case SET_CURRENT_STUDIO:
      console.info("REDUCER: Setting the current studio.", state, action)
      Cache.setItem("currentStudio", action.selectedStudio);
      return action.selectedStudio;
    default:
      return state;
  }
}

// This is a list of contact objects, stored as an object, keyed on contact.id
// { [contact1.id]: contact1, [contact2.id]: contact2, ... }
const contacts = (state = {}, action) => {
  switch (action.type) {
    case SET_CONTACTS:
      console.info("REDUCER: Setting the contacts.", state, action)
      Cache.setItem("contacts", action.contacts);
      return action.contacts ? action.contacts : {};
    default:
      return state;
  }
}

const _contactForm_default = {
  id: null,
  contact: {},
  changes: {},
}
const contactForm = (state = { ..._contactForm_default }, action) => {
  switch (action.type) {
    case SET_CONTACT_FORM:
      console.info("REDUCER: Setting a fresh contact form with no changes. Depends on action.contactForm having an id and contact.", state, action)
      return {
        ...state,
        id: action.contactForm.id,
        contact: action.contactForm.contact,
        changes: {},
      }
    case UPDATE_CONTACT_FORM:
      console.info("REDUCER: Updating the contact form", state, action)

      // Mark the change
      const newState = {
        ...state,
        changes: {
          ...state.changes,
          [action.change.id]: action.change.value
        }
      }
      console.debug("newState: ", newState)

      // Remove any changes which aren't changes any more (get the entries as an array, filter out those that are the same as the original, convert array into an object)
      const filteredChanges = Object.entries(newState.changes)
        .filter(pair => {
          return newState.contact[pair[0]] !== pair[1]
        })
        .reduce((acc, pair) => {
          return { ...acc, [pair[0]]: pair[1] }
        }, {})
      console.debug("filteredChanges: ", filteredChanges)

      return {
        ...newState,
        changes: filteredChanges
      }
    default:
      return state;
  }
}

// WEAVER TOOLS

const weaverToolsProjects = (state = [], action) => {
  switch (action.type) {
    case SET_WEAVERTOOLS_PROJECTS:
      console.info("WEAVERTOOLS REDUCER: Setting the list of projects.", state, action)
      Cache.setItem("weaverToolsProjects", action.projects);
      return action.projects ? action.projects : [];
    default:
      return state;
  }
}

// Exports

export default combineReducers({
  cognitoUsername,
  userProfile,
  userStudios,
  currentStudio,
  contacts,
  contactForm,

  // WEAVER TOOLS
  weaverToolsProjects,
})
