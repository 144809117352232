import React, { useState, useEffect } from "react";
import { Panel, Button, ButtonToolbar, Label } from "react-bootstrap";
import Debugger from "../../components/Debugger";
import { API } from "aws-amplify";
import "./SyncSupplyProfessionals.css";

const SyncSupplyProfessionals = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [syncData, setSyncData] = useState(null);

  // On load
  useEffect(() => {
    API.get("apigw", `/weavertools/syncSupplyProfessionals`)
      .then(results => {
        setSyncData(results)
        setIsLoading(false)
      })
  }, []);

  // Make it so!
  const doIt = () => {
    setIsLoading(true)
    API.put("apigw", `/weavertools/syncSupplyProfessionals`)
      .then(results => {
        setSyncData(results)
        setIsLoading(false)
      })
  }

  return (
    <div className="syncSupplyProfessionals">
      <Debugger models={{ debugging: { isLoading, syncData }}} />
      {!isLoading && <ListCompanies syncData={syncData} onApproveAction={doIt} />}
    </div>
  )
}

const ListCompanies = ({ syncData, onApproveAction }) => {
  return syncData ? (
    <Panel>
      <Panel.Heading>
        <Panel.Title>List of Supply which is not in Professional</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <p>
          This weaver tool lists Supply records from a view, and where they don't exist as linked rows in Professional, it creates a Professional.
          If you don't see anything in the list below, then there are no Professionals to create and link.
        </p>
        <pre>
          <b>Pseudocode for the list below:</b><br/>
          > Get a list of Supply records in the "Supply for Professionals" view of the Marketplace database<br/>
          > Filter the list to those not already present in the Professionals table<br/>
          > Return the id and company for each remaining Supply
        </pre>
        <pre>
          <b>Pseudocode for the Do It action:</b><br/>
          > Repeat the process above to get the list of Supply not already present in the Professionals table<br/>
          > For each Supply listed:<br/>
          > > Create a new record in the Professionals table and link to this Supply<br/>
          > Repeat the process above to get the list of Supply not already present in the Professionals table (should now be empty)<br/>
          > Return the id and company for each remaining Supply (should be none)<br/>
        </pre>
        <p>
          {syncData.map((supply, i) => (<span key={i}><Label>{supply.fields.Company}</Label> </span>))}
        </p>
        <ButtonToolbar>
          <Button bsStyle="success" onClick={onApproveAction}>Do it!</Button>
        </ButtonToolbar>
      </Panel.Body>
    </Panel>
  ) : null
}

export default SyncSupplyProfessionals
