import React, { useEffect, useContext, useState } from "react";
import { Button, ButtonToolbar, Modal, FormGroup, ControlLabel, InputGroup, FormControl, ListGroup, ListGroupItem } from "react-bootstrap";
import { ProjectStageContext, AddressAreaContext, BudgetRangeContext, ProjectContext, ProjectDescriptionContext } from "../model"
import Select from 'react-select';
import { lookupAddressesByPostcode } from "../services/adddressIO"
import Debugger from '../components/Debugger'
import "./ProjectForm.css";
import ButtonWithTooltip from "./ButtonWithTooltip";

const AddressListItem = ({ address, onClick }) => {
  return (
    <ListGroupItem bsStyle="default" onClick={onClick}>
      <span>{address.full.replace(/\n/g, ", ")}</span>
    </ListGroupItem>
  )
}

const AddressSelectorModal = ({ fields, setFields, addresses, isShowing, switchToProjectForm }) => {
  const addressAreas = useContext(AddressAreaContext)
  const pickAddress = address => {
    const area = addressAreas.findByName(address.area)
    setFields({
      ...fields,
      addressAreaId: area ? area.id : null,
      address: address.full,
    })
    switchToProjectForm()
  }

  return (
    <Modal show={isShowing} onHide={switchToProjectForm}>
      <Modal.Header closeButton>
        <Modal.Title>Select Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup bsClass="addressListGroup">
          {addresses.map(each => <AddressListItem address={each} onClick={() => { pickAddress(each) }} />)}
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

const ProjectFormModal = ({ fields, setFields, projectId, isShowing, close, switchToAddressSelector }) => {
  console.debug("Rendering ProjectForm", isShowing, close)
  const [isSaving, setIsSaving] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)

  const setFieldValue = (key, value) => { setHasChanged(true); setFields({ ...fields, [key]: value }); }
  const setFieldsValues = fieldValues => { setHasChanged(true); setFields({ ...fields, ...fieldValues }); }
  const onChange = (event) => {
    setFieldValue(event.target.id, event.target.value)
  }

  // Initialise when isShowing or the projectId change
  const addressAreas = useContext(AddressAreaContext)
  const budgetRanges = useContext(BudgetRangeContext)
  const projectDescriptions = useContext(ProjectDescriptionContext)
  const projects = useContext(ProjectContext)
  const projectStages = useContext(ProjectStageContext)

  const abort = () => {
    if (!hasChanged || window.confirm("You have unsaved changes which will be lost if you continue. Are you sure?")) close()
  }

  const onDelete = (e) => {
    e.preventDefault()
    setIsSaving(true)
    projects.removeProject(projectId)
      .then(setIsSaving(false))
      .then(close)
  }

  const onSave = (e) => {
    e.preventDefault()
    if (fields.description.length === 1 && !window.confirm("You have only selected one project description, would you like to continue without selecting more?")) return
    setIsSaving(true)
    const action = projectId ? projects.updateProjectFields : projects.createProject
    action(fields)
      .then(setIsSaving(false))
      .then(close)
  }

  const onClickLookupArea = () => {
    setIsSaving(true)
    lookupAddressesByPostcode(fields.addressPostcode)
      .then(setIsSaving(false))
      .then(switchToAddressSelector)
  }

  const onChangeBudget = event => {
    const filteredBudget = event.target.value.replace(/[^0-9]+/g, '')
    const budgetRange = budgetRanges.getByValue(filteredBudget)
    setFieldsValues({
      [event.target.id]: filteredBudget,
      budgetRangeId: budgetRange ? budgetRange.id : null,
    })
  }

  const isValidMessage = () => {
    const invalidMessages = [
      fields.addressPostcode ? null : "Project Postcode.",
      fields.addressAreaId ? null : "Project Geographic Area (lookup).",
      fields.description && fields.description.length ? null : "Project Description.",

    ].filter(each => each !== null)
    return invalidMessages.length ? invalidMessages.join(" ") : null
  }
  const isValid = () => isValidMessage() !== null

  const projectDescriptionsOptions = projectDescriptions.data.map(each => ({ value: each.id, label: each.name }))
  const projectDescriptionsDefaultValue = fields.description === null ? null : Array.isArray(fields.description) ?
    projectDescriptionsOptions.filter(option => fields.description.includes(option.value)) : projectDescriptionsOptions.filter(option => option.value === fields.description)

  return (
    <Modal show={isShowing} onHide={abort}>
      <Modal.Header closeButton>
        <Modal.Title>Create Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Debugger models={{ models: { fields, projectDescriptionsOptions, projectDescriptionsDefaultValue } }} />
        <form onSubmit={() => isValid() ? onSave() : null}>
          <FormGroup controlId="projectStageId" bsSize="large">
            <ControlLabel>Project Stage</ControlLabel>
            <FormControl componentClass="select" value={fields.projectStageId} onChange={onChange} disabled={isSaving}>
              {projectStages.data.map(each => <option key={each.id} value={each.id}>Project Stage {each.number}: {each.title}</option>)}
            </FormControl>
          </FormGroup>
          <FormGroup controlId="ref" bsSize="large">
            <ControlLabel>Project Reference</ControlLabel>
            <FormControl autoFocus type="text" placeholder="Enter your studio’s reference code for this project" value={fields.ref || ''} onChange={onChange} disabled={isSaving} />
          </FormGroup>
          <FormGroup controlId="name" bsSize="large">
            <ControlLabel>Project Name</ControlLabel>
            <FormControl type="text" placeholder="Enter a short project name, e.g. the first part of the street name" value={fields.name || ''} onChange={onChange} disabled={isSaving} />
          </FormGroup>
          <FormGroup controlId="description" bsSize="large">
            <ControlLabel>Project Description (pick as many as are relevant)</ControlLabel>
            <Select
              options={projectDescriptionsOptions}
              isMulti
              disabled={isSaving}
              defaultValue={projectDescriptionsDefaultValue}
              placeholder="Select multiple relevant descriptions for this project"
              className="form-select"
              styles={{
                // This is just to make it match the other FormControls size
                control: (provided, state) => ({
                  ...provided,
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  fontSize: "18px",
                })
              }}
              // formatOptionLabel={formatOptionLabel}
              onChange={(change, action) => {
                console.debug(`Select description Change: `, change, action)
                // This is just to make it work like FormControl event handlers
                onChange({
                  target: {
                    id: "description", value: change === null ? change : Array.isArray(change) ? change.map(each => each.value) : change.value
                  }
                })
              }}
            />
          </FormGroup>
          <FormGroup controlId="addressPostcode" bsSize="large">
            <ControlLabel>Project Postcode</ControlLabel>
            <FormControl type="text" placeholder="Enter the postcode for the project" value={fields.addressPostcode || ''} onChange={onChange} disabled={isSaving} />
            <ButtonToolbar bsClass="btn-toolbar pull-right">
              <Button className="areaLookupButton pull-right" bsStyle="link" onClick={onClickLookupArea} disabled={isSaving}>Lookup Address &amp; Geographic Area</Button>
            </ButtonToolbar>
          </FormGroup>
          <FormGroup controlId="address" bsSize="large">
            <ControlLabel>Project Address</ControlLabel>
            <FormControl componentClass="textarea" rows="4" placeholder="Lookup the address by postcode, then edit manually after" value={fields.address || ''} onChange={onChange} disabled={isSaving || !fields.addressPostcode} />
          </FormGroup>
          <FormGroup controlId="addressAreaId" bsSize="large">
            <ControlLabel>Project Geographic Area</ControlLabel>
            <FormControl componentClass="select" value={fields.addressAreaId || ''} disabled>
              <option value="">Unknown</option>
              {addressAreas.data.map(each => <option key={each.id} value={each.id}>{each.name}</option>)}
            </FormControl>
          </FormGroup>
          <FormGroup controlId="budget" bsSize="large">
            <ControlLabel>Project Budget</ControlLabel>
            <InputGroup>
              <InputGroup.Addon>£</InputGroup.Addon>
              <FormControl type="text" placeholder="Enter the project budget, as accurately as known" value={fields.budget || ''} onChange={onChangeBudget} disabled={isSaving} />
              <InputGroup.Addon>.00</InputGroup.Addon>
            </InputGroup>
          </FormGroup>
          <FormGroup controlId="budgetRangeId" bsSize="large">
            <ControlLabel>Project Budget Range</ControlLabel>
            <FormControl componentClass="select" value={fields.budgetRangeId || ''} onChange={onChange} disabled>
              <option value="">Unknown</option>
              {budgetRanges.data.map(each => <option key={each.id} value={each.id}>{each.name}</option>)}
            </FormControl>
          </FormGroup>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {projectId ? <Button onClick={onDelete} bsStyle="danger" disabled={isSaving}>Archive</Button> : null}
        <ButtonWithTooltip onClick={onSave} bsStyle="success" disabled={isSaving} tooltipCondition={isValid()} tooltipMessage={`The following fields are invalid: ${isValidMessage()}`}>Save</ButtonWithTooltip>
        <Button onClick={abort} bsStyle="link" disabled={isSaving}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

export const ProjectForm = ({ isShowing, close, projectId }) => {
  const [displayFormModal, setDisplayFormModal] = useState(isShowing)
  const [displayAddressModal, setDisplayAddressModal] = useState(false)
  const [addresses, setAddresses] = useState([])
  const [fields, setFields] = useState({})

  const projects = useContext(ProjectContext)
  const projectStages = useContext(ProjectStageContext)

  useEffect(() => {
    setDisplayFormModal(isShowing)
    setDisplayAddressModal(false)
    setAddresses([])

    // Reset to default everytime the modal visibility changes
    const defaultProjectStage = projectStages.data[0];
    setFields(projectId ? projects.getById(projectId) : {
      projectStageId: defaultProjectStage ? defaultProjectStage.id : "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing, projectId])

  return (<>
    <ProjectFormModal projectId={projectId} fields={fields} setFields={setFields} isShowing={displayFormModal} close={() => {
      setDisplayFormModal(false)
      close()
    }} switchToAddressSelector={(addresses) => {
      setAddresses(addresses)
      setDisplayFormModal(false)
      setDisplayAddressModal(true)
    }} />
    <AddressSelectorModal addresses={addresses} fields={fields} setFields={setFields} isShowing={displayAddressModal} switchToProjectForm={() => {
      setDisplayAddressModal(false)
      setDisplayFormModal(true)
      setAddresses([])
    }} />
  </>)
}
