import React, { useState } from "react";
import { Modal, Button, NavItem } from "react-bootstrap";
import './ContactUs.css'

export const ContactUs = ({ isShowing, close }) => {
  console.debug("Rendering ContactUs", isShowing, close)

  return isShowing && (<Modal show={isShowing} onHide={close} dialogClassName="contact-us-modal">
    <Modal.Header closeButton>
      <Modal.Title>Contact Us</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p className="phone">Give us a call now on <a href="tel:+442038713195">+44 20 3871 3195</a></p>
      <p className="email">Or email us at <a href="mailto:support@weaver.build">support@weaver.build</a></p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => close()} bsStyle="link">Close</Button>
    </Modal.Footer>
  </Modal>)
}

export const ContactUsNav = () => {
  const [isShowing, setIsShowing] = useState(false)
  return (<>
    <ContactUs isShowing={isShowing} close={() => setIsShowing(false)} />
    <NavItem onClick={() => setIsShowing(true)}>Contact Us</NavItem>
  </>)
}

export default ContactUsNav
