import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

export default function Home() {
  return (
    <div className="Home">
      <div className="lander">
        <h1>Weaver Tools</h1>
        <p>This is a holding page. It'll grow to contain a list of available tools.</p>
        <ol>
          <li><Link to="projects">List of Projects</Link></li>
          <li><Link to="project-areas">Convert Project Postcodes into London/Nationwide Areas</Link></li>
          <li><Link to="sync-supply-professionals">Synchronise the Supply with Professionals</Link></li>
          <li><Link to="sync-supply-budget-address-areas">Synchronise the Supply Budget Ranges, Address Areas and Professional Tags</Link></li>
          <li><Link to="show-professional-profile">Show the profile for a Professional</Link></li>
        </ol>
      </div>
    </div>
  );
}
