export default {
  s3: {
    REGION: "weaver-dhardiker",
    BUCKET: "my.weaver.technology",
  },
  apiGateway: {
    REGION: "weaver-dhardiker",
    URL: "https://14nwkfng2j.execute-api.eu-west-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "weaver-dhardiker",
    USER_POOL_ID: "eu-west-1_NzW5AQ4Qq",
    APP_CLIENT_ID: "5e8sfvkrof4tg3phalbejrf8jt",
    IDENTITY_POOL_ID: "eu-west-1:d093fdec-5f46-47a4-b32c-cf10e185cb71",
  },
  addressIO: {
    API_KEY: "C_C6zH59OEC2ZDPiAw9aeQ25019",
  },
  sentry: {
    DSN: "https://d3a0fd3b7a5b4a2d9bedc678e249d3a3@sentry.io/1884281",
    ENV: "beta",
    VER: "e154e68",
  },
  analytics: {
    TRACKING_ID: "UA-99023990-2",
  },
  gtm: {
    GOOGLE_TAG_MANAGER: "GTM-PDK6ZQH",
  },
};
