import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Button } from "react-bootstrap";
import { API } from "aws-amplify";
import Debugger from "../../components/Debugger";

const ProjectAreaConverter = ({ cognitoUsername, history }) => {
  const [projects, setProjects] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function loadProjects() {
      return API.get("apigw", `/weavertools/projectsWithPostcodeWithoutArea`);
    }

    async function onLoad() {
      try {
        const response = await loadProjects();

        // Take the array of weaverToolsProjects returned from the server and mutate it as necessary
        const promises = response.projects.map((project, i) => {
          const url = response.projects.length > 30 ? "http://localhost:8080" : "https://api.postcodes.io"
          return new Promise((resolve, reject) => {
            fetch(`${url}/postcodes/${project.ProjectPostcode.replace(/[^A-Za-z0-9]/g, '')}`)
              .then(res => res.json())
              .then(json => {
                // console.log("XHR Response: ", json)
                if (!json || !json.result) {
                  return resolve({ ...project, NoArea: true })
                }
                if (json.result.region === "London") {
                  return resolve({ ...project, LondonArea: json.result.admin_district })
                } else {
                  return resolve({ ...project, NationwideArea: json.result.admin_county })
                }
              })
          })
        })

        // Resolve the promises
        const resolvedPromises = await Promise.all(promises)
        console.log("Resolved Promises", resolvedPromises)

        setProjects(resolvedPromises);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUsername]);
  // TODO: the above should make sure that unless the username changes that we don't ask the server for the data again ... but it seems like we are asking every time!

  function renderProjects() {
    const countAllProjects = projects.length
    const countLondonProjects = projects.filter(each => each.LondonArea).length
    const countNationwideProjects = projects.filter(each => each.NationwideArea).length
    const countUnknownProjects = projects.filter(each => each.NoArea).length

    const fixLondonProjects = () => {
      console.log("Fixing London projects")
      API.put("apigw", `/weavertools/projectsWithPostcodeWithoutArea`, {
        body: projects.filter(each => each.LondonArea),
      })
        .then(() => console.log("London projects fixed"))
    }

    const fixNationwideProjects = () => {
      console.log("Fixing Nationwide projects")
      API.put("apigw", `/weavertools/projectsWithPostcodeWithoutArea`, {
        body: projects.filter(each => each.NationwideArea),
      })
        .then(() => console.log("Nationwide projects fixed"))
    }

    return (
      <>
        <p>There are {countAllProjects} projects, of which {countLondonProjects} are in London (<Button className="inline-text-link" bsStyle="link" onClick={fixLondonProjects}>fix</Button>), {countNationwideProjects} are nationwide (<Button className="inline-text-link" bsStyle="link" onClick={fixNationwideProjects}>fix</Button>).</p>
        {countUnknownProjects ?
          <>
            <p>There following projects are unknown and should have their postcodes fixed:</p>
            <ul>
              {projects.filter(each => each.NoArea).map(each => (<li>Project "{each.id}" in postcode: {each.ProjectPostcode}</li>))}
            </ul>
          </> : null
        }
      </>
    )
  }

  return (
    <div className="WeaverToolsProjectAreaConverter">
      <Debugger models={{ projects }} />
      {cognitoUsername && !isLoading && renderProjects()}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    cognitoUsername: state.cognitoUsername,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectAreaConverter)