import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

/**
 * Wraps a button in a tooltip that displays for a few seconds under the given conditions
 * 
 * @param {*} tooltipCondition boolean - if true, shows the tooltipMessage for a few seconds, otherwise calls onClick prop 
 * @param {*} tooltipMessage string - text to show in the tooltip
 * @param {*} props array - passed through to to the <Button /> component
 */
const ButtonWithTooltip = ({ tooltipCondition, tooltipMessage, ...props }) => {
  const tooltip = (<Tooltip id="tooltip">{tooltipMessage}</Tooltip>)

  return tooltipCondition ? (
    <OverlayTrigger placement="top" overlay={tooltip}>
      <Button {...props} onClick={() => { }} />
    </OverlayTrigger>
  ) : (
      <Button {...props} />
    )
}

export default ButtonWithTooltip
