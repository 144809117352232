import React from "react";
import { Image } from "react-bootstrap";
import "./ProfileImage.css";

export default function ProfileImage({
  profileImage,
  ...props
}) {

  let sanitisedProfileImage
  let className
  switch (profileImage) {
    case "avatar-1": case "avatar-2": case "avatar-3":
    case "avatar-4": case "avatar-5": case "avatar-6":
    case "avatar-7": case "avatar-8": case "avatar-9":
      sanitisedProfileImage = profileImage
      className = "known"
      break
    default:
      sanitisedProfileImage = "avatar-1"
      className = "unknown"
      break
  }

  return (
    <Image
      className={`profileImage ${className}`}
      src={`/${sanitisedProfileImage}.png`}
      rounded
      {...props}
    />
  );
}
