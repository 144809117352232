import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      const getValue = target => {
        switch (target.type) {
          case "checkbox": return target.checked
          default: return target.value
        }
      }
      setValues({
        ...fields,
        [event.target.id]: getValue(event.target)
      });
    }
  ];
}
