import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Auth } from "aws-amplify";
import * as Sentry from '@sentry/browser';
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ProfileImage from "./components/ProfileImage"
import ButterToast from 'butter-toast';
import "./App.css";
import Routes from "./Routes";
import { loadSessionCacheFromAuthenticatedUser } from "./sessionCache";
import { setCognitoUsername } from "./actions"
import ContactUsNav from "./components/ContactUs";
import Analytics from "./services/Analytics"

const App = ({ userProfile, currentStudio, cognitoUsername, setCognitoUsername, history }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  Sentry.configureScope(function (scope) {
    scope.setUser({
      id: userProfile && userProfile.id,
      username: cognitoUsername,
      email: cognitoUsername,
    });
    scope.setTag("studioId", currentStudio && currentStudio.id)
    scope.setTag("studioName", currentStudio && currentStudio.name)
  });

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession().then(user => {
        loadSessionCacheFromAuthenticatedUser();
      });
      userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  const withNavBars = () => {
    return !history.location.pathname.startsWith("/_/")
  }

  return withNavBars() ? (
    !isAuthenticating &&
    <div className="App container">
      <ButterToast />
      <Navbar fluid collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/studios"><img className="logo" alt="" src="/logo.png" /></Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Nav className="studioName">
            {currentStudio ? (<LinkContainer to="/studios"><NavItem>{currentStudio.name}</NavItem></LinkContainer>) : ""}
          </Nav>
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            {isAuthenticated ?
              <>
                <LinkContainer to="/projects">
                  <NavItem>Projects</NavItem>
                </LinkContainer>
                <LinkContainer to="/contacts">
                  <NavItem>Professionals</NavItem>
                </LinkContainer>
              </> :
              <>
                <LinkContainer to="/signup">
                  <NavItem>Signup</NavItem>
                </LinkContainer>
                <LinkContainer to="/login">
                  <NavItem>Login</NavItem>
                </LinkContainer>
              </>
            }
            <LinkContainer to="/profile">
              <NavItem className="profileImage">
                <ProfileImage profileImage={userProfile.ProfileImage} />
              </NavItem>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Analytics>
        <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
      </Analytics>
      <Navbar className="footer">
        <Nav>
          <LinkContainer to="/privacy">
            <NavItem>Privacy Policy</NavItem>
          </LinkContainer>
          <ContactUsNav />
        </Nav>
      </Navbar>
    </div>
  ) : (
      <div className="App container">
        <ButterToast />
        <Analytics>
          <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
        </Analytics>
      </div>
    )
}

const mapStateToProps = state => ({
  userProfile: state.userProfile,
  currentStudio: state.currentStudio,
  cognitoUsername: state.cognitoUsername,
})
const mapDispatchToProps = dispatch => {
  return {
    setCognitoUsername: (username) => {
      dispatch(setCognitoUsername(username))
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
