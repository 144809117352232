import React from "react";
import ProfileImage from "./ProfileImage"
import Select from "react-select"
import "./ProfileImageSelect.css";

export default function ProfileImageSelect({
  controlId,
  initialValue,
  onChange,
  ...props
}) {

  const options = [
    { value: "avatar-1", label: "Avatar 1" },
    { value: "avatar-2", label: "Avatar 2" },
    { value: "avatar-3", label: "Avatar 3" },
    { value: "avatar-4", label: "Avatar 4" },
    { value: "avatar-5", label: "Avatar 5" },
    { value: "avatar-6", label: "Avatar 6" },
    { value: "avatar-7", label: "Avatar 7" },
    { value: "avatar-8", label: "Avatar 8" },
    { value: "avatar-9", label: "Avatar 9" },
  ]

  const formatOptionLabel = ({ label, value }) => {
    return (
      <div style={{ display: "flex" }}>
        <ProfileImage profileImage={value} />
      </div>
    )
  }

  const customStyles = {
    // This is just to make it match the other FormControls size
    valueContainer: (provided, state) => ({
      ...provided,
      height: "65px",
      marginLeft: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      paddingLeft: "30px",
    }),
  }

  return (
    <Select
      options={options}
      defaultValue={{ value: initialValue }}
      className="profile-image-select"
      styles={customStyles}
      formatOptionLabel={formatOptionLabel}
      onChange={(change, action) => {
        console.debug(`Profile Image Select Change: `, change, action)
        // This is just to make it work like FormControl event handlers
        onChange({
          target: {
            id: controlId, value: change.value
          }
        })
      }}
    />
  )
}
