import React, { useState, useContext } from "react";
import { connect } from 'react-redux';
import { Panel, PageHeader, FormGroup, FormControl, ControlLabel, Button } from "react-bootstrap";
import ProfileImageSelect from '../components/ProfileImageSelect';
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { setUserProfile, setCognitoUsername } from "../actions";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { NavItem } from "react-bootstrap";
import { clearSessionCacheAfterLogout } from "../sessionCache";
import "./Profile.css";
import Debugger from "../components/Debugger";
import logger from '../logger'
import { AnalyticsContext } from "../services/Analytics";

const Profile = ({ cognitoUsername, userHasAuthenticated, userProfile, setUserProfile, history }) => {
  console.log("Displaying the profile", userProfile)

  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields(userProfile);

  const analytics = useContext(AnalyticsContext);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true)

    // Remove any fields which arent changed
    const changes = Object.entries(fields)
      .filter(pair => {
        return userProfile[pair[0]] !== pair[1]
      })
      .reduce((acc, pair) => {
        return { ...acc, [pair[0]]: pair[1] }
      }, {})

    console.debug("Updating the API with the profile changes", changes)
    API.put("apigw", "/profile", {
      body: changes,
    }).then(response => {
      setIsLoading(false)
      console.info("Submission response: ", response)
      setUserProfile(response)
    }).catch(error => {
      setIsLoading(false)
      alert("Saving profile failed. Please see the console.")
      logger.error("Saving profile failed with API call error", error)
    })
  }

  async function handleLogout() {
    await Auth.signOut();
    setCognitoUsername(null)
    clearSessionCacheAfterLogout();

    userHasAuthenticated(false);

    history.push("/");
  }

  return (
    <div className="Profile">
      <Debugger models={{ userProfile: userProfile, cognitoUsername: cognitoUsername }} />
      {!cognitoUsername ?
        <Panel bsStyle="info">
          <Panel.Heading>
            <Panel.Title componentClass="h3">User Profile Unavailable</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            You need to login before you can see / edit your user profile.
        </Panel.Body>
        </Panel>
        :
        <form onSubmit={handleSubmit}>
          <PageHeader>User Profile for {fields.FirstName || ""} {fields.LastName || ""}</PageHeader>
          <NavItem onClick={handleLogout}>Logout</NavItem>
          <div className="formGroupWrapper">
            <FormGroup controlId="ProfileImage" bsSize="large" className="profileImage">
              <ControlLabel>Profile Image</ControlLabel>
              <ProfileImageSelect controlId="ProfileImage" initialValue={fields.ProfileImage} onChange={handleFieldChange} />
            </FormGroup>
            <FormGroup controlId="IsDebugging" bsSize="large" className="isDebugging">
              <ControlLabel>Debug Enabled</ControlLabel>
              <FormControl
                type="checkbox"
                checked={fields.IsDebugging}
                onChange={handleFieldChange}
              />
            </FormGroup>
            {fields.HasWeaverTools ?
              <FormGroup controlId="HasWeaverTools" bsSize="large" className="hasWeaverTools">
                <ControlLabel>Weaver Tools</ControlLabel>
                <div>
                  <Button onClick={() => history.push("/weaver/")}>Go to Weaver Tools</Button>
                  <Button onClick={() => analytics.event("TEST", { some: "data", here: true })}>Push Test Event to Airtable</Button>
                </div>
              </FormGroup>
              : null}
          </div>
          <LoaderButton block type="submit" bsSize="large" isLoading={isLoading}>Save</LoaderButton>
        </form>
      }
    </div>
  );
}


const mapStateToProps = state => ({
  cognitoUsername: state.cognitoUsername,
  userProfile: state.userProfile,
})
const mapDispatchToProps = dispatch => {
  return {
    setUserProfile: (userProfile) => {
      dispatch(setUserProfile(userProfile))
    },
    setCognitoUsername: (username) => {
      dispatch(setCognitoUsername(username))
    },
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile))