import React from "react";
import PropTypes from 'prop-types';
import { Comparator } from 'react-bootstrap-table2-filter';
import Rating from 'react-rating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarFull } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import './RatingFilter.css'

export default class RatingFilter extends React.Component {
  static propTypes = {
    column: PropTypes.object.isRequired,
    onFilter: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props);
    this.filter = this.filter.bind(this);
    this.state = { value: 0 };
  }
  filter() {
    this.props.onFilter({
      number: this.state.value,
      comparator: Comparator.GE,
    });
  }
  render() {
    const stars = (icon) => {
      const star = (className) => {
        return <FontAwesomeIcon className={className} icon={icon} size="2x" />
      }

      return [star("low"), star("low"), star("medium"), star("high"), star("high")]
    }

    return (
      <div className="form-rating form-rating-filter">
        <Rating
          // Take the value from the changes, if missing, take the default value
          initialRating={this.state.value}
          fractions={2}
          stop={5}
          placeholderSymbol={stars(faStarFull)}
          fullSymbol={stars(faStarFull)}
          emptySymbol={stars(faStarEmpty)}
          onClick={(newValue) => {
            console.debug(`Rating Filter Change: `, newValue)
            this.setState({ value: newValue })
            this.props.onFilter({
              number: newValue,
              comparator: Comparator.GE,
            });
          }}
        />
      </div>
    )
  }
}
