import React, { useState, useContext, useEffect } from "react";
import { connect } from 'react-redux';
import { AnalyticsContext } from '../services/Analytics';
import { ButtonToolbar, Form, FormGroup, FormControl, Checkbox, ControlLabel, Label } from "react-bootstrap";
import Debugger from "../components/Debugger"
import LoaderButton from "../components/LoaderButton";
import Spinner from "../components/Spinner";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Rating from 'react-rating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarFull } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import { setContactForm, updateContactForm } from "../actions";
import { API } from "aws-amplify";
import logger from '../logger'
import "./Contact.css";
import PrivateStudioProfessionalMessage from "../components/PrivateStudioProfessionalMessage";
import ReactDatePicker from "react-datepicker";

const Contact = ({ contact, contacts, contactForm, setContactForm, updateContactForm, currentStudio, match, history }) => {
  const [isLoadingGlobals, setIsLoadingGlobals] = useState(true);
  const [isLoadingContact, setIsLoadingContact] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canGoBack, setCanGoBack] = useState(false);

  const defaultModel = {
    isLoading: false,
    lastUpdated: null,
    data: [],
  }

  const analytics = useContext(AnalyticsContext);

  const [roleGroups, setRoleGroups] = useState(defaultModel);
  const [roles, setRoles] = useState(defaultModel);
  const [addressAreas, setAddressAreas] = useState(defaultModel);
  const [budgetRanges, setBudgetRanges] = useState(defaultModel);
  const [professionalTags, setProfessionalTags] = useState(defaultModel);

  // Build a list of the roles, grouped by role group
  const getRoleOptions = () => {
    const results = roleGroups.data.reduce((acc, eachRoleGroup) => {
      const rolesInGroup = roles.data.filter(eachRole => eachRole.roleGroupId === eachRoleGroup.id)
      if (!rolesInGroup.length) return acc
      return [
        ...acc,
        { label: eachRoleGroup.name, options: rolesInGroup.map(eachRole => ({ label: eachRole.name, value: eachRole.id })) },
      ]
    }, [])
    console.log("Role options", results)
    return results
  }
  const roleOptions = getRoleOptions()

  // Build a list of the address areas, grouped by london vs nationwide
  const getAddressAreaOptions = () => {
    const options = addressAreas.data.reduce((acc, each) => {
      return { ...acc, [each.type]: [...acc[each.type], { label: each.name, value: each.id }] }
    }, { "London": [], "Nationwide": [] })
    return [
      { label: "London", options: options["London"] },
      { label: "Nationwide", options: options["Nationwide"] },
    ];
  }
  const addressAreaOptions = getAddressAreaOptions()

  // Build a list of the budget ranges
  const getBudgetRangeOptions = () => {
    return budgetRanges.data.filter(each => each.manuallySelectableOption === true).map(each => ({ label: each.name, value: each.id }))
  }
  const budgetRangeOptions = getBudgetRangeOptions()

  // Build a list of the professional tags
  const getProfessionalTagOptions = () => {
    return professionalTags.data.map(each => ({ label: each.name, value: each.id }))
  }
  const professionalTagOptions = getProfessionalTagOptions()

  useEffect(() => {
    console.debug("Using effect")

    async function loadGlobalConstants() {
      console.info("Loading the global constants")
      API.get("apigw", "/global")
        .then(response => {
          console.debug("Found global constants", response)

          const firstCharLower = string => string.charAt(0).toLowerCase() + string.slice(1)
          const remapFieldsWithFirstCharLower = (object, mappers = {}) => Object.entries(object).reduce((acc, [key, value]) => ({
            ...acc,
            // Lower case the first character of each key, and if there is a mapper by the same name, call the mapper on the value
            [firstCharLower(key)]: mappers[firstCharLower(key)] ? mappers[firstCharLower(key)](value) : value
          }), {})
          const extractSingleValueIfArray = value => Array.isArray(value) ? value[0] : value

          setRoleGroups({ ...roleGroups, data: response.RoleGroups.map(record => remapFieldsWithFirstCharLower(record)) })
          setRoles({ ...roles, data: response.Roles.map(record => remapFieldsWithFirstCharLower(record, { "roleGroupId": extractSingleValueIfArray })) })
          setBudgetRanges({ ...budgetRanges, data: response.BudgetRanges.map(record => remapFieldsWithFirstCharLower(record)) })
          setAddressAreas({ ...addressAreas, data: response.AddressAreas.map(record => remapFieldsWithFirstCharLower(record)) })
          setProfessionalTags({ ...professionalTags, data: response.ProfessionalTags.map(record => remapFieldsWithFirstCharLower(record)) })

          setIsLoadingGlobals(false)
        })
    }
    loadGlobalConstants()

    async function onLoad() {
      console.info("Loading contact form: ", match.params.id, contact)
      setContactForm({
        id: match.params.id,
        contact: contact
      })
      console.debug("Contact loaded")
      buttonFlipper("reset")
      setIsLoadingContact(false);
    }

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, currentStudio]);

  const buttonFlipper = (action) => {
    switch (action) {
      case "submit-start":
        setIsSubmitting(true)
        setIsDeleting(false)
        setCanSubmit(false)
        setCanDelete(false)
        setCanGoBack(false)
        break
      case "submit-done":
        setIsSubmitting(false)
        setIsDeleting(false)
        setCanSubmit(true)
        setCanDelete(true)
        setCanGoBack(true)
        break
      case "delete-start":
        setIsSubmitting(false)
        setIsDeleting(true)
        setCanSubmit(false)
        setCanDelete(false)
        setCanGoBack(false)
        break
      case "delete-done":
        setIsSubmitting(false)
        setIsDeleting(false)
        setCanSubmit(true)
        setCanDelete(true)
        setCanGoBack(true)
        break
      case "reset":
      default:
        setIsSubmitting(false)
        setIsDeleting(false)
        setCanSubmit(true)
        setCanDelete(true)
        setCanGoBack(true)
    }
  }

  const handleSubmit = () => {
    console.info("Submitting contact changes", contactForm.id, contactForm.changes)
    buttonFlipper("submit-start")
    if (analytics && analytics.event) analytics.event("Contact", "Submit")

    // Choose which HTTP method API function and path, based on if we're creating a new contact (POST) or updating an existing one (PUT)
    const apiCall = contactForm.id === "new"
      ?
      API.post("apigw", "/contacts", {
        body: contactForm.changes,
        headers: { StudioAuthorization: `Bearer ${currentStudio.jwt}` }
      })
      :
      API.put("apigw", `/contacts/${contactForm.id}`, {
        body: contactForm.changes,
        headers: { StudioAuthorization: `Bearer ${currentStudio.jwt}` }
      })
    console.debug("Submitting with apiCall", apiCall)

    // Call the API
    apiCall.then(response => {
      buttonFlipper("submit-done")
      console.info("Submission response: ", response)
      // Send to the contact list (which refreshes data from the server)
      history.push(`/contacts`)
    }).catch(error => {
      // TODO: check to see if the error is because of invalid fields (notably, email address already in use by another contact)
      buttonFlipper("submit-done")
      alert("Saving failed. Please see the console.")
      logger.error("Saving failed with API call error", error)
    })
  }

  const handleDelete = () => {
    console.log("Archiving contact: ", contactForm)
    buttonFlipper("delete-start")
    if (analytics && analytics.event) analytics.event("Contact", "Archive")
    API.del("apigw", `/contacts/${contactForm.id}`, {
      headers: { StudioAuthorization: `Bearer ${currentStudio.jwt}` }
    }).then(response => {
      buttonFlipper("delete-done")
      console.info("Deletion response: ", response)
      // Send to the contact list (which refreshes data from the server)
      history.push(`/contacts`)
    }).catch(error => {
      buttonFlipper("delete-done")
      alert("Deletion failed. Please see the console.")
      logger.error("Deletion failed with API call error", error)
    })
  }

  const handleGoBack = () => {
    console.debug("Ignore changes and go back to the contact list")
    if (analytics && analytics.event) analytics.event("Contact", "Go Back")
    history.push(`/contacts`)
  }

  const handleFieldChange = (event) => {
    console.debug(`Storing field change on ContactForm. ${event.target.id}: ${event.target.value}`)
    updateContactForm({ id: event.target.id, value: event.target.value })
  }

  const renderContact = (contactForm) => {
    console.debug("Displaying contact form: ", contactForm)

    const addField = ({ key, defaultValue, label, formatOptionLabel, type = "input", options, rows, doValidation, autoFocus = false, multi = false, disabled = false }) => {
      if (typeof defaultValue === "undefined") defaultValue = contactForm.contact[key]

      if (!label) label = key

      const getValue = () => {
        return key in contactForm.changes ? contactForm.changes[key] : defaultValue
      }

      switch (type) {
        // TODO: Can this be merged into select with a switch over <Select.../> vs <CreatableSelect.../> ?
        case "creatable-select": {
          console.debug("Select default value:", defaultValue, options)
          const customStyles = {
            // This is just to make it match the other FormControls size
            control: (provided, state) => ({
              ...provided,
              paddingTop: multi ? "4px" : "",
              paddingBottom: "4px",
            })
          }
          return (
            <FormGroup key={key} controlId={key} bsSize="large">
              <ControlLabel>{label}</ControlLabel>
              <CreatableSelect
                options={options}
                isMulti={multi}
                autoFocus={autoFocus}
                defaultValue={defaultValue === null ? null : Array.isArray(defaultValue) ? options.filter(option => defaultValue.includes(option.value)) : options.filter(option => option.value === defaultValue)}
                className="form-select"
                styles={customStyles}
                onChange={(change, action) => {
                  console.debug(`Select ${key} Change: `, change, action)
                  // This is just to make it work like FormControl event handlers
                  handleFieldChange({
                    target: {
                      id: key, value: change === null ? change : Array.isArray(change) ? change.map(each => each.value) : change.value
                    }
                  })
                }}
              />
            </FormGroup>
          )
        }
        case "select": {
          const unpackOptionGroups = optionGroups => optionGroups.map(each => each.options).flat()
          const _options = options && options.length && options[0].options ? unpackOptionGroups(options) : options
          const _defaultValue = defaultValue === null ? null : Array.isArray(defaultValue) ? _options.filter(option => defaultValue.includes(option.value)) : _options.filter(option => option.value === defaultValue)
          console.debug("Select default value:", options, defaultValue, _options, _defaultValue)
          const customStyles = {
            // This is just to make it match the other FormControls size
            control: (provided, state) => ({
              ...provided,
              paddingTop: multi ? "4px" : "",
              paddingBottom: "4px",
            })
          }
          return (
            <FormGroup key={key} controlId={key} bsSize="large">
              <ControlLabel>{label}</ControlLabel>
              <Select
                options={options}
                isMulti={multi}
                autoFocus={autoFocus}
                defaultValue={_defaultValue}
                className="form-select"
                styles={customStyles}
                formatOptionLabel={formatOptionLabel}
                onChange={(change, action) => {
                  console.debug(`Select ${key} Change: `, change, action)
                  // This is just to make it work like FormControl event handlers
                  handleFieldChange({
                    target: {
                      id: key, value: change === null ? change : Array.isArray(change) ? change.map(each => each.value) : change.value
                    }
                  })
                }}
              />
            </FormGroup>
          )
        }
        case "rating": {
          const stars = (icon) => {
            const star = (className) => {
              return <FontAwesomeIcon className={className} icon={icon} size="2x" />
            }

            return [star("low"), star("low"), star("medium"), star("high"), star("high")]
          }

          return (
            <FormGroup key={key} controlId={key} bsSize="large">
              <ControlLabel>{label}</ControlLabel>
              <div className="form-rating">
                <Rating
                  // Take the value from the changes, if missing, take the default value
                  placeholderRating={getValue()}
                  stop={5}
                  placeholderSymbol={stars(faStarFull)}
                  fullSymbol={stars(faStarFull)}
                  emptySymbol={stars(faStarEmpty)}
                  onClick={(newValue) => {
                    console.debug(`Rating ${key} Change: `, newValue)
                    // This is just to make it work like FormControl event handlers
                    handleFieldChange({
                      target: {
                        id: key, value: newValue
                      }
                    })
                  }}
                />
              </div>
            </FormGroup>
          )
        }
        case "textarea": {
          return (
            <FormGroup key={key} controlId={key} validationState={doValidation ? doValidation(getValue()) : null} bsSize="large">
              <ControlLabel>{label}</ControlLabel>
              <FormControl
                autoFocus={autoFocus}
                componentClass="textarea"
                rows={rows}
                defaultValue={defaultValue}
                disabled={disabled}
                onChange={handleFieldChange}
              />
            </FormGroup>
          )
        }
        case "date": {
          const getDateValue = () => {
            const value = getValue()
            console.debug(`Getting date value for ${key}:`, value)
            return value ? new Date(value) : value
          }
          const handleDateFieldChange = date => {
            handleFieldChange({ target: { id: key, value: date } })
          }
          return (
            <FormGroup key={key} controlId={key} validationState={doValidation ? doValidation(getValue()) : null} bsSize="large">
              <ControlLabel>{label}</ControlLabel>
              <div>
                <ReactDatePicker
                  dateFormat="dd MMMM yyyy"
                  todayButton="Today"
                  placeholderText="Click to select a date"
                  selected={getDateValue()}
                  disabled={disabled}
                  onChange={handleDateFieldChange}
                />
              </div>
            </FormGroup>
          )
        }
        case "checkbox": {
          const handleCheckboxChange = event => {
            handleFieldChange({ target: { id: key, value: event.target.checked } })
          }
          return (
            <FormGroup key={key} controlId={key} validationState={doValidation ? doValidation(getValue()) : null} bsSize="large">
              <ControlLabel>{label}</ControlLabel>
              <Checkbox
                autoFocus={autoFocus}
                type={type}
                checked={getValue()}
                disabled={disabled}
                onChange={handleCheckboxChange}
              >Checked</Checkbox>
            </FormGroup>
          )
        }
        default:
          return (
            <FormGroup key={key} controlId={key} validationState={doValidation ? doValidation(getValue()) : null} bsSize="large">
              <ControlLabel>{label}</ControlLabel>
              <FormControl
                autoFocus={autoFocus}
                type={type}
                defaultValue={defaultValue}
                disabled={disabled}
                onChange={handleFieldChange}
              />
            </FormGroup>
          )
      }
    }

    const formatOptionLabel = ({ label }) => {
      let style = "default"
      switch (label) {
        case "Used":
          style = "success"
          break
        case "Never Used":
          style = "warning"
          break
        case "Unknown":
          style = "info"
          break
        case "Sin Bin":
          style = "danger"
          break
        default:
          style = "default"
      }

      return (
        <div style={{ display: "flex" }}>
          <Label bsStyle={style}>{label}</Label>
        </div>
      )
    }

    const isValidEmailAddress = input => {
      if (!input) return false
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(input)
    }

    const fields = [
      [
        addField({ key: "Company", autoFocus: true, doValidation: value => !value ? "error" : null }),
        addField({ key: "FirstName", label: "First Name" }),
        addField({ key: "LastName", label: "Last Name" }),
      ],
      [
        addField({
          key: "RolesId", label: "Roles", type: "select", multi: true,
          options: roleOptions
        }),
        addField({
          key: "AddressAreasId", label: "Geographic Coverage Areas", type: "select", multi: true,
          options: addressAreaOptions
        }),
        addField({
          key: "BudgetRangesId", label: "Budget Ranges", type: "select", multi: true,
          options: budgetRangeOptions
        }),
        addField({ key: "Status", type: "select", formatOptionLabel: formatOptionLabel, options: ["Unknown", "Never used", "Used", "Sin Bin"].map(each => { return { value: each, label: each } }) }),
      ],
      [
        addField({ key: "Email", label: "Email Address", type: "email", doValidation: value => !isValidEmailAddress(value) ? "error" : null }),
        addField({ key: "OfficePhone", label: "Office Phone" }),
        addField({ key: "MobilePhone", label: "Mobile Phone" }),
      ],
      addField({ key: "Address", type: "textarea", rows: 3 }),
      addField({ key: "Postcode" }),
      addField({ key: "Website" }),
      addField({
        key: "OwnerArchitect", defaultValue: contactForm.contact.OwnerArchitect ? contactForm.contact.OwnerArchitect[0] : "", label: "Owner", type: "select", options: [{
          value: "", label: "Unknown Owner"
        },
        ...currentStudio.users.map(studioUser => {
          return {
            value: studioUser.id,
            label: `${studioUser.FirstName || ""} ${studioUser.LastName || ""}`,
          }
        })]
      }),
      [
        addField({ key: "BudgetRating", label: "Budget Rating", type: "rating" }),
        addField({ key: "CommsRating", label: "Comms Rating", type: "rating" }),
        addField({ key: "ProjectManagementRating", label: "Project Management Rating", type: "rating" }),
        addField({ key: "TimingRating", label: "Timing Rating", type: "rating" }),
      ],
      addField({
        key: "TagsId", label: "Professional Tags", type: "select", multi: true,
        options: professionalTagOptions
      }),
      [
        addField({ key: "InsurancePublicLiabilityLimit", label: "Public Liability Limit", doValidation: value => value && /[^0-9]/.test(value) ? "error" : null }),
        addField({ key: "InsuranceEmployerLiabilityLimit", label: "Employer Liability Limit", doValidation: value => value && /[^0-9]/.test(value) ? "error" : null }),
        addField({ key: "InsuranceRenewalDate", label: "Insurance Renewal Date", type: "date" }),
      ],
      [
        addField({ key: "CompanyEstablishedYear", label: "Company Established Year", doValidation: value => value && /[^0-9]/.test(value) ? "error" : null }),
        addField({ key: "VettingCompanyDirectorBankruptcy", label: "Vetting: Director Bankruptcy", type: "checkbox" }),
        addField({ key: "VettingCompanyDebt", label: "Vetting: Company Debt", type: "checkbox" }),
      ],
      addField({ key: "Fees" }),
      addField({ key: "Notes", type: "textarea", rows: 5 }),
      [
        addField({ key: "DateAdded", label: "Date Added", disabled: true }),
        addField({ key: "LastModified", label: "Last Modified", disabled: true }),
      ],
    ].map((each, i) => {
      return <div key={i} className="formGroupWrapper">{each}</div>
    })
    return (
      <Form onSubmit={handleSubmit}>
        {fields}
        <ButtonToolbar bsClass="btn-toolbar pull-right">
          <LoaderButton onClick={handleDelete} disabled={!canDelete} isLoading={isDeleting} bsStyle="danger">Archive</LoaderButton>
          <LoaderButton onClick={handleSubmit} disabled={!canSubmit} isLoading={isSubmitting} bsStyle="primary">Save</LoaderButton>
          <LoaderButton onClick={handleGoBack} disabled={!canGoBack} bsStyle="link" >Go Back</LoaderButton>
        </ButtonToolbar>
      </Form>
    )
  }

  return (
    <div className="MyContact">
      <Debugger models={{ models: { contactForm, roleGroups, roles, addressAreas, budgetRanges } }} />
      {(isLoadingGlobals || isLoadingContact) && <Spinner />}
      {currentStudio && !isLoadingGlobals && !isLoadingContact && (
        <>
          <PrivateStudioProfessionalMessage />
          {renderContact(contactForm)}
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  console.debug("mapStateToProps for Contact - url param id: ", ownProps.match.params.id, state.contacts[ownProps.match.params.id], state)

  return {
    contact: state.contacts[ownProps.match.params.id] ? state.contacts[ownProps.match.params.id] : {},
    contacts: state.contacts,
    contactForm: state.contactForm,
    currentStudio: state.currentStudio,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContactForm: (contactForm) => {
      console.log("Setting the initial contact form state: ", contactForm)
      dispatch(setContactForm(contactForm))
    },
    updateContactForm: (change) => {
      console.log("Updating contact form with a change: ", change)
      dispatch(updateContactForm(change))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact)