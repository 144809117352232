import React from 'react';
import { FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import './FormControlPassword.css';

export default function FormControlPassword(props) {

  const hasNumber = value => new RegExp(/[0-9]/).test(value)
  const hasMixed = value => new RegExp(/[a-z]/).test(value) &&
    new RegExp(/[A-Z]/).test(value)

  const isWeak = value => value && hasMixed(value) && hasNumber(value) && value.length > 8
  const isStrong = value => isWeak(value) && value.length > 10

  const strengthStyle = value => isStrong(value) ? "value-good" : isWeak(value) ? "value-ok" : "value-bad"

  const tooltip = (<Tooltip id="overload-right">Must contain at least 8 upper and lower case characters and numbers.</Tooltip>)

  return (
    <OverlayTrigger placement="right" overlay={tooltip}>
      <FormControl
        type='password'
        defaultValue={props.value}
        className={`password-input ${strengthStyle(props.value)}`}
        onChange={props.onChange}
      />
    </OverlayTrigger>
  )
}
