import store from "./store";
import { setCognitoUsername, setUserProfile, setCurrentStudio, setContacts, setWeaverToolsProjects } from "./actions";
import { Cache } from "aws-amplify";

const entryHandlers = [
  {
    load: () => {
      const cognitoUsername = Cache.getItem("cognitoUsername");
      console.log("Setting the cognito username: ", cognitoUsername);
      store.dispatch(setCognitoUsername(cognitoUsername));
    },
    clear: () => {
      Cache.removeItem("cognitoUsername");
      console.log("Clearing the cognito username");
      store.dispatch(setCognitoUsername(null));
    }
  },
  {
    load: () => {
      const userProfile = Cache.getItem("userProfile");
      console.log("Setting the user profile: ", userProfile);
      store.dispatch(setUserProfile(userProfile));
    },
    clear: () => {
      Cache.removeItem("userProfile");
      console.log("Clearing the user profile");
      store.dispatch(setUserProfile(null));
    }
  },
  {
    load: () => {
      const currentStudio = Cache.getItem("currentStudio");
      console.log("Setting the current studio: ", currentStudio);
      store.dispatch(setCurrentStudio(currentStudio));
    },
    clear: () => {
      Cache.removeItem("currentStudio");
      console.log("Clearing the current studio");
      store.dispatch(setCurrentStudio(null));
    }
  },
  {
    load: () => {
      const contacts = Cache.getItem("contacts");
      console.log("Setting the contacts: ", contacts);
      store.dispatch(setContacts(contacts));
    },
    clear: () => {
      Cache.removeItem("contacts");
      console.log("Clearing the contacts");
      store.dispatch(setContacts(null));
    }
  },
  {
    load: () => {
      const weaverToolsProjects = Cache.getItem("weaverToolsProjects");
      console.log("Setting the weaverToolsProjects: ", weaverToolsProjects);
      store.dispatch(setWeaverToolsProjects(weaverToolsProjects));
    },
    clear: () => {
      Cache.removeItem("weaverToolsProjects");
      console.log("Clearing the weaverToolsProjects");
      store.dispatch(setWeaverToolsProjects(null));
    }
  },
]

export function loadSessionCacheFromAuthenticatedUser() {
  console.log("Given we're authenticated, reading state in from the AWS Amplify cache");
  entryHandlers.forEach((each) => {
    if (each.load) each.load();
  })
}

export function clearSessionCacheAfterLogout() {
  console.log("Clearing the AWS Amplify cache")
  entryHandlers.forEach((each) => {
    if (each.clear) each.clear();
  })
}
