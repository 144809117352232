import React, { useState, useEffect } from "react";
import ButterToast, { Cinnamon } from 'butter-toast';
import { API } from "aws-amplify";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import jwt from 'jsonwebtoken'
import logger from '../logger'

// Initialise models, including functions

const sampleModels = {
  isLoading: true,
  sourcingStages: [
    { id: "SHORTLISTING", title: "Shortlisting", descrption: "Sending of leads to professionals, seeking interest in joining the tender process." },
    { id: "TENDERING", title: "Tendering", description: "Once the tender seats are full, professionals are invited to bid against a timeframe." },
    { id: "CONTRACTING", title: "Contracting", description: "Once a prefered bid has been chosen, we negotiate a contract with that individual." },
    { id: "COMPLETED", title: "Completed", description: "Once contracted, the sourcing is complete." },
  ],
}

// Toast defaults

var defaultToast = {
  sticky: true,
  dismiss: ({ dismiss }) => { // When ready to dismiss, pause a little first
    // debugger // TODO: Figure out why this doesnt actually get called! Apparently it should: https://github.com/ealush/butter-toast (seems to be called if you click the X to force dismiss)
    setTimeout(dismiss, 500)
  },
}

// Initialise React Contexts

const defaultModel = {
  isLoading: true,
  lastUpdated: null,
  data: [],
}

export const ProjectStageContext = React.createContext(defaultModel)
export const RoleGroupContext = React.createContext(defaultModel)
export const RoleContext = React.createContext(defaultModel)
export const BudgetRangeContext = React.createContext(defaultModel)
export const AddressAreaContext = React.createContext(defaultModel)
export const ProjectDescriptionContext = React.createContext(defaultModel)
export const ProfessionalTagContext = React.createContext(defaultModel)
export const StudioContext = React.createContext(defaultModel)
export const ProjectContext = React.createContext(defaultModel)
export const ProfessionalContext = React.createContext(defaultModel)
export const SourcingStageContext = React.createContext(defaultModel)
export const SourcingContext = React.createContext(defaultModel)
export const SourcingInviteContext = React.createContext(defaultModel)

// Helper Functions

export const lookupContextByIds = (context, ids, defaultValue = {}) => {
  return Array.isArray(ids) ? ids.map(id => lookupContextById(context, id, defaultValue)) : []
}
export const lookupContextById = (context, id, defaultValue = {}) => {
  const value = context.data.filter(each => each.id === id)[0]
  return value ? value : defaultValue
}

// DataModel React Component

export const DataModel = (props) => {
  // Check
  if (props && props.currentStudio && props.currentStudio.jwt) {
    const token = jwt.decode(props.currentStudio.jwt, { complete: true })
    if (Date.now() >= token.payload.exp * 1000) {
      console.warn("Our currentStudio token has expired. We must go fetch a new one before we can interact with the authenticated API.")
      props.history.push("/studios")
    }
  }

  // DATA 

  const [projectStages, setProjectStages] = useState({ ...defaultModel })
  projectStages.getById = id => projectStages.data.filter(each => each.id === id)[0]

  const [roleGroups, setRoleGroups] = useState({ ...defaultModel })
  roleGroups.getById = id => roleGroups.data.filter(each => each.id === id)[0]

  const [roles, setRoles] = useState({ ...defaultModel })
  roles.getById = id => roles.data.filter(each => each.id === id)[0]

  const [budgetRanges, setBudgetRanges] = useState({ ...defaultModel })
  budgetRanges.getById = id => budgetRanges.data.filter(each => each.id === id)[0]
  budgetRanges.getByValue = value => budgetRanges.data.filter(each => each.lowerValueInclusive <= value && each.upperValueExclusive > value)[0]
  budgetRanges.getNameById = id => budgetRanges.getById(id) ? budgetRanges.getById(id).name : null

  const [addressAreas, setAddressAreas] = useState({ ...defaultModel })
  addressAreas.getById = id => addressAreas.data.filter(each => each.id === id)[0]
  addressAreas.getNameById = id => addressAreas.getById(id) ? addressAreas.getById(id).name : null
  addressAreas.findByName = name => {
    const lowerName = name.toLowerCase()
    return addressAreas.data.map(each => ({ ...each, name: each.name.toLowerCase() })).filter(each => each.name === lowerName)[0]
  }

  const [projectDescriptions, setProjectDescriptions] = useState({ ...defaultModel })
  projectDescriptions.getById = id => projectDescriptions.data.filter(each => each.id === id)[0]

  const [professionalTags, setProfessionalTags] = useState({ ...defaultModel })
  professionalTags.getById = id => professionalTags.data.filter(each => each.id === id)[0]
  professionalTags.getNameById = id => professionalTags.getById(id) ? professionalTags.getById(id).name : null

  const [studio, setStudio] = useState({ ...defaultModel, data: {} })
  studio.getFullNameByUserId = value => {
    const find = id => {
      const user = studio.data.users.filter(each => each.id === id)[0]
      return user ? `${user.FirstName || ""} ${user.LastName || ""}` : null
    }
    return Array.isArray(value) ? value.map(each => find(each)) : find(value)
  }

  const [projects, setProjects] = useState({ ...defaultModel })
  projects.getById = id => projects.data.filter(each => each.id === id)[0]
  projects.getByTeamMemberId = teamMemberId => projects.data.filter(eachProject => {
    return eachProject.team.map(eachTeamMember => eachTeamMember.id).includes(teamMemberId)
  })[0]
  projects.getTeamMemberStatus = teamMemberId => {
    const project = projects.getByTeamMemberId(teamMemberId)
    const member = project && project.team.filter(each => each.id === teamMemberId)[0]
    console.log("TEAM MEMBER STATUS", member)
    return !member ? "UNKNOWN" :
      member.studioProfessionalId || member.weaverProfessionalId ? "COMPLETE" :
        member.sourcingId ? "UNDERWAY" :
          "VACANT"
  }
  projects.createProject = (fields) => {
    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content="Creating a new project ..." scheme={Cinnamon.Crisp.SCHEME_GREEN} />,
    })

    return new Promise((resolve, reject) => API.post("apigw", "/projects", {
      body: {
        // Guard against bad fields by only copying over the ones we support, if they've been set
        projectStageId: fields.projectStageId,
        ref: fields.ref,
        name: fields.name,
        description: fields.description,
        address: fields.address,
        addressPostcode: fields.addressPostcode,
        addressAreaId: fields.addressAreaId,
        budget: fields.budget,
        budgetRangeId: fields.budgetRangeId,
      },
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from POST /projects", response)

        const modifiedProjects = {
          ...projects,
          data: [
            ...projects.data,
            {
              // TODO: Take these fields from the response
              architectLead: null,
              architectOthers: [],
              team: [],
              // Guard against bad fields by only copying over the ones we support, if they've been set
              id: response.id,
              projectStageId: fields.projectStageId,
              ref: fields.ref,
              name: fields.name,
              description: fields.description,
              address: fields.address,
              addressPostcode: fields.addressPostcode,
              addressAreaId: fields.addressAreaId,
              budget: fields.budget,
              budgetRangeId: fields.budgetRangeId,
            }
          ],
        }
        setProjects(modifiedProjects)

        ButterToast.dismiss(toast)
        resolve()
      })
      .catch(error => {
        logger.error("Error when creating a project", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }
  projects.updateProjectFields = (fields) => {
    const projectId = fields && fields.id

    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Changing the project fields for project ${projectId} ...`} scheme={Cinnamon.Crisp.SCHEME_ORANGE} />,
    })

    return new Promise((resolve, reject) => API.put("apigw", `/projects/${projectId}`, {
      body: {
        // Guard against bad fields by only copying over the ones we support, if they've been set
        projectStageId: fields.projectStageId,
        ref: fields.ref,
        name: fields.name,
        description: fields.description,
        address: fields.address,
        addressPostcode: fields.addressPostcode,
        addressAreaId: fields.addressAreaId,
        budget: fields.budget,
        budgetRangeId: fields.budgetRangeId,
        architectLead: fields.architectLead,
        architectOthers: fields.architectOthers,
      },
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from PUT /projects/" + projectId, response)

        const modifiedProjects = {
          ...projects,
          data: projects.data.map(each => each.id !== projectId ? each : {
            ...each,
            // Guard against bad fields by only copying over the ones we support, if they've been set
            projectStageId: fields.projectStageId,
            ref: fields.ref,
            name: fields.name,
            description: fields.description,
            address: fields.address,
            addressPostcode: fields.addressPostcode,
            addressAreaId: fields.addressAreaId,
            budget: fields.budget,
            budgetRangeId: fields.budgetRangeId,
            architectLead: fields.architectLead,
            architectOthers: fields.architectOthers,
          }),
        }
        setProjects(modifiedProjects)

        ButterToast.dismiss(toast)
        resolve()
      })
      .catch(error => {
        logger.error("Error when updating project fields", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }
  projects.removeProject = (projectId) => {
    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Removing the project ${projectId} ...`} scheme={Cinnamon.Crisp.SCHEME_RED} />,
    })

    return new Promise((resolve, reject) => API.del("apigw", `/projects/${projectId}`, {
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from DELETE /projects/{id}", response)
        const modifiedProjects = {
          ...projects,
          data: projects.data.filter(each => each.id !== projectId),
        }
        setProjects(modifiedProjects)

        ButterToast.dismiss(toast)
        resolve()
      })
      .catch(error => {
        logger.error("Error when removing a project", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }
  projects.updateTeamMember = (projectId, memberId, fields) => {
    // TODO: Wire this up to a real service layer (within the model directory?)
    // NOTE: This only needs to call the server when setting either the StudioProfessionalId or the WeaverProfessionalId
    //         -- otherwise it's just updating the model locally (as other actions, like creating a sourcing update the other fields automatically in AirTable)

    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Changing the team member field for project ${projectId} ...`} scheme={Cinnamon.Crisp.SCHEME_ORANGE} />,
    })

    // Pretend to have contacted the server
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const modifiedProjects = {
          ...projects,
          data: projects.data.map(eachProject => eachProject.id !== projectId ? eachProject : {
            ...eachProject,
            team: eachProject.team.map(eachTeamMember => eachTeamMember.id !== memberId ? eachTeamMember : {
              ...eachTeamMember,
              ...fields,
            }),
          }),
        }
        setProjects(modifiedProjects)
        ButterToast.dismiss(toast)
        resolve(modifiedProjects.data.filter(e => e.id === projectId)[0].team.filter(e => e.id === memberId)[0])
      }, 500)
    })
  }
  projects.addTeamMember = (projectId, newMember) => {
    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Adding the team member ${newMember.id} to project ${projectId} ...`} scheme={Cinnamon.Crisp.SCHEME_GREEN} />,
    })

    return new Promise((resolve, reject) => API.post("apigw", `/projects/${projectId}/team`, {
      body: {
        // Guard against bad fields by only copying over the ones we support, if they've been set
        roleId: newMember.roleId,
      },
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from POST /projects/{id}/team", response)
        const modifiedProjects = {
          ...projects,
          data: projects.data.map(each => each.id !== projectId ? each : {
            ...each,
            team: [...each.team, {
              ...newMember,
              id: response.id,
            }],
          }),
        }
        setProjects(modifiedProjects)

        ButterToast.dismiss(toast)
        resolve()
      })
      .catch(error => {
        logger.error("Error when adding a team memeber", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }
  projects.removeTeamMember = (projectId, memberId) => {
    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Removing the team member ${memberId} from project ${projectId} ...`} scheme={Cinnamon.Crisp.SCHEME_RED} />,
    })

    return new Promise((resolve, reject) => API.del("apigw", `/projects/${projectId}/team/${memberId}`, {
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from DELETE /projects/{id}/team/{id}", response)
        const modifiedProjects = {
          ...projects,
          data: projects.data.map(each => each.id !== projectId ? each : {
            ...each,
            team: each.team.filter(each => each.id !== memberId),
          }),
        }
        setProjects(modifiedProjects)

        ButterToast.dismiss(toast)
        resolve()
      })
      .catch(error => {
        logger.error("Error when adding a team memeber", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }

  const [professionals, setProfessionals] = useState({
    ...defaultModel,
  })

  professionals.getById = id => professionals.data.filter(each => each.id === id)[0]
  professionals.getProfessionalsFiltered = (filter) => professionals.data.filter(filter)
  professionals.getProfessionalsFilteredByProject = (projectId, additionalFilter = () => true) => {
    const project = !projectId ? null : projects.getById(projectId)
    const projectFilter = project ? (pro) => {
      return pro.addressAreas.includes(project.addressAreaId) && pro.budgetRanges.includes(project.budgetRangeId)
    } : () => true

    return professionals.getProfessionalsFiltered(projectFilter).filter(additionalFilter)
  }
  professionals.getProfessionalsFromStudio = (projectId) => professionals.getProfessionalsFilteredByProject(projectId, pro => pro.system === "STUDIO")
  professionals.getProfessionalsFromWeaver = (projectId) => professionals.getProfessionalsFilteredByProject(projectId, pro => pro.system === "WEAVER")
  professionals.countProfessionalsFromStudio = () => professionals.getProfessionalsFromStudio().length
  professionals.countProfessionalsFromWeaver = () => professionals.getProfessionalsFromWeaver().length
  professionals.quickCreateInStudio = fields => {
    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content="Quick creating a new studio professional ..." scheme={Cinnamon.Crisp.SCHEME_GREEN} />,
    })

    return new Promise((resolve, reject) => API.post("apigw", "/contacts", {
      body: {
        // Guard against bad fields by only copying over the ones we support, if they've been set
        Company: fields.company,
        RolesId: fields.rolesId,
      },
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from POST /contacts", response)

        const savedProfessional = {
          system: "STUDIO",
          id: response.id,
          company: response.Company,
          rolesId: response.RolesId,
          dateAdded: response.DateAdded,
          addressAreasId: [],
          budgetRangesId: [],
        }

        const modifiedProfessionals = {
          ...professionals,
          data: [
            ...professionals.data,
            savedProfessional,
          ],
        }
        setProfessionals(modifiedProfessionals)

        ButterToast.dismiss(toast)
        resolve(savedProfessional)
      })
      .catch(error => {
        logger.error("Error when quick creating a professional", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }

  const [sourcingStages, setSourcingStages] = useState({
    ...defaultModel,
  })

  const [sourcing, setSourcing] = useState({
    ...defaultModel,
  })

  sourcing.createForTeamMember = member => {
    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Creating the source for member ${member.id} ...`} scheme={Cinnamon.Crisp.SCHEME_GREEN} />,
    })

    const projectId = projects.getByTeamMemberId(member.id).id
    const newSourcing = {
      teamMemberId: member.id,
      tenderSeatCount: 5,
      stage: "SHORTLISTING",
    }

    return new Promise((resolve, reject) => API.post("apigw", `/sourcing`, {
      body: newSourcing,
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from POST /sourcing", response)
        const storedSourcing = { ...newSourcing, id: response.id, }
        console.debug("Storing new sourcing", storedSourcing)
        setSourcing({
          ...sourcing,
          data: [...sourcing.data, storedSourcing,]
        })
        projects.updateTeamMember(projectId, member.id, {
          sourcingId: storedSourcing.id,
        })
          .then((updatedMember) => {
            console.log("THEN 1: ", updatedMember)
            ButterToast.dismiss(toast)
            resolve({ member: updatedMember, sourcing: storedSourcing })
          })
      })
      .catch(error => {
        logger.error("Error when adding sourcing for a team memeber", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }
  sourcing.getById = id => sourcing.data.filter(each => each.id === id)[0]
  sourcing.sortByStatus = (statuses, chainSortFn = () => 0) => (a, b) => {
    const indexOf = (value, list) => {
      const result = list.indexOf(value)
      if (result !== -1) return result

      logger.error(`Looking for ${value} gave me index ${result} when looking through`, statuses)
      return statuses.length
    }

    const aIndex = indexOf(a.status, statuses)
    const bIndex = indexOf(b.status, statuses)

    return aIndex > bIndex ? 1 : aIndex < bIndex ? -1 : chainSortFn(a, b)
  }
  sourcing.sortByPreferred = (chainSortFn = () => 0) => (a, b) => a.preferred < b.preferred ? 1 : a.preferred > b.preferred ? -1 : chainSortFn(a, b)
  sourcing.sortByLastActionDate = (chainSortFn = () => 0) => (a, b) => a.lastActionAt > b.lastActionAt ? 1 : a.lastActionAt < b.lastActionAt ? -1 : chainSortFn(a, b)
  sourcing.getOrderedInvites = (sourcingId, orderedStatuses = ["SELECTED", "ACCEPTED", "INVITED", "REJECTED"]) => {
    // Get a list of all the invites in status
    const rawInvites = sourcingInvites.getInvitesInStatus(sourcingId, orderedStatuses)

    // Ordered by: status [orderedStatuses in that order], then preferred, then date of last action descending
    return [...rawInvites].sort(sourcing.sortByStatus(orderedStatuses, sourcing.sortByPreferred(sourcing.sortByLastActionDate())))
  }
  sourcing.getOrderedInvitesForFilledTenderSeats = sourcingId => {
    const thisSourcing = sourcing.getById(sourcingId)
    if (!thisSourcing) return [];

    const orderedInvites = sourcing.getOrderedInvites(sourcingId, ["SELECTED", "ACCEPTED"])
    const topInvites = orderedInvites.slice(0, thisSourcing.tenderSeatCount)
    return topInvites
  }
  sourcing.getOrderedInvitesForAllTenderSeats = (sourcingId, defaultSeat = {}) => {
    const thisSourcing = sourcing.getById(sourcingId)
    if (!thisSourcing) return [];

    // Get the filled seats
    const filledSeats = sourcing.getOrderedInvitesForFilledTenderSeats(sourcingId)

    // Add preferred invites to any remaining tender seats
    const preferredInvites = sourcing.getOrderedInvites(sourcingId, ["INVITED"]).filter(invite => invite.preferred)
    const preferredSeats = thisSourcing.tenderSeatCount - filledSeats.length
    const topInvites = [...filledSeats, ...preferredInvites.slice(0, preferredSeats)]

    // Mark any remaining seats are vacant with the default to complete our set
    const vacantSeats = thisSourcing.tenderSeatCount - topInvites.length
    const tenderSeats = topInvites.concat(Array(vacantSeats).fill(defaultSeat))
    return tenderSeats
  }
  sourcing.getInvitesBySourceType = (sourcingId, sourceType) => {
    return sourcing.getOrderedInvites(sourcingId).filter(invite => invite.sourceType === sourceType)
  }
  sourcing.isAllTenderSeatsFilled = sourcingId => {
    const filledTenderSeats = sourcing.getOrderedInvitesForFilledTenderSeats(sourcingId)
    const thisSourcing = sourcing.getById(sourcingId)
    if (!thisSourcing) return false
    const tenderSeatCount = thisSourcing.tenderSeatCount
    console.debug(`isAllTenderSeatsFilled comparing ${tenderSeatCount} and coming up with ${filledTenderSeats.length === tenderSeatCount} with:`, filledTenderSeats)
    return filledTenderSeats.length === tenderSeatCount
  }
  sourcing.setStage = (sourcingId, stage) => {
    // TODO
    console.log(`Setting the sourcingId ${sourcingId} to stage ${stage}`)
    const showErrorToast = (content = "The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message.") => {
      ButterToast.raise({
        ...defaultToast,
        content: <Cinnamon.Crisp title="Error Setting Stage" content={content} scheme={Cinnamon.Crisp.SCHEME_RED} />,
      })
    }

    const thisSourcing = sourcing.getById(sourcingId)
    if (!thisSourcing || !stage) {
      logger.error("Cannot find either the sourcing or stage", sourcingId, thisSourcing, stage)
      showErrorToast()
      return
    }

    // Validate
    switch (stage) {
      case "TENDERING": {
        // We must have all our tender seats filled for us to start tendering with them
        if (!sourcing.isAllTenderSeatsFilled(sourcingId)) {
          logger.error("Cannot move to the TENDERING stage before all the tender seats are filled.")
          showErrorToast("Cannot move to the TENDERING stage before all the tender seats are filled.")
          return
        }
        break
      }
      case "CONTRACTING": {
        const selected = sourcing.getOrderedInvites(sourcingId, ["SELECTED"])
        // We must have selected our preferred tender seat
        if (!selected || !selected.length === 1) {
          logger.error("Cannot move to the CONTRACTING stage before a professional is preferred.")
          showErrorToast("Cannot move to the CONTRACTING stage before a professional is preferred.")
          return
        }
        break
      }
      case "COMPLETED": {
        // We must be in the CONTRACTING stage
        if (thisSourcing.stage !== "CONTRACTING") {
          logger.error("Cannot only move to the COMPLETED stage from CONTRACTING.")
          showErrorToast("Cannot only move to the COMPLETED stage from CONTRACTING.")
          return
        }
        break
      }
      default: {
        logger.error(`Setting the sourcing stage to ${stage} is unsupported.`)
        showErrorToast()
        return
      }
    }

    // Set the stage
    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Setting the stage to ${stage} for sourcing ${sourcingId} ...`} scheme={Cinnamon.Crisp.SCHEME_ORANGE} />,
    })

    return new Promise((resolve, reject) => API.put("apigw", `/sourcing/${sourcingId}`, {
      body: {
        stage: stage
      },
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from PUT /sourcing/{id}", response)
        const modifiedSourcing = {
          ...sourcing,
          data: sourcing.data.map(each => each.id !== sourcingId ? each : {
            ...each,
            stage: stage,
          }),
        }
        setSourcing(modifiedSourcing)
        ButterToast.dismiss(toast)

        // For any preferred invites in the INVITED or REJECTED stages, remove the preferred status
        const invites = sourcing.getOrderedInvites(sourcingId, ["INVITED", "REJECTED"])
        const promises = invites.filter(invite => invite.preferred).map(invite => sourcingInvites.toggleInvitePreferred(invite.id))
        Promise.all(promises)

        resolve()
      })
      .catch(error => {
        logger.error("Error when updating the sourcing stage", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }
  sourcing.updateSourcing = (sourcingId, fields) => {
    const thisSourcing = sourcing.getById(sourcingId)
    if (!thisSourcing) {
      logger.error(`Unable to update sourcing with ${JSON.stringify(fields)} as I cannot find the sourcing: ${sourcingId}`)
      return
    }
    if (fields.tenderSeatCount < 1) {
      logger.error("Cannot reduce the tenderSeatCount below 1 for sourcing", thisSourcing)
      return
    }

    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Updating sourcing ${sourcingId} ...`} scheme={Cinnamon.Crisp.SCHEME_ORANGE} />,
    })

    const payload = {}
    if (fields.tenderSeatCount) payload.tenderSeatCount = fields.tenderSeatCount
    if (fields.tenderStartDate) payload.tenderStartDate = fields.tenderStartDate
    if (fields.tenderReturnDate) payload.tenderReturnDate = fields.tenderReturnDate
    if (fields.worksStartDate) payload.worksStartDate = fields.worksStartDate

    return new Promise((resolve, reject) => API.put("apigw", `/sourcing/${sourcingId}`, {
      body: payload,
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from PUT /sourcing/{id}", response)
        const modifiedSourcing = {
          ...sourcing,
          data: sourcing.data.map(each => each.id !== sourcingId ? each : {
            ...each,
            tenderSeatCount: response.TenderSeatCount,
            tenderStartDate: response.TenderStartDate ? new Date(response.TenderStartDate) : response.TenderStartDate,
            tenderReturnDate: response.TenderReturnDate ? new Date(response.TenderReturnDate) : response.TenderReturnDate,
            worksStartDate: response.WorksStartDate ? new Date(response.WorksStartDate) : response.WorksStartDate,
          }),
        }
        setSourcing(modifiedSourcing)
        ButterToast.dismiss(toast)
        resolve()
      })
      .catch(error => {
        logger.error(`Error when updating the sourcing ${sourcingId} with the payload ${JSON.stringify(payload)}`, error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }
  sourcing.tenderSeatsIncrement = (sourcingId) => {
    const thisSourcing = sourcing.getById(sourcingId)
    if (!thisSourcing) {
      logger.error(`Unable to increment the tender seats for an unknown sourcing: ${sourcingId}`)
      return
    }
    sourcing.updateSourcing(sourcingId, { tenderSeatCount: thisSourcing.tenderSeatCount + 1 })
  }
  sourcing.tenderSeatsDecrement = (sourcingId) => {
    const thisSourcing = sourcing.getById(sourcingId)
    if (!thisSourcing) {
      logger.error(`Unable to decrement the tender seats for an unknown sourcing: ${sourcingId}`)
      return
    }
    sourcing.updateSourcing(sourcingId, { tenderSeatCount: thisSourcing.tenderSeatCount - 1 })
  }
  sourcing.setTenderStartDate = (sourcingId, date) => {
    sourcing.updateSourcing(sourcingId, { tenderStartDate: date })
  }
  sourcing.setTenderReturnDate = (sourcingId, date) => {
    sourcing.updateSourcing(sourcingId, { tenderReturnDate: date })
  }
  sourcing.setWorksStartDate = (sourcingId, date) => {
    sourcing.updateSourcing(sourcingId, { worksStartDate: date })
  }
  sourcing.addInvites = (sourcingId, invites) => {
    const allInviteProfessionalIds = sourcingInvites.getAllInvites(sourcingId).map(each => each.professionalId)
    const nonExistingInvites = invites.filter(each => !allInviteProfessionalIds.includes(each.professional.id))

    const newInvites = nonExistingInvites.map(each => ({
      sourcingId: sourcingId,
      sourceType: each.professional.system,
      studioProfessionalId: each.professional.system === "STUDIO" ? each.professional.id : null,
      weaverProfessionalId: each.professional.system === "WEAVER" ? each.professional.id : null,
      status: "INVITED",
      preferred: false,
      lastActionAt: null, // This is overriden server side to "now"
    }))

    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Sending new invites for sourcing ${sourcingId} ...`} scheme={Cinnamon.Crisp.SCHEME_GREEN} />,
    })

    return new Promise((resolve, reject) => API.post("apigw", `/sourcing/invites`, {
      body: {
        invites: newInvites,
      },
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from POST /sourcing/invites", response)
        const savedInvites = response.map(invite => ({
          id: invite.id,
          sourcingId: invite.SourcingId[0],
          sourceType: invite.SourceType,
          professionalId: invite.SourceType === "STUDIO" ? invite.StudioProfessionalId[0] : invite.SourceType === "WEAVER" ? invite.WeaverProfessionalId : null,
          studioProfessionalId: Array.isArray(invite.StudioProfessionalId) ? invite.StudioProfessionalId[0] : null,
          weaverProfessionalId: invite.WeaverProfessionalId,
          status: invite.Status,
          lastActionAt: invite.LastActionAt,
          preferred: invite.Preferred,
        }))
        console.debug("Adding the following invites locally", savedInvites, "to those already invited", sourcingInvites.data)
        const modifiedSourcingInvites = {
          ...sourcingInvites,
          data: [
            ...sourcingInvites.data,
            ...savedInvites,
          ],
        }
        setSourcingInvites(modifiedSourcingInvites)
        ButterToast.dismiss(toast)
        resolve(savedInvites)
      })
      .catch(error => {
        logger.error("Error when sending new invites", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }

  const [sourcingInvites, setSourcingInvites] = useState({
    ...defaultModel,
  })
  sourcingInvites.reload = async () => {
    const firstCharLower = string => string.charAt(0).toLowerCase() + string.slice(1)
    const remapFieldsWithFirstCharLower = (object, mappers = {}) => Object.entries(object).reduce((acc, [key, value]) => ({
      ...acc,
      // Lower case the first character of each key, and if there is a mapper by the same name, call the mapper on the value
      [firstCharLower(key)]: mappers[firstCharLower(key)] ? mappers[firstCharLower(key)](value) : value
    }), {})
    const extractSingleValueIfArray = value => Array.isArray(value) ? value[0] : value

    // Fetch the data models
    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Initialise Data" content="Loading the sourcing..." scheme={Cinnamon.Crisp.SCHEME_BLUE} />,
    })

    return API.get("apigw", `/sourcing/invites`, {
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug("Got response from /sourcing/invites", response)
        console.info("Settings sourcing to response")
        console.debug("DO SOURCING INVITES")
        const mapProfessionalId = record => ({
          ...record,
          professionalId: record.studioProfessionalId ? record.studioProfessionalId : record.weaverProfessionalId ? record.weaverProfessionalId : null,
        })
        setSourcingInvites({
          ...sourcingInvites, isLoading: false, data: response.map(record => ({
            preferred: false,
            ...mapProfessionalId(remapFieldsWithFirstCharLower(record, {
              "sourcingId": extractSingleValueIfArray,
              "studioProfessionalId": extractSingleValueIfArray,
            }))
          }))
        })
        ButterToast.dismiss(toast)
      })
      .catch(error => {
        logger.error("Error when getching sourcing", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Initialising Sourcing" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        throw error
      })
  }
  sourcingInvites.getById = id => sourcingInvites.data.filter(each => each.id === id)[0]
  sourcingInvites.getAllInvites = sourcingId => sourcingInvites.data.filter(each => each.sourcingId === sourcingId)
  sourcingInvites.getInvitesInStatus = (sourcingId, statuses) => sourcingInvites.getAllInvites(sourcingId).filter(each => statuses.includes(each.status))
  // sourcingInvites.getAcceptedInvites = sourcingId => sourcingInvites.getInvitesInStatus(sourcingId, ["ACCEPTED"])
  sourcingInvites.mapFromIds = (inviteIds) => inviteIds.map(sourcingInvites.getById)
  sourcingInvites.toggleInvitePreferred = (inviteId) => {
    const invite = sourcingInvites.getById(inviteId)
    if (!invite) throw new Error("Unable to find invite by ID", inviteId)

    return sourcingInvites.toggleInvitePreferredByObject(invite)
  }
  sourcingInvites.toggleInvitePreferredByObject = (invite) => {
    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Toggling the invite ${invite.id} preferred setting ...`} scheme={Cinnamon.Crisp.SCHEME_ORANGE} />,
    })

    return new Promise((resolve, reject) => API.put("apigw", `/sourcing/invites/${invite.id}`, {
      body: {
        preferred: !invite.preferred,
      },
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug(`Got response from PUT /sourcing/invites/${invite.id}`, response)
        if (!Array.isArray(response) || response.length !== 1) throw new Error("Expected a single response element")

        const modifiedSourcingInvites = {
          ...sourcingInvites,
          data: sourcingInvites.data.map(each => each.id !== invite.id ? each : {
            ...each,
            preferred: response[0].Preferred,
          }),
        }
        setSourcingInvites(modifiedSourcingInvites)
        ButterToast.dismiss(toast)
        resolve()
      })
      .catch(error => {
        logger.error("Error when sending new invites", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }
  sourcingInvites.setInviteStatus = (inviteId, newStatus) => {
    if (newStatus !== "INVITED" && newStatus !== "ACCEPTED" && newStatus !== "REJECTED" && newStatus !== "SELECTED") {
      logger.error(`Cannot set the invite ${inviteId} to the status ${newStatus}`)
      return
    }
    // TODO: Wire this up to a real service layer (within the model directory?)
    var toast = ButterToast.raise({
      ...defaultToast,
      content: <Cinnamon.Crisp title="Updating Data" content={`Setting the invite ${inviteId} status to ${newStatus} ...`} scheme={Cinnamon.Crisp.SCHEME_ORANGE} />,
    })

    return new Promise((resolve, reject) => API.put("apigw", `/sourcing/invites/${inviteId}`, {
      body: {
        status: newStatus,
      },
      headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
    })
      .then(response => {
        console.debug(`Got response from PUT /sourcing/invites/${inviteId}`, response)

        // Convert the response array into an object, keyed off the id
        const responseObject = response.reduce((acc, each) => ({ ...acc, [each.id]: each }), {})

        // For each of the responses, set their status
        const modifiedSourcingInvites = {
          ...sourcingInvites,
          data: sourcingInvites.data.map(each => !responseObject[each.id] ? each : {
            ...each,
            status: responseObject[each.id].Status,
            lastActionAt: responseObject[each.id].LastActionAt,
          }),
        }
        console.debug("Modifying the sourcing invites to: ", modifiedSourcingInvites, "using", responseObject)
        setSourcingInvites(modifiedSourcingInvites)

        // If our new status was SELECTED then also update the Sourcing (not just the Invites)
        const onceDone = () => {
          ButterToast.dismiss(toast)
          resolve()
        }

        if (newStatus === "SELECTED") {
          const invite = sourcingInvites.getById(inviteId)
          const thisSourcing = sourcing.getById(invite.sourcingId)
          const project = projects.getByTeamMemberId(thisSourcing.teamMemberId)

          projects.updateTeamMember(project.id, thisSourcing.teamMemberId, {
            studioProfessionalId: invite.studioProfessionalId,
            weaverProfessionalId: invite.weaverProfessionalId,
          })
            .then(onceDone)
        } else {
          onceDone()
        }
      })
      .catch(error => {
        logger.error("Error when sending new invites", error)
        ButterToast.dismiss(toast)
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Updating Data" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        reject(error)
      }))
  }
  sourcingInvites.selectInvite = (inviteId) => {
    return sourcingInvites.setInviteStatus(inviteId, "SELECTED")
  }
  sourcingInvites.acceptInvite = (inviteId) => {
    return sourcingInvites.setInviteStatus(inviteId, "ACCEPTED")
  }
  sourcingInvites.rejectInvite = (inviteId) => {
    return sourcingInvites.setInviteStatus(inviteId, "REJECTED")
  }

  // INITIALISE

  useEffect(() => {
    console.log("Loading the data model")

    // The response is a thin layer over Airtable, meaning we need some helper functions...
    // 1. Our field names start upper cased, but we've built everything on names starting with lower case
    // 2. Where we have single foreign keys, they're single element arrays - but we expect there to be single values, so we have to unpack the array on those fields
    const firstCharLower = string => string.charAt(0).toLowerCase() + string.slice(1)
    const remapFieldsWithFirstCharLower = (object, mappers = {}) => Object.entries(object).reduce((acc, [key, value]) => ({
      ...acc,
      // Lower case the first character of each key, and if there is a mapper by the same name, call the mapper on the value
      [firstCharLower(key)]: mappers[firstCharLower(key)] ? mappers[firstCharLower(key)](value) : value
    }), {})
    const extractSingleValueIfArray = value => Array.isArray(value) ? value[0] : value
    const convertToDate = value => value ? new Date(value) : value
    const convertToBoolean = value => value ? true : false
    const extractSingleDateValueIfArray = value => convertToDate(extractSingleValueIfArray(value))
    const extractSingleBooleanValueIfArray = value => convertToBoolean(extractSingleValueIfArray(value))

    // Load the global settings
    const loadSettings = () => {
      // Fetch the settings models
      var toastSettings = ButterToast.raise({
        ...defaultToast,
        content: <Cinnamon.Crisp title="Initialise Settings" content="Loading the setting models (stages, roleGroups, roles)..." scheme={Cinnamon.Crisp.SCHEME_BLUE} />,
      })

      return new Promise((resolve, reject) => API.get("apigw", `/global`)
        .then(response => {
          console.debug("Got response from /global", response)

          console.info("Settings project stage / roleGroup / role data / budget ranges / address areas / project descriptions to response")

          console.debug("DO PROJECT STAGES")
          setProjectStages({ ...projectStages, isLoading: false, data: response.ProjectStages.map(record => remapFieldsWithFirstCharLower(record)) })
          console.debug("DO ROLE GROUPS")
          setRoleGroups({ ...roleGroups, isLoading: false, data: response.RoleGroups.map(record => remapFieldsWithFirstCharLower(record)) })
          console.debug("DO ROLES")
          setRoles({ ...roles, isLoading: false, data: response.Roles.map(record => remapFieldsWithFirstCharLower(record, { "roleGroupId": extractSingleValueIfArray })) })
          console.debug("DO BUDGET RANGES")
          setBudgetRanges({ ...budgetRanges, isLoading: false, data: response.BudgetRanges.map(record => remapFieldsWithFirstCharLower(record)) })
          console.debug("DO ADDRESS AREAS")
          setAddressAreas({ ...addressAreas, isLoading: false, data: response.AddressAreas.map(record => remapFieldsWithFirstCharLower(record)) })
          console.debug("DO PROJECT DESCRIPTIONS")
          setProjectDescriptions({ ...projectDescriptions, isLoading: false, data: response.ProjectDescriptions.map(record => remapFieldsWithFirstCharLower(record)) })
          console.debug("DO PROFESSIONAL TAGS")
          setProfessionalTags({ ...professionalTags, isLoading: false, data: response.ProfessionalTags.map(record => remapFieldsWithFirstCharLower(record)) })

          console.info("Settings sourcing stages data to sample model data", sampleModels)

          console.debug("DO SOURCING STAGES")
          setSourcingStages({ ...sourcingStages, isLoading: false, data: sampleModels.sourcingStages })
          ButterToast.dismiss(toastSettings)
          resolve()
        })
        .catch(error => {
          logger.error("Error when fetching globals", error)
          ButterToast.dismiss(toastSettings)
          ButterToast.raise({
            ...defaultToast,
            content: <Cinnamon.Crisp title="Error Initialising Globals" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
          })
          reject(error)
        }))
    }

    const loadStudio = async () => {
      if (!props.currentStudio || !props.currentStudio.jwt) {
        ButterToast.raise({
          ...defaultToast,
          content: <Cinnamon.Crisp title="Error Initialising Studio" content="Missing current studio! Please login and select a studio." scheme={Cinnamon.Crisp.SCHEME_RED} />,
        })
        throw new Error("Missing current studio! Please login and select a studio.")
      }

      setStudio({ ...studio, isLoading: false, data: props.currentStudio })
    }

    const loadProjects = async () => {
      // Fetch the data models
      var toast = ButterToast.raise({
        ...defaultToast,
        content: <Cinnamon.Crisp title="Initialise Data" content="Loading the projects..." scheme={Cinnamon.Crisp.SCHEME_BLUE} />,
      })

      return API.get("apigw", `/projects`, {
        headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
      })
        .then(response => {
          console.debug("Got response from /projects", response)
          console.info("Settings projects to response")
          console.debug("DO PROJECTS")
          setProjects({
            ...projects, isLoading: false, data: response.projects.map(record => remapFieldsWithFirstCharLower(record, {
              "projectStageId": extractSingleValueIfArray,
              "addressAreaId": extractSingleValueIfArray,
              "budgetRangeId": extractSingleValueIfArray,
              "architectLead": extractSingleValueIfArray,
              "team": value => Array.isArray(value) ? value.map(member => remapFieldsWithFirstCharLower(member, {
                "roleId": extractSingleValueIfArray,
                "sourcingId": extractSingleValueIfArray,
              })) : value,
            }))
          })
          // TODO: Add in the weaver & studio professionals
          ButterToast.dismiss(toast)
        })
        .catch(error => {
          logger.error("Error when getching projects", error)
          ButterToast.dismiss(toast)
          ButterToast.raise({
            ...defaultToast,
            content: <Cinnamon.Crisp title="Error Initialising Projects" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
          })
          throw error
        })
    }

    const loadSourcing = async () => {
      // Fetch the data models
      var toast = ButterToast.raise({
        ...defaultToast,
        content: <Cinnamon.Crisp title="Initialise Data" content="Loading the sourcing..." scheme={Cinnamon.Crisp.SCHEME_BLUE} />,
      })

      return API.get("apigw", `/sourcing`, {
        headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
      })
        .then(response => {
          console.debug("Got response from /sourcing", response)
          console.info("Settings sourcing to response")
          console.debug("DO SOURCING")
          setSourcing({
            ...sourcing, isLoading: false, data: response.map(record => remapFieldsWithFirstCharLower(record, {
              "teamMemberId": extractSingleValueIfArray,
              "tenderStartDate": convertToDate,
              "tenderReturnDate": convertToDate,
              "worksStartDate": convertToDate,
            }))
          })
          ButterToast.dismiss(toast)
        })
        .catch(error => {
          logger.error("Error when getching sourcing", error)
          ButterToast.dismiss(toast)
          ButterToast.raise({
            ...defaultToast,
            content: <Cinnamon.Crisp title="Error Initialising Sourcing" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
          })
          throw error
        })
    }

    // NOTE: We are downloading ALL the studio professionals - this might become a lot of data!
    // NOTE: This is currently mapping from the /contacts API end point - extension down this path may become untennable
    const loadStudioProfessionals = async () => {
      // Fetch the data models
      var toast = ButterToast.raise({
        ...defaultToast,
        content: <Cinnamon.Crisp title="Initialise Data" content="Loading the studio professionals..." scheme={Cinnamon.Crisp.SCHEME_BLUE} />,
      })

      return API.get("apigw", `/contacts`, {
        headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
      })
        .then(response => {
          console.debug("Got studio professionals response from /contacts", response)
          console.info("Adding studio professionals to response (dedup on ID)")
          console.debug("DO STUDIO PROFESSIONALS")
          // build the professionals
          const numberOrZero = value => typeof value === "number" ? value : 0
          const roundHalf = value => Math.round(value * 2) / 2
          const mappedProfessionals = response.contacts.map(record => ({
            // Identify the source
            system: "STUDIO",
            // Compute the average rating for the professional
            averageRating: roundHalf((
              numberOrZero(record.BudgetRating) +
              numberOrZero(record.CommsRating) +
              numberOrZero(record.ProjectManagementRating) +
              numberOrZero(record.TimingRating)
            ) / 4),
            // Initalise the arrays
            rolesId: [],
            addressAreasId: [],
            budgetRangesId: [],
            tagsId: [],
            // Copying the fields passed in
            ...remapFieldsWithFirstCharLower(record, {
              "studioId": extractSingleValueIfArray,
            }),
          }))
          // remove any professionals that match our newly downloaded professionals
          const mappedProfessionalIds = mappedProfessionals.map(pro => pro.id)
          const filteredExistingProfessioanals = professionals.data.filter(existingPro => !mappedProfessionalIds.includes(existingPro.id))
          // comined the existing professionals and our newly downloaded professionals
          const newProfessionals = { ...professionals, isLoading: false, data: [...filteredExistingProfessioanals, ...mappedProfessionals] }
          setProfessionals(newProfessionals)

          ButterToast.dismiss(toast)
          return newProfessionals
        })
        .catch(error => {
          logger.error("Error when fetching studio professionals", error)
          ButterToast.dismiss(toast)
          ButterToast.raise({
            ...defaultToast,
            content: <Cinnamon.Crisp title="Error Initialising Studio Professionals" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
          })
          throw error
        })
    }

    const loadWeaverProfessionals = async (newProfessionals) => {
      // Fetch the data models
      var toast = ButterToast.raise({
        ...defaultToast,
        content: <Cinnamon.Crisp title="Initialise Data" content="Loading the weaver professionals..." scheme={Cinnamon.Crisp.SCHEME_BLUE} />,
      })

      return API.get("apigw", `/weaver`, {
        headers: { StudioAuthorization: `Bearer ${props.currentStudio.jwt}` }
      })
        .then(response => {
          console.debug("Got weaver professionals response from /weaver", response)
          console.info("Adding studio professionals to response (dedup on ID)")
          console.debug("DO WEAVER PROFESSIONALS")
          // build the professionals
          const arrayFromCSV = value => typeof value === "string" ? value.replace(/[^A-Za-z0-9,]+/g, "").split(",") : Array.isArray(value) ? value : []
          const numberOrZero = value => typeof value === "number" ? value : 0
          const roundHalf = value => Math.round(value * 2) / 2
          const mappedProfessionals = response.map(record => ({
            // Identify the source
            system: "WEAVER",
            // Compute the average rating for the professional
            averageRating: roundHalf((
              numberOrZero(extractSingleValueIfArray(record.BudgetRating)) +
              numberOrZero(extractSingleValueIfArray(record.CommsRating)) +
              numberOrZero(extractSingleValueIfArray(record.ProjectManagementRating)) +
              numberOrZero(extractSingleValueIfArray(record.TimingRating))
            ) / 4),
            // Initalise the arrays
            rolesId: [],
            addressAreasId: [],
            budgetRangesId: [],
            tagsId: [],
            // Copying the fields passed in
            ...remapFieldsWithFirstCharLower(record, {
              "company": extractSingleValueIfArray,
              "firstName": extractSingleValueIfArray,
              "lastName": extractSingleValueIfArray,
              "website": extractSingleValueIfArray,
              "tenderCount": extractSingleValueIfArray,
              "budgetRating": extractSingleValueIfArray,
              "commsRating": extractSingleValueIfArray,
              "projectManagementRating": extractSingleValueIfArray,
              "timingRating": extractSingleValueIfArray,
              "reference1": extractSingleValueIfArray,
              "reference2": extractSingleValueIfArray,
              "portfolioImage1": extractSingleValueIfArray,
              "portfolioImage2": extractSingleValueIfArray,
              "portfolioImage3": extractSingleValueIfArray,
              "portfolioImage4": extractSingleValueIfArray,
              "insurancePublicLiabilityLimit": extractSingleValueIfArray,
              "insuranceEmployerLiabilityLimit": extractSingleValueIfArray,
              "insuranceRenewalDate": extractSingleDateValueIfArray,
              "companyEstablishedYear": extractSingleValueIfArray,
              "vettingCompanyDirectorBankruptcy": extractSingleBooleanValueIfArray,
              "vettingCompanyDebt": extractSingleBooleanValueIfArray,
              "dateAdded": extractSingleValueIfArray,
              "leadsVsTenderRatio": extractSingleValueIfArray,
              "lastLead": extractSingleValueIfArray,
              // TODO: The below for rolesId - which is currently just a single string array, which works fine for a pro with a single role.
              // Convert addressAreasId / budgetRangesId / tags to an array
              "addressAreasId": value => arrayFromCSV(extractSingleValueIfArray(value)),
              "budgetRangesId": value => arrayFromCSV(extractSingleValueIfArray(value)),
              "tagsId": value => arrayFromCSV(extractSingleValueIfArray(value)),
            }),
          }))
          // remove any professionals that match our newly downloaded professionals
          const mappedProfessionalIds = mappedProfessionals.map(pro => pro.id)
          const filteredExistingProfessioanals = newProfessionals.data.filter(existingPro => !mappedProfessionalIds.includes(existingPro.id))
          // comined the existing professionals and our newly downloaded professionals
          setProfessionals({ ...newProfessionals, isLoading: false, data: [...filteredExistingProfessioanals, ...mappedProfessionals] })

          ButterToast.dismiss(toast)
        })
        .catch(error => {
          logger.error("Error when fetching studio professionals", error)
          ButterToast.dismiss(toast)
          ButterToast.raise({
            ...defaultToast,
            content: <Cinnamon.Crisp title="Error Initialising Weaver Professionals" content="The system is currently unavaialble for use. Please contact Weaver for support. You will need to reload this page to remove this message." scheme={Cinnamon.Crisp.SCHEME_RED} />,
          })
          throw error
        })
    }

    // 
    setTimeout(() => {
      loadSettings()
        .then(loadStudio)
        .then(loadStudioProfessionals)
        .then(loadWeaverProfessionals)
        .then(loadProjects)
        .then(loadSourcing)
        .then(sourcingInvites.reload)
        .then(results => {
          console.log("Data loading complete")
          return results
        })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ProjectStageContext.Provider value={projectStages}>
        <RoleGroupContext.Provider value={roleGroups}>
          <RoleContext.Provider value={roles}>
            <BudgetRangeContext.Provider value={budgetRanges}>
              <AddressAreaContext.Provider value={addressAreas}>
                <ProjectDescriptionContext.Provider value={projectDescriptions}>
                  <ProfessionalTagContext.Provider value={professionalTags}>
                    <StudioContext.Provider value={studio}>
                      <ProjectContext.Provider value={projects}>
                        <ProfessionalContext.Provider value={professionals}>
                          <SourcingStageContext.Provider value={sourcingStages}>
                            <SourcingContext.Provider value={sourcing}>
                              <SourcingInviteContext.Provider value={sourcingInvites}>
                                {props.children}
                              </SourcingInviteContext.Provider>
                            </SourcingContext.Provider>
                          </SourcingStageContext.Provider>
                        </ProfessionalContext.Provider>
                      </ProjectContext.Provider>
                    </StudioContext.Provider>
                  </ProfessionalTagContext.Provider>
                </ProjectDescriptionContext.Provider>
              </AddressAreaContext.Provider>
            </BudgetRangeContext.Provider>
          </RoleContext.Provider>
        </RoleGroupContext.Provider>
      </ProjectStageContext.Provider>
    </>
  )
}

const mapStateToProps = state => {
  return {
    cognitoUsername: state.cognitoUsername,
    currentStudio: state.currentStudio
  }
}
export const DataModelRedux = withRouter(connect(mapStateToProps)(DataModel))