import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import FormControlPassword from "../components/FormControlPassword";
import { useFormFields } from "../libs/hooksLib";
import "./Signup.css";
import { Auth, API } from "aws-amplify";

const SignupEmailExists = ({ email, close, history }) => {
  return (
    <Modal show={email !== null} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Email Exists</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>The email address <b>{email}</b> already has an account in our system.</p>
        <p>Instead, please try to <Link to={`/login?email=${encodeURIComponent(email)}`}>login</Link>, where you can also reset your password.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} bsStyle="link">Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function Signup(props) {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    phone: "",
    suggestedStudioName: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [existingEmail, setExistingEmail] = useState(null);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword &&
      fields.firstName.length > 0 &&
      fields.lastName.length > 0 &&
      fields.phone.length > 0 &&
      fields.suggestedStudioName.length > 0
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      console.log(`Signing up ${fields.email}`)
      await Auth.signUp({
        username: fields.email,
        password: fields.password
      });

      const payload = {
        Email: fields.email,
        FirstName: fields.firstName,
        LastName: fields.lastName,
        Phone: fields.phone,
        SuggestedStudioName: fields.suggestedStudioName,
      }
      console.log(`Creating profile`, payload)
      await API.post("apigw_anon", `/signup`, { body: payload, });

      console.log("Redirecting for validation")
      setIsLoading(false);
      props.history.push(`/signup/validate?email=${encodeURIComponent(fields.email)}`)
    } catch (e) {
      switch (e.code) {
        case "UsernameExistsException": {
          setExistingEmail(fields.email)
          break;
        }
        default: {
          console.error(e);
          alert(e.message);
          break;
        }
      }
      setIsLoading(false);
    }
  }

  return (
    <div className="Signup">
      <SignupEmailExists email={existingEmail} close={() => setExistingEmail(null)} {...props} />
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl autoFocus type="email" value={fields.email} onChange={handleFieldChange} />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControlPassword value={fields.password} onChange={handleFieldChange} />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl type="password" onChange={handleFieldChange} value={fields.confirmPassword} />
        </FormGroup>
        <FormGroup controlId="firstName" bsSize="large">
          <ControlLabel>First Name</ControlLabel>
          <FormControl type="text" value={fields.firstName} onChange={handleFieldChange} />
        </FormGroup>
        <FormGroup controlId="lastName" bsSize="large">
          <ControlLabel>Last Name</ControlLabel>
          <FormControl type="text" value={fields.lastName} onChange={handleFieldChange} />
        </FormGroup>
        <FormGroup controlId="phone" bsSize="large">
          <ControlLabel>Phone</ControlLabel>
          <FormControl type="text" value={fields.phone} onChange={handleFieldChange} />
        </FormGroup>
        <FormGroup controlId="suggestedStudioName" bsSize="large">
          <ControlLabel>Suggested Studio Name</ControlLabel>
          <FormControl type="text" value={fields.suggestedStudioName} onChange={handleFieldChange} />
        </FormGroup>
        <p>We consider privacy and security to be core functions of our platform, as well as foundational requirements for all new feature development.</p>
        <p>Earning and keeping the trust of our users is our top priority, so we hold ourselves to the highest privacy and security standards.</p>
        <p>You can read more about this in our <Link to="/privacy">Privacy Policy</Link>.</p>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
      </form>
    </div>
  );
}
