import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Label } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter';
import { setWeaverToolsProjects } from "../../actions";
import { API } from "aws-amplify";
import Debugger from "../../components/Debugger";

const Projects = ({ cognitoUsername, weaverToolsProjects, setWeaverToolsProjects, history }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function loadProjects() {
      return API.get("apigw", `/weavertools/projects`);
    }

    async function onLoad() {
      try {
        const weaverToolsProjects = await loadProjects();

        // Take the array of weaverToolsProjects returned from the server and mutate it as necessary
        const mutated = weaverToolsProjects.projects.map((project, i) => {
          return {
            ...project,
            _Areas: [project.LondonArea, project.NationwideArea].filter(each => each),
          }
        })
        // Convert the [project1, project2] into { [project1.id]: project1, [project2.id]: project2, ... }
        .reduce((acc, project) => {
          return { ...acc, [project.id]: project }
        }, {})

        setWeaverToolsProjects(mutated);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUsername]);
  // TODO: the above should make sure that unless the username changes that we don't ask the server for the data again ... but it seems like we are asking every time!

  function renderProjects() {
    const clickProjectRow = (event, row) => {
      history.push("projects/" + row.id + "/supply")
    }
  
    const optionsFor = field => {
      let fieldValues = Object.values(weaverToolsProjects).map((each) => { return each[field] })
        .flat(2)                // Flatten multi dimensional arrays (useful for multi value fields as well as those with a single value)
        .filter(each => each)   // Filter out any empty values
      let uniqueValues = new Set(fieldValues.sort())
  
      let object = {}
      uniqueValues.forEach(each => object[each] = each)
      console.log(`Getting unique values for ${field}:`, object)
      return object
    }
    const statusOptions = optionsFor("Status")
  
    const columns = [
      {
        text: 'Project Name',
        dataField: 'ProjectName',
        headerClasses: 'header-class-project-name',
        filter: textFilter(),
      },
      {
        text: 'Status',
        dataField: 'Status',
        headerClasses: 'header-class-status',
        formatter: (cell, row) => {
          if (!cell) return cell;
  
          let style = "default"
          switch (cell) {
            case "1. Uploaded":
              style = "warning"
              break
            case "2. Tendering": 
              style = "success"
              break
            default:
              style = "default"
          }
  
          return (<Label bsStyle={style}>{cell}</Label>)
        },
        filter: selectFilter({
          options: statusOptions,
          defaultValue: "",
        }),
      },
      {
        text: 'Project Description',
        dataField: 'ProjectDescription',
        headerClasses: 'header-class-project-description',
        filter: textFilter(),
      },
      {
        text: 'Weaver Builders',
        dataField: 'NumberOfWeaverBuildersTendering',
        headerClasses: 'header-class-weaver-builders',
        filter: numberFilter()
      },
      {
        text: 'Supply Interested',
        dataField: 'ProjectLeadInterested',
        headerClasses: 'header-class-supply-interested',
      },
      {
        text: 'Budget Range',
        dataField: 'BudgetRange',
        headerClasses: 'header-class-budget-range',
      },
      {
        text: 'London Area',
        dataField: 'LondonArea',
        headerClasses: 'header-class-london-areas',
      },
      {
        text: 'Nationwide Area',
        dataField: 'NationwideArea',
        headerClasses: 'header-class-nationwide-areas',
      },
    ]
  
    const rowEvents = {
      onClick: clickProjectRow
    }
  
    return (
      <BootstrapTable striped hover keyField="id" columns={columns} data={Object.values(weaverToolsProjects)} filter={filterFactory()} rowEvents={rowEvents} headerClasses="header-class" />
    )
  }

  return (
    <div className="WeaverToolsProjects">
      <Debugger models={{ weaverToolsProjects }}/>
      {cognitoUsername && !isLoading && renderProjects()}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    cognitoUsername: state.cognitoUsername,
    weaverToolsProjects: state.weaverToolsProjects,
  } 
}

const mapDispatchToProps = dispatch => {
  return {
    setWeaverToolsProjects: (model) => {
      dispatch(setWeaverToolsProjects(model))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects)