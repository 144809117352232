import React, { useEffect, useContext, useState } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import { Breadcrumb, Panel, Glyphicon, Button, ButtonToolbar, Grid, Row, Col } from "react-bootstrap";
import { RoleContext, AddressAreaContext, BudgetRangeContext, SourcingStageContext, ProjectContext, ProfessionalContext, SourcingContext, SourcingInviteContext, ProjectStageContext, RoleGroupContext, StudioContext } from "../model"
import Debugger from "../components/Debugger";
import SelfBlurringButton from "../components/SelfBlurringButton";
import { SourcingStudioInviter, SourcingWeaverInviter, getStudioInvitations } from "./SourcingInviter";
import { OverviewProfessionalProfile, FullProfessionalProfile } from "./SourcingProfessionalProfile"
import Spinner from "../components/Spinner";
import * as typeformEmbed from '@typeform/embed'
import logger from '../logger'
import "./Sourcing.css";
import QuickAddProfessional from "../components/QuickAddProfessional";
import ButtonWithTooltip from "../components/ButtonWithTooltip";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AnalyticsContext } from "../services/Analytics";

// -------------- SELECTION ---------------- //

const EmptyCard = ({ title }) => {
  console.debug("Rendering EmptyCard")
  return (
    <div className={`card cardProfessional emptySeat`}>
      {title}
    </div>
  )
}
const ProfessionalCard = ({ disabled, professional, invite }) => {
  console.debug("Rendering ProfessionalCard")

  const thisSourcing = useContext(ThisSourcingContext)

  const [profileShowing, setProfileShowing] = useState(false)

  const status = !invite ? "" :
    invite.status === "INVITED" ? " invited" :
      invite.status === "ACCEPTED" ? " inviteAccepted" :
        invite.status === "SELECTED" ? " inviteSelected" :
          invite.status === "REJECTED" ? " inviteRejected" : ""

  const invitePreferred = !invite ? "" : invite.preferred ? " invitePreferred" : ""

  if (!professional) {
    logger.error(`No professional to display for invite ${invite.id}`)
    return (
      <div className={`card cardProfessional${status}${invitePreferred}`}>
        <div className="field"><em>Unknown Professional</em></div>
      </div>
    )
  }

  return (
    <>
      <OverviewProfessionalProfile invite={invite} professional={professional} disabled={disabled} isShowing={profileShowing} close={() => setProfileShowing(false)} />
      <div className={`card cardProfessional${status}${invitePreferred}`} onClick={() => setProfileShowing(true)}>
        <div className="field">{professional.system === "STUDIO" || thisSourcing.userHasWeaverTools ? professional.company : `Pro #: ${professional.id}`}</div>
        {professional.system === "STUDIO" && thisSourcing.showProfessionalName ?
          <div className="field">{professional.firstName} {professional.lastName}</div>
          : null
        }
        {/* <div className="field lastAction">{invite.lastAction}</div> */}
      </div>
    </>
  )
}

const TenderProfessionalPanel = ({ disabled, professional, invite }) => {
  console.debug("Rendering TenderProfessionalPanel")

  const analytics = useContext(AnalyticsContext)
  const sourcingInvites = useContext(SourcingInviteContext)
  const thisSourcing = useContext(ThisSourcingContext)

  const [profileShowing, setProfileShowing] = useState(false)

  return (
    <Panel bsStyle={invite.status === "SELECTED" ? "success" : "default"}>
      <Panel.Heading>
        <Panel.Title>
          {invite.status === "SELECTED" ?
            <>
              <b>{professional.company}</b>
              <Button className="startContractingButton pull-right" disabled={disabled} onClick={() => thisSourcing.setStage("CONTRACTING")}>Start Contracting</Button>
            </> : professional.company}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <FullProfessionalProfile invite={invite} professional={professional} disabled={disabled} isShowing={profileShowing} close={() => setProfileShowing(false)} />
        <ButtonToolbar>
          <Button bsStyle="primary" className="moreInfoButton" disabled={disabled} onClick={() => setProfileShowing(true)}><Glyphicon glyph="info-sign" /> More Information</Button>
          <SelfBlurringButton bsStyle="success" className="selectButton" disabled={disabled} onClick={() => {
            if (analytics && analytics.event) analytics.event("Sourcing", "Select invite")
            return sourcingInvites.selectInvite(invite.id)
          }} active={invite.status === "SELECTED"}><Glyphicon glyph="user" /> Prefer Professional</SelfBlurringButton>
        </ButtonToolbar>
      </Panel.Body>
    </Panel>
  )
}

const TenderStagePanelList = ({ disabled }) => {
  console.debug("Rendering TenderStagePanelList")

  const thisSourcing = useContext(ThisSourcingContext)
  const [cards, setCards] = useState([])

  const professionals = useContext(ProfessionalContext)
  useEffect(() => {
    const invites = thisSourcing.tenderSeatInvites
    const mappedCards = invites.map(invite => !invite.id ? invite : {
      invite: invite,
      professional: professionals.getById(invite.professionalId),
    })
    setCards(mappedCards)
  }, [thisSourcing.tenderSeatInvites, professionals])

  return (
    <>
      {cards.map((each, index) => each.invite ? <TenderProfessionalPanel key={index} disabled={disabled} {...each} /> : null)}
    </>
  )
}

const TenderSeatsCardList = ({ disabled }) => {
  console.debug("Rendering TenderSeatsCardList")

  const thisSourcing = useContext(ThisSourcingContext)
  const [cards, setCards] = useState([])

  const analytics = useContext(AnalyticsContext);

  const professionals = useContext(ProfessionalContext)
  useEffect(() => {
    const invites = thisSourcing.tenderSeatInvites
    const mappedCards = invites.map(invite => !invite.id ? invite : {
      invite: invite,
      professional: professionals.getById(invite.professionalId),
    })
    setCards(mappedCards)
  }, [thisSourcing.tenderSeatInvites, professionals])

  const getStartTenderButtonDisabledMessage = () => {
    console.debug("getStartTenderButtonDisabledMessage: ", disabled, thisSourcing)
    if (disabled === true) return "" // I'm disabled, but I don't have a reason to offer

    const sourcing = thisSourcing.sourcing
    const inTheFuture = date => date ? date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) : false
    if (!inTheFuture(sourcing.tenderStartDate) || !inTheFuture(sourcing.tenderReturnDate) || !inTheFuture(sourcing.worksStartDate))
      return "You cannot start tendering until all the sourcing dates are in the future."

    if (!thisSourcing.isAllTenderSeatsFilled) return "All the tender seats must be filled with accepted professionals before you can start tendering!"

    // We're not disabled!
    return null
  }
  const isStartTenderButtonDisabled = () => getStartTenderButtonDisabledMessage() !== null

  const uploadTenderDocumentsClick = (e) => {
    e.preventDefault()
    if (analytics && analytics.event) analytics.event('Sourcing', 'Upload Tender Documents')
    const studioName = thisSourcing.currentStudio.name
    const sourcingId = thisSourcing.sourcing.id
    const projectPostcode = thisSourcing.project.addressPostcode
    typeformEmbed.makePopup(`https://weavertech.typeform.com/to/HWoRNc?studioname=${studioName}&sourcingid=${sourcingId}&projectpostcode=${projectPostcode}`, {
      mode: 'popup',
      autoOpen: true,
      autoClose: 5,
      onSubmit: function () {
        console.log('Typeform successfully submitted')
      }
    })
  }

  return (
    <Panel bsStyle="default" className="tenderSeatsCardList">
      <Panel.Heading>
        <Button className="minusButton pull-right" disabled={disabled} onClick={() => {
          if (analytics && analytics.event) analytics.event('Sourcing', 'Increment Tender Seats')
          return thisSourcing.tenderSeatsDecrement()
        }}><Glyphicon glyph="minus" /></Button>
        <Button className="plusButton pull-right" disabled={disabled} onClick={() => {
          if (analytics && analytics.event) analytics.event('Sourcing', 'Decrement Tender Seats')
          return thisSourcing.tenderSeatsIncrement()
        }}><Glyphicon glyph="plus" /></Button>
        <ButtonWithTooltip className="startTenderingButton pull-right" disabled={disabled} onClick={() => {
          if (analytics && analytics.event) analytics.event('Sourcing', 'Set Stage: TENDERING')
          return thisSourcing.setStage("TENDERING");
        }} tooltipCondition={isStartTenderButtonDisabled()} tooltipMessage={getStartTenderButtonDisabledMessage()}>Start Tendering</ButtonWithTooltip>
        <Button className="uploadTenderDocumentsButton pull-right" disabled={disabled} onClick={(e) => {
          if (analytics && analytics.event) analytics.event('Sourcing', 'Uploading Tender Documents')
          return uploadTenderDocumentsClick(e)
        }}>Upload Tender Documents</Button>
        <Panel.Title>{cards.length} Tender Seat{cards.length === 1 ? "" : "s"}</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <div className="cards">
          {cards.map((each, index) => each.invite ? <ProfessionalCard key={index} disabled={disabled} {...each} panel="TENDER-SEATS" /> : <EmptyCard key={index} title="Vacant Seat" />)}
        </div>
      </Panel.Body>
    </Panel>
  )
}

const InvitedStudioCardList = ({ disabled }) => {
  const thisSourcing = useContext(ThisSourcingContext)
  const professionals = useContext(ProfessionalContext)
  const sourcing = useContext(SourcingContext)
  const sourcingInvites = useContext(SourcingInviteContext)
  const projects = useContext(ProjectContext)
  const roles = useContext(RoleContext)

  const invites = thisSourcing.invitesStudio
    .filter(each => each.status !== "REJECTED")
    .filter(each => !thisSourcing.tenderSeatInvites.map(tsi => tsi.id).includes(each.id))

  // How many studio professionals would be shown if I displayed the inviter?
  const { theUninvited } = getStudioInvitations({ thisSourcing, professionals, sourcing, sourcingInvites, projects, roles })
  const uninvitedCount = Array.isArray(theUninvited) ? theUninvited.length : 0

  return (<InvitedCardList system="STUDIO" invites={invites} inviterComponentClass={SourcingStudioInviter} disabled={disabled} uninvitedCount={uninvitedCount} />)
}

const InvitedWeaverCardList = ({ disabled }) => {
  const thisSourcing = useContext(ThisSourcingContext)
  const invites = thisSourcing.invitesWeaver
    .filter(each => each.status !== "REJECTED")
    .filter(each => !thisSourcing.tenderSeatInvites.map(tsi => tsi.id).includes(each.id))

  return (<InvitedCardList system="WEAVER" invites={invites} inviterComponentClass={SourcingWeaverInviter} disabled={disabled} />)
}

const InvitedCardList = ({ invites, inviterComponentClass: Inviter, disabled, system, uninvitedCount }) => {
  console.debug("Rendering RejectedCardList")

  const analytics = useContext(AnalyticsContext);

  const [inviterIsShowing, setInviterIsShowing] = useState(false)
  const professionals = useContext(ProfessionalContext)
  const roleGroups = useContext(RoleGroupContext)
  const projectStages = useContext(ProjectStageContext)

  const thisSourcing = useContext(ThisSourcingContext)
  const projects = useContext(ProjectContext)
  const roles = useContext(RoleContext)
  const sourcing = thisSourcing.sourcing
  const project = !sourcing ? null : projects.getByTeamMemberId(sourcing.teamMemberId)
  const teamMember = !project ? null : project.team.filter(each => each.id === sourcing.teamMemberId)[0]
  const role = !teamMember ? null : roles.getById(teamMember.roleId)
  console.log("INVITED CARD LIST MODEL OBJECTS ", { sourcing, project, teamMember, role })

  const cards = invites.map(invite => {
    return {
      invite: invite,
      // TODO: Handle case where it's a Weaver Supply
      professional: professionals.getById(invite.professionalId),
    }
  })

  const [quickAddProfessionalShowing, setQuickAddProfessionalShowing] = useState(false)
  const quickAddClick = () => setQuickAddProfessionalShowing(true)
  const quickAddClose = () => setQuickAddProfessionalShowing(false)

  const bulkUploadClick = (e) => {
    e.preventDefault()
    if (analytics && analytics.event) analytics.event('Sourcing', 'Bulk Upload')
    typeformEmbed.makePopup("https://weavertech.typeform.com/to/QDJ2ZY", {
      mode: 'popup',
      autoOpen: true,
      autoClose: 5,
      onSubmit: function () {
        console.log('Typeform successfully submitted')
      }
    })
  }

  const getInviteButtonDisabledMessage = () => {
    if (disabled === true) return "" // I'm disabled, but I don't have a reason to offer
    if (system === "STUDIO" && !uninvitedCount) return "There are no studio professionals left for you to invite!"
    if (system === "STUDIO") return null

    // All these are weaver related
    const roleGroup = roleGroups.getById(role.roleGroupId)
    const projectStage = projectStages.getById(project.projectStageId)
    console.log("INVITE BUTTON", project, roleGroup, projectStage)
    if (roleGroup.name === "Contractor" && projectStage.title !== "Technical Design")
      return "You can only find Contractor roles from the Weaver Marketplace when the project is in the Technical Design stage."
    // if (roleGroup.name !== "Contractor")
    //   return "You can only find Contractor roles from the Weaver Marketplace."

    const inTheFuture = date => date ? date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) : false
    console.debug("Testing sourcing dates are in the future: ", sourcing, inTheFuture(sourcing.tenderStartDate), inTheFuture(sourcing.tenderReturnDate), inTheFuture(sourcing.worksStartDate))
    if (!inTheFuture(sourcing.tenderStartDate) || !inTheFuture(sourcing.tenderReturnDate) || !inTheFuture(sourcing.worksStartDate))
      return "You cannot source from the Weaver Marketplace unless all the sourcing dates are in the future."

    // We're not disabled!
    return null
  }
  const isInviteButtonDisabled = () => getInviteButtonDisabledMessage() !== null

  return (
    <Panel bsStyle="default">
      <Panel.Heading>
        <Panel.Title>
          Professionals Shortlisted
        <ButtonWithTooltip
            className="inviteButton pull-right"
            tooltipCondition={isInviteButtonDisabled()}
            tooltipMessage={(() => { const msg = getInviteButtonDisabledMessage(); console.log("INVITE BUTTON msg: ", msg); return msg })()}
            onClick={() => setInviterIsShowing(true)}
          ><Glyphicon glyph="plus" /></ButtonWithTooltip>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <QuickAddProfessional isShowing={quickAddProfessionalShowing} close={quickAddClose} role={role} />
        <Inviter isShowing={inviterIsShowing} close={() => setInviterIsShowing(false)} />
        <div className="cards">
          {cards.length ? cards.map((each, index) => <ProfessionalCard key={index} disabled={disabled} {...each} />) : system === "WEAVER" ? <EmptyCard title="Empty" /> : null}
        </div>
        {!uninvitedCount && system === "STUDIO" ? <div className="no-pros">
          <p>You have no more matching professionals in your studio.</p>
          <p>You can <Button className="inline-text-link" bsStyle="link" onClick={quickAddClick}>quickly add</Button> a professional, or <Button className="inline-text-link" bsStyle="link" onClick={bulkUploadClick}>bulk upload</Button> a spreadsheet.</p>
        </div> : null}
      </Panel.Body>
    </Panel>
  )
}

const RejectedStudioCardList = ({ disabled }) => {
  const thisSourcing = useContext(ThisSourcingContext)
  const invites = thisSourcing.invitesStudio
    .filter(each => each.status === "REJECTED")

  return (<RejectedCardList invites={invites} disabled={disabled} defaultExpanded={thisSourcing.sourcing.stage === "SHORTLISTING"} />)
}


const RejectedWeaverCardList = ({ disabled }) => {
  const thisSourcing = useContext(ThisSourcingContext)
  const invites = thisSourcing.invitesWeaver
    .filter(each => each.status === "REJECTED")

  return (<RejectedCardList invites={invites} disabled={disabled} />)
}

const RejectedCardList = ({ invites, disabled, defaultExpanded = false }) => {
  console.debug("Rendering RejectedCardList")

  const professionals = useContext(ProfessionalContext)

  const cards = invites.map(invite => {
    return {
      invite: invite,
      // TODO: Handle case where it's a Weaver Supply
      professional: professionals.getById(invite.professionalId),
    }
  })

  return (
    <Panel bsStyle="danger" defaultExpanded={defaultExpanded}>
      <Panel.Heading>
        <Panel.Title toggle>
          {cards.length} Professional{cards.length === 1 ? "" : "s"} Rejected
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        <div className="cards">
          {cards.length ? cards.map((each, index) => <ProfessionalCard key={index} disabled={disabled} {...each} />) : <EmptyCard title="No Rejects" />}
        </div>
      </Panel.Body>
    </Panel>
  )
}

const SourcingBreadcrumbs = ({ studio }) => {
  console.debug("Rendering SourcingBreadcrumbs")

  const thisSourcing = useContext(ThisSourcingContext)
  const projects = useContext(ProjectContext)
  const roles = useContext(RoleContext)

  const sourcing = thisSourcing.sourcing
  const project = !sourcing ? null : projects.getByTeamMemberId(sourcing.teamMemberId)
  const teamMember = !project ? null : project.team.filter(each => each.id === sourcing.teamMemberId)[0]
  const role = !teamMember ? null : roles.getById(teamMember.roleId)

  return (<Breadcrumb>
    <Breadcrumb.Item href="/studios">{studio ? studio.name : "Studio"}</Breadcrumb.Item>
    <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
    <Breadcrumb.Item active>Project: <b>{project && project.name}</b></Breadcrumb.Item>
    <Breadcrumb.Item active>Team Member: <b>{role && role.name}</b></Breadcrumb.Item>
    <Breadcrumb.Item active>Stage: <b>{sourcing && sourcing.stage}</b></Breadcrumb.Item>
  </Breadcrumb>)
}

const WarningBullet = ({ pass, messageGood, messageBad = messageGood }) => {
  return pass
    ? (<p className="warning-good"><Glyphicon glyph="check" /> {messageGood}</p>)
    : (<p className="warning-bad"><Glyphicon glyph="unchecked" />  {messageBad}</p>)
}

const ReadyForWeaverWarning = ({ role, addressArea, budgetRange }) => {
  const roleGroups = useContext(RoleGroupContext)
  const roleGroup = roleGroups.getById(role.roleGroupId)

  const conditions = [
    {
      pass: (roleGroup && roleGroup.name === "Contractor"),
      messageGood: "The role to be sourced must be in the Contractor group.",
      messageBad: `The role to be sourced must be in the Contractor group. It is in the ${(roleGroup && roleGroup.name) || "UNKNOWN"} group.`,
    },
    { pass: (addressArea), messageGood: "The project geographic area must be specified.", },
    { pass: (budgetRange), messageGood: "The project budget must be specified.", },
  ]

  // Start with TRUE and while still TRUE, use the next condition's pass result -- once we hit a FALSE, we carry that forward and out
  const passesAllConditions = conditions.reduce((acc, each) => acc === false ? false : each.pass, true)
  return passesAllConditions ? null : (
    <Panel bsStyle="danger">
      <Panel.Heading>
        <Panel.Title>
          Unable to source from the Weaver Marketplace
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <p>In order to source professionals from the Weaver Marketplace the following conditions need to be met:</p>
        {conditions.map((each, i) => (<WarningBullet key={i} {...each} />))}
        <p>You can edit the project and team member data to fix.</p>
      </Panel.Body>
    </Panel>
  )
}

const SourcingDatesField = ({ fieldName, title, onChange, minDate, maxDate, highlightDates, disabled }) => {
  const thisSourcing = useContext(ThisSourcingContext)
  const sourcing = thisSourcing.sourcing
  const date = sourcing[fieldName]
  const inTheFuture = () => date ? date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) : false

  console.debug("Rendering SourcingDatesField", sourcing, date, fieldName, minDate, maxDate, highlightDates, disabled)

  return (
    <div className={`field ${inTheFuture() ? "future" : "not-future"}`}>
      <div className="title">{title}</div>
      <ReactDatePicker
        dateFormat="dd MMMM yyyy"
        todayButton="Today"
        placeholderText="Click to select a date"
        minDate={minDate}
        maxDate={maxDate}
        highlightDates={highlightDates}
        selected={date}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}
const SourcingDates = ({ disabled }) => {
  const analytics = useContext(AnalyticsContext)
  const thisSourcing = useContext(ThisSourcingContext)
  const sourcing = thisSourcing.sourcing

  return (
    <Panel bsStyle="default" className="sourcingDates">
      <Panel.Heading>
        <Panel.Title>Sourcing Dates</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <div className="container">
          <SourcingDatesField fieldName="tenderStartDate" title="Tender Start Date"
            onChange={(date) => {
              if (analytics && analytics.event) analytics.event("Sourcing", "Set tender start date")
              return thisSourcing.setTenderStartDate(date)
            }}
            minDate={new Date()}
            maxDate={sourcing.tenderReturnDate || sourcing.worksStartDate || null}
            highlightDates={[sourcing.tenderReturnDate, sourcing.worksStartDate]}
            disabled={disabled}
          />
          <SourcingDatesField fieldName="tenderReturnDate" title="Tender Return Date"
            onChange={(date) => {
              if (analytics && analytics.event) analytics.event("Sourcing", "Set tender return date")
              return thisSourcing.setTenderReturnDate(date)
            }}
            minDate={sourcing.tenderStartDate || new Date()}
            maxDate={sourcing.worksStartDate || null}
            highlightDates={[sourcing.tenderStartDate, sourcing.worksStartDate]}
            disabled={disabled}
          />
          <SourcingDatesField fieldName="worksStartDate" title="Works Start Date"
            onChange={(date) => {
              if (analytics && analytics.event) analytics.event("Sourcing", "Set tender start date")
              return thisSourcing.setWorksStartDate(date)
            }}
            minDate={sourcing.tenderReturnDate || sourcing.tenderStartDate || new Date()}
            highlightDates={[sourcing.tenderStartDate, sourcing.tenderReturnDate]}
            disabled={disabled}
          />
        </div>
      </Panel.Body>
    </Panel>
  )
}

const ShortlistingStage = () => {
  console.debug("Rendering ShortlistingStage")

  const thisSourcing = useContext(ThisSourcingContext)
  const projects = useContext(ProjectContext)
  const roles = useContext(RoleContext)
  const addressAreas = useContext(AddressAreaContext)
  const budgetRanges = useContext(BudgetRangeContext)

  const sourcing = thisSourcing.sourcing
  const project = !sourcing ? null : projects.getByTeamMemberId(sourcing.teamMemberId)
  const teamMember = !project ? null : project.team.filter(each => each.id === sourcing.teamMemberId)[0]

  const role = !teamMember ? null : roles.getById(teamMember.roleId)
  const addressArea = addressAreas.getById(project && project.addressAreaId)
  const budgetRange = budgetRanges.getById(project && project.budgetRangeId)

  const disabled = !sourcing || sourcing.stage !== "SHORTLISTING"

  return (
    <Panel bsStyle="default" className={disabled ? "stage-disabled" : ""}>
      {/* <Debugger models={{thisSourcing: thisSourcing}} /> */}
      <Grid>
        <Panel.Body>
          <Row>
            <h2>Shortlisting Professionals</h2>
            <p>
              Here we build a shortlist of professionals who fill our tender seats as they confirm their availability.
              Once the seats are full, we start the tendering process.
              Professionals must supply <b>{(role && role.name) || "UNKNOWN"}</b> services to the <b>{(addressArea && addressArea.name) || "UNKNOWN"}</b> area,
              for projects with an estimated budget of <b>{(budgetRange && budgetRange.name) || "UNKNOWN"}</b>.
            </p>
            <ReadyForWeaverWarning {...{ role, addressArea, budgetRange }} />
          </Row>
          <Row>
            <Col md={12}><SourcingDates disabled={disabled} /></Col>
          </Row>
          <Row>
            <Col md={12}><TenderSeatsCardList disabled={disabled} /></Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3>My Studio</h3>
              <InvitedStudioCardList disabled={disabled} />
              <RejectedStudioCardList disabled={disabled} />
            </Col>
            <Col md={6}>
              <h3>Weaver Marketplace</h3>
              <InvitedWeaverCardList disabled={disabled} />
              <RejectedWeaverCardList disabled={disabled} />
            </Col>
          </Row>
        </Panel.Body>
      </Grid>
    </Panel>
  )
}

const TenderingStage = () => {
  console.debug("Rendering TenderingStage")

  const thisSourcing = useContext(ThisSourcingContext)
  const sourcing = thisSourcing.sourcing
  const disabled = !sourcing || sourcing.stage !== "TENDERING"

  return (
    <Panel bsStyle="default" className={disabled ? "stage-disabled" : ""}>
      {/* <Debugger models={{thisSourcing: thisSourcing}} /> */}
      <Grid>
        <Panel.Body>
          <Row>
            <h2>Tendering</h2>
            <p>Here we control the tendering process, with a set of tools to aid us. Here select the preferred professional and move into contracting with them.</p>
          </Row>
          <Row>
            <Col md={12}><TenderStagePanelList /></Col>
          </Row>
        </Panel.Body>
      </Grid>
    </Panel>
  )
}

const ContractingStage = () => {
  console.debug("Rendering ContractingStage")

  const analytics = useContext(AnalyticsContext);

  const thisSourcing = useContext(ThisSourcingContext)
  const [card, setCard] = useState(null)

  const [profileShowing, setProfileShowing] = useState(false)

  const professionals = useContext(ProfessionalContext)
  useEffect(() => {
    const leadInvite = thisSourcing.tenderSeatInvites[0]
    setCard(!leadInvite || leadInvite.status !== "SELECTED" ? null : {
      invite: leadInvite,
      professional: professionals.getById(leadInvite.professionalId)
    })
  }, [thisSourcing.tenderSeatInvites, professionals])

  const sourcing = thisSourcing.sourcing
  const disabled = !sourcing || sourcing.stage !== "CONTRACTING"

  return (
    <Panel bsStyle="default" className={disabled ? "stage-disabled" : ""}>
      {/* <Debugger models={{thisSourcing: thisSourcing}} /> */}
      <Grid>
        <Panel.Body>
          <Row>
            <h2>Contracting</h2>
            <p>We now enter negotiations with the preferred professional. Once concluded successfully, we have successfully sourced our team member.</p>
          </Row>
          <Row>
            <Col md={12}>
              {card ?
                <Panel bsStyle="default">
                  <Panel.Heading>
                    <Panel.Title>
                      <b>{card.professional.company}</b>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <FullProfessionalProfile invite={card.invite} professional={card.professional} disabled={disabled} isShowing={profileShowing} close={() => setProfileShowing(false)} />
                    <ButtonToolbar>
                      <Button bsStyle="primary" className="moreInfoButton" disabled={disabled} onClick={() => setProfileShowing(true)}><Glyphicon glyph="info-sign" /> More Information</Button>
                    </ButtonToolbar>
                  </Panel.Body>
                </Panel>
                : null}
              <ButtonToolbar>
                <Button bsStyle="success" className="contractButton" disabled={disabled} onClick={() => {
                  if (analytics && analytics.event) analytics.event("Sourcing", "Set Stage: COMPLETED")
                  return thisSourcing.setStage("COMPLETED");
                }}>Contract Complete</Button>
                <Button bsStyle="warning" className="rejectButton" disabled={disabled} onClick={() => {
                  if (analytics && analytics.event) analytics.event("Sourcing", "Set Stage: TENDERING")
                  return thisSourcing.setStage("TENDERING")
                }}><Glyphicon glyph="remove" /> Return to Tendering</Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Panel.Body>
      </Grid>
    </Panel>
  )
}

// -------------- PRIMARY ---------------- //

// COMPONENT

const UnknownSourcingId = () => {
  console.debug("Rendering UnknownSourcingId")

  const thisSourcing = useContext(ThisSourcingContext)

  return (
    <Panel bsStyle="danger">
      <Panel.Heading>
        <Panel.Title>
          Unable to locate Sourcing
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <p>We cannot locate a Sourcing available to you by the reference <b>{thisSourcing.sourcingId}</b>.</p>
        <p>Weaver have been notified of the attempt. Please <a href="mailto:my@weaver.com">contact Weaver</a> if the error persists.</p>
        <p><Link to="/projects">Back to Projects</Link></p>
      </Panel.Body>
    </Panel>
  )
}

// COMPONENT

const Sourcing = ({ match, currentStudio, userProfile }) => {
  console.debug("Rendering Sourcing")

  const sourcing = useContext(SourcingContext)
  const sourcingInvites = useContext(SourcingInviteContext)
  const sourcingStages = useContext(SourcingStageContext)
  const projects = useContext(ProjectContext)

  const sourcingId = match.params.id

  const [thisSourcing, setThisSourcing] = useState({
    sourcingId: null,
    sourcing: null,
    invitesStudio: [],
    invitesWeaver: [],
    tenderSeatInvites: [],
    userHasWeaverTools: userProfile && userProfile.HasWeaverTools,
    currentStudio: currentStudio,
    project: null,
  })
  thisSourcing.getInvitesBySourceType = (sourceType) => !sourcing ? [] : sourcing.getInvitesBySourceType(sourcingId, sourceType)
  thisSourcing.getOrderedInvites = () => !sourcing ? [] : sourcing.getOrderedInvites(sourcingId)
  thisSourcing.getOrderedInvitesForFilledTenderSeats = () => !sourcing ? [] : sourcing.getOrderedInvitesForFilledTenderSeats(sourcingId)
  thisSourcing.getOrderedInvitesForAllTenderSeats = () => !sourcing ? [] : sourcing.getOrderedInvitesForAllTenderSeats(sourcingId)
  thisSourcing.tenderSeatsIncrement = () => !sourcing ? null : sourcing.tenderSeatsIncrement(sourcingId)
  thisSourcing.tenderSeatsDecrement = () => !sourcing ? null : sourcing.tenderSeatsDecrement(sourcingId)
  thisSourcing.setTenderStartDate = (date) => !sourcing ? null : sourcing.setTenderStartDate(sourcingId, date)
  thisSourcing.setTenderReturnDate = (date) => !sourcing ? null : sourcing.setTenderReturnDate(sourcingId, date)
  thisSourcing.setWorksStartDate = (date) => !sourcing ? null : sourcing.setWorksStartDate(sourcingId, date)
  thisSourcing.setStage = (stage) => !sourcing ? null : sourcing.setStage(sourcingId, stage)

  useEffect(() => {
    console.info("Sourcing Stages", sourcingStages)
    console.info("Projects", projects)

    const sourcingObj = sourcing.getById(sourcingId)
    setThisSourcing({
      ...thisSourcing,
      sourcingId: sourcingId,
      sourcing: sourcingObj,
      invitesStudio: thisSourcing.getInvitesBySourceType("STUDIO"),
      invitesWeaver: thisSourcing.getInvitesBySourceType("WEAVER"),
      allInvites: thisSourcing.getOrderedInvites(),
      tenderSeatFilled: thisSourcing.getOrderedInvitesForFilledTenderSeats(),
      tenderSeatInvites: thisSourcing.getOrderedInvitesForAllTenderSeats(),
      setStage: thisSourcing.setStage,
      isAllTenderSeatsFilled: sourcing.isAllTenderSeatsFilled(sourcingId),
      project: sourcingObj && sourcingObj.teamMemberId ? projects.getByTeamMemberId(sourcingObj.teamMemberId) : null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcingId, sourcing, sourcingInvites])

  if (sourcing.isLoading || sourcingInvites.isLoading || sourcingStages.isLoading || projects.isLoading)
    return (<Spinner />)

  return (
    <ThisSourcingContext.Provider value={thisSourcing}>
      <div className="sourcing">
        <Debugger models={{ thisSourcing: thisSourcing }} />
        {thisSourcing.sourcing ?
          <>
            <SourcingBreadcrumbs studio={currentStudio} />
            <ShortlistingStage />
            {["TENDERING", "CONTRACTING", "COMPLETED"].includes(thisSourcing.sourcing.stage) ? <TenderingStage /> : null}
            {["CONTRACTING", "COMPLETED"].includes(thisSourcing.sourcing.stage) ? <ContractingStage /> : null}
          </>
          : <UnknownSourcingId />}
      </div>
    </ThisSourcingContext.Provider>
  )
}

export const ThisSourcingContext = React.createContext("ThisSourcingContext-defaultValue")

const mapStateToProps = state => ({
  currentStudio: state.currentStudio,
  userProfile: state.userProfile,
})
export default connect(mapStateToProps)(Sourcing)
