import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { PageHeader, Panel } from "react-bootstrap";
import Spinner from "../components/Spinner";
import { setUserStudios, setCurrentStudio } from "../actions";
import { API } from "aws-amplify";
import logger from '../logger'

const StudioSelecter = ({ cognitoUsername, userStudios, currentStudio, setUserStudios, onStudioClick, history }) => {
  console.log("State: ", cognitoUsername, userStudios, currentStudio, typeof (setUserStudios), typeof (onStudioClick), typeof (history))
  const [isLoading, setIsLoading] = useState(true);

  console.log("StudioSelector effect limited by cognito username: ", cognitoUsername)
  useEffect(() => {
    function loadUserStudios() {
      return API.get("apigw", `/studios`);
    }

    async function onLoad() {
      try {
        const userStudios = await loadUserStudios();
        setUserStudios(userStudios);
      } catch (e) {
        alert(e);
        logger.error("Error loading user studios", e)
      }

      setIsLoading(false);
    }

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUsername]);
  // TODO: the above should make sure that unless the username changes that we don't ask the server for the data again ... but it seems like we are asking every time!

  function selectStudio(e, studio) {
    console.log(`Selecting the studio: ${studio.name}`);
    onStudioClick(studio);
    history.push("/projects");
  }

  function renderStudios(userStudios) {
    // If I don't have any studios, then ask the user to contact us
    if (!userStudios || userStudios.length === 0) {
      return (
        <Panel bsStyle="warning">
          <Panel.Heading>
            <Panel.Title componentClass="h3">No Studios!</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            You have no architecture studios associated with your account. Please contact <a href="https://weaver.build/">Weaver</a> to register your interest in MyWeaver.
            </Panel.Body>
        </Panel>
      )
    }

    // If I have only one studio, select it automatically
    if (userStudios.length === 1) {
      selectStudio(null, userStudios[0])
      return
    }

    // If I have multiple studios, let me choose
    const studioList = userStudios.map((eachStudio, i) =>
      (
        // Instead of linking ... onclick, set the session studio state and go to the homepage
        <Panel key={eachStudio.id} onClick={(e) => selectStudio(e, eachStudio)} bsStyle={eachStudio.name === currentStudio ? "success" : "default"}>
          <Panel.Heading>
            <Panel.Title componentClass="h3">{eachStudio.name}</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div style={{ color: "lightgrey" }}><b>ID:</b> <span style={{ marginLeft: '10px' }}>{eachStudio.id}</span></div>
            <div style={{ color: "grey" }}><b>Access Levels:</b> {
              eachStudio.accessLevels.map((level, ii) =>
                (<span key={level} style={{ marginLeft: '10px' }}>{level}</span>)
              )
            }</div>
          </Panel.Body>
        </Panel>
      )
    )
    return (
      <>
        <PageHeader>Select your Studio</PageHeader>
        {studioList}
      </>
    )
  }

  return (
    <div className="MyStudios">
      <div className="userStudios">
        {isLoading && <Spinner />}
        {cognitoUsername && !isLoading && renderStudios(userStudios)}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    cognitoUsername: state.cognitoUsername,
    userStudios: state.userStudios,
    currentStudio: state.currentStudio
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserStudios: (model) => {
      console.log("Update user studios model: ", model)
      dispatch(setUserStudios(model))
    },
    onStudioClick: studio => {
      console.log("Update the selected studio: ", studio)
      dispatch(setCurrentStudio(studio))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudioSelecter)