import React from "react";
import { Panel, Glyphicon } from "react-bootstrap";

const PrivateStudioProfessionalMessage = () => {
  return (
    <Panel bsStyle="info">
      <Panel.Heading>
        <Panel.Title componentClass="h3"><Glyphicon glyph="lock" /> Your Studio Professionals</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <p>These are your studio's <b>private</b> professionals, only visible to your studio architects, for internal use <b>only</b>.</p>
        <p>They are not seen by other studios, nor are they used by Weaver for other studio's projects.</p>
        <p>The professionals themselves cannot see any sensitive information you add, such as the notes and your ratings.</p>
      </Panel.Body>
    </Panel>
  )
}

export default PrivateStudioProfessionalMessage