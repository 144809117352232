import React, { useState } from "react";
import { Panel, Button, ButtonToolbar } from "react-bootstrap";
import Debugger from "../../components/Debugger";
import { API } from "aws-amplify";

const SyncSupplyBudgetAddressAreas = () => {
  const [syncData, setSyncData] = useState(null);

  // Make it so!
  const doIt = () => {
    API.post("apigw", `/weavertools/syncSupplyBudgetAddressAreas`)
      .then(results => {
        setSyncData(results)
      })
  }

  return (
    <div className="syncSupplyProfessionals">
      <Debugger models={{ debugging: { syncData } }} />
      <Panel>
        <Panel.Heading>
          <Panel.Title>Update the Budget Ranges, Address Areas, Tags for each Supply</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <p>
            This weaver tool takes each Supply record, and where they don't exist as linked rows in Professional, it creates a Professional.
            If you don't see anything in the list below, then there are no Professionals to create and link.
          </p>
          <pre>
            <b>Pseudocode for the Do It action:</b><br />
            > For each Supply record (all of them) of the Marketplace database<br />
            > > Merge the LondonAreas and NationwideAreas into one list<br />
            > > Convert the merged areas into a comma separated list of RecordIDs based on a Name match against the MW_AddressAreas in the MyWeaver database, filtering out missing values<br />
            > > Convert the BudgetRanges into a comma separated list of RecordIDs based on a Name match against the MW_BudgetRanges in the MyWeaver database, filtering out missing values<br />
            > > Convert the Tags into a comma separated list of RecordIDs based on a Name match against the MW_ProfessionalTags in the MyWeaver database, filtering out missing values<br />
            > > Update the Supply record AddressAreasId, BudgetRangesId, and TagsId fields
          </pre>
          <ButtonToolbar>
            <Button bsStyle="success" onClick={doIt}>Do it!</Button>
          </ButtonToolbar>
        </Panel.Body>
      </Panel>
    </div>
  )
}

export default SyncSupplyBudgetAddressAreas
