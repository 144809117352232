import React, { useState, useContext, useEffect } from "react";
import { connect } from 'react-redux';
import { AnalyticsContext } from '../services/Analytics';
import { Badge, Label, Panel, ButtonToolbar, Button } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter, customFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import Spinner from "../components/Spinner";
import Rating from 'react-rating'
import RatingFilter from '../components/RatingFilter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarFull } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import { setContacts } from "../actions";
import { API } from "aws-amplify";
import * as typeformEmbed from '@typeform/embed'
import "./Contacts.css"
import Debugger from "../components/Debugger";
import PrivateStudioProfessionalMessage from "../components/PrivateStudioProfessionalMessage";

export const loadContacts = async ({ currentStudio, setContacts }) => {
  try {
    console.log("Loading the contacts for studio: ", currentStudio)
    const contacts = await API.get("apigw", `/contacts`, {
      headers: { StudioAuthorization: `Bearer ${currentStudio.jwt}` }
    });
    // Take the array of contacts returned from the server and mutate it as necessary
    const mutated = contacts.contacts.map((contact, i) => {
      const _firstName = contact.FirstName ? contact.FirstName : ""
      const _lastName = contact.LastName ? contact.LastName : ""

      const numberOrZero = value => typeof value === "number" ? value : 0
      const roundHalf = value => Math.round(value * 2) / 2

      return {
        ...contact,
        _Name: _firstName && _lastName ? `${_firstName} ${_lastName}` : `${_firstName}${_lastName}`,
        _AverageRating: roundHalf((
          numberOrZero(contact.BudgetRating) +
          numberOrZero(contact.CommsRating) +
          numberOrZero(contact.ProjectManagementRating) +
          numberOrZero(contact.TimingRating)
        ) / 4),
      }
    })
      // Convert the [contact1, contact2] into { [contact1.id]: contact1, [contact2.id]: contact2, ... }
      .reduce((acc, contact) => {
        return { ...acc, [contact.id]: contact }
      }, {})

    setContacts(mutated);
  } catch (e) {
    alert(e);
  }
}

const Contacts = ({ contacts, currentStudio, setContacts, cognitoUsername, history }) => {
  console.log("Contacts State: ", contacts, currentStudio, setContacts, cognitoUsername)
  const [isLoadingContacts, setIsLoadingContacts] = useState(true);
  const [isLoadingGlobals, setIsLoadingGlobals] = useState(true);

  const defaultModel = {
    isLoading: false,
    lastUpdated: null,
    data: [],
  }

  const [roleGroups, setRoleGroups] = useState(defaultModel);
  const [roles, setRoles] = useState(defaultModel);
  const [addressAreas, setAddressAreas] = useState(defaultModel);
  const [budgetRanges, setBudgetRanges] = useState(defaultModel);
  const [professionalTags, setProfessionalTags] = useState(defaultModel);

  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    console.log("Using effect")

    async function onLoad() {
      console.log("On load...")
      if (!cognitoUsername) {
        console.log("You're not logged in! Can't show you anything.")
        return;
      }

      await loadContacts({ currentStudio, setContacts });
      setIsLoadingContacts(false);
    }

    // Load Roles
    (async () => {
      console.info("Loading the global constants")
      API.get("apigw", "/global")
        .then(response => {
          console.debug("Found global constants", response)

          const firstCharLower = string => string.charAt(0).toLowerCase() + string.slice(1)
          const remapFieldsWithFirstCharLower = (object, mappers = {}) => Object.entries(object).reduce((acc, [key, value]) => ({
            ...acc,
            // Lower case the first character of each key, and if there is a mapper by the same name, call the mapper on the value
            [firstCharLower(key)]: mappers[firstCharLower(key)] ? mappers[firstCharLower(key)](value) : value
          }), {})
          const extractSingleValueIfArray = value => Array.isArray(value) ? value[0] : value

          setRoleGroups({ ...roleGroups, data: response.RoleGroups.map(record => remapFieldsWithFirstCharLower(record)) })
          setRoles({ ...roles, data: response.Roles.map(record => remapFieldsWithFirstCharLower(record, { "roleGroupId": extractSingleValueIfArray })) })
          setBudgetRanges({ ...budgetRanges, data: response.BudgetRanges.map(record => remapFieldsWithFirstCharLower(record)) })
          setAddressAreas({ ...addressAreas, data: response.AddressAreas.map(record => remapFieldsWithFirstCharLower(record)) })
          setProfessionalTags({ ...professionalTags, data: response.ProfessionalTags.map(record => remapFieldsWithFirstCharLower(record)) })

          setIsLoadingGlobals(false)
        })
    })();

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUsername, currentStudio]);
  // TODO: the above should make sure that unless the username changes that we don't ask the server for the data again ... but it seems like we are asking every time!

  const clickContactRow = (event, row) => {
    history.push("/contact/" + row.id)
  }

  // Take a list, look up the id and use the mapping to manipulate the resulting object  
  const getGlobalById = (list, id, mapping) => {
    return mapping(list.filter(each => each.id === id)[0])
  }
  const optionsFor = (field, mapper) => {
    let fieldValues = Object.values(contacts).map((each) => { return each[field] })
      .flat(2)                // Flatten multi dimensional arrays (useful for multi value fields as well as those with a single value)
      .filter(each => each)   // Filter out any empty values
    let uniqueValues = [...new Set(fieldValues.sort())]

    // Build an object keys by the data value, with the value of the label to use (supporting a mapper to map from the data value to a given name)
    let object = {}
    uniqueValues.forEach(each => object[each] = mapper ? mapper(each) : each)
    console.log(`Getting unique values for ${field}:`, object)
    return object
  }
  // Get an object keyed by IDs of roles used in the data, and map the label to the name of the role
  const roleOptions = optionsFor("RolesId", id => getGlobalById(roles.data, id, each => each ? each.name : null))
  const statusOptions = optionsFor("Status")
  const tagsOptions = professionalTags.data.reduce((acc, each) => ({ ...acc, [each.id]: each.name }), {})

  const columns = [
    {
      text: 'Company',
      dataField: 'Company',
      headerClasses: 'header-class-company',
      filter: textFilter(),
    },
    {
      text: 'Roles',
      dataField: 'RolesId',
      headerClasses: 'header-class-type',
      formatter: (cell, row) => {
        return cell ? cell.map(each => (<Badge key={each}>{getGlobalById(roles.data, each, each => each ? each.name : null)}</Badge>)) : cell
      },
      filter: selectFilter({
        options: roleOptions,
        defaultValue: "",
        comparator: Comparator.LIKE,
      }),
    },
    {
      text: 'Name',
      dataField: '_Name',
      headerClasses: 'header-class-name',
      filter: textFilter(),
    },
    {
      text: 'Status',
      dataField: 'Status',
      headerClasses: 'header-class-status',
      formatter: (cell, row) => {
        if (!cell) return cell;

        let style = "default"
        switch (cell) {
          case "Used":
            style = "success"
            break
          case "Never Used":
            style = "warning"
            break
          case "Unknown":
            style = "info"
            break
          case "Sin Bin":
            style = "danger"
            break
          default:
            style = "default"
        }

        return (<Label bsStyle={style}>{cell}</Label>)
      },
      filter: selectFilter({
        options: statusOptions,
        defaultValue: "",
      }),
    },
    {
      text: 'Tags',
      dataField: 'TagsId',
      headerClasses: 'header-class-tags',
      classes: 'column-tags',
      formatter: (cell, row) => {
        return cell ? cell.map(each => (<Badge key={each}>{tagsOptions[each]}</Badge>)) : cell
      },
      filter: selectFilter({
        options: tagsOptions,
        defaultValue: "",
        comparator: Comparator.LIKE,
      }),
    },
  ]

  const rowEvents = {
    onClick: clickContactRow
  }

  const showBulkUploadMessage = () => {
    return Object.values(contacts).length < 20
  }

  const bulkUploadClick = (e) => {
    e.preventDefault()
    if (analytics && analytics.event) analytics.event("Contact List", "Bulk Upload")
    typeformEmbed.makePopup("https://weavertech.typeform.com/to/QDJ2ZY", {
      mode: 'popup',
      autoOpen: true,
      autoClose: 5,
      onSubmit: function () {
        console.log('Typeform successfully submitted')
      }
    })
  }

  return (
    // https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
    <div className="MyContacts">
      <Debugger models={{ models: { isLoadingGlobals, isLoadingContacts, currentStudio, contacts, tagsOptions } }} />
      {(isLoadingGlobals || isLoadingContacts) && <Spinner />}
      {currentStudio && !isLoadingContacts && !isLoadingGlobals && showBulkUploadMessage() &&
        (<>
          <Panel bsStyle="info">
            <Panel.Heading>
              <Panel.Title componentClass="h3">Upload Contacts in Bulk!</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              Did you know that you can upload your contacts in bulk by <Button className="inline-text-link" bsStyle="link" onClick={bulkUploadClick} style={{ cursor: 'pointer' }}>uploading a file here</Button> saving you having to create your contacts one by one
          </Panel.Body>
          </Panel>
        </>)}
      {currentStudio && !isLoadingContacts && !isLoadingGlobals && (
        <>
          <PrivateStudioProfessionalMessage />
          <BootstrapTable striped hover keyField="id" columns={columns} data={Object.values(contacts)} filter={filterFactory()} rowEvents={rowEvents} headerClasses="header-class" />
        </>
      )}
      <ButtonToolbar bsClass="btn-toolbar pull-left">
        <Button onClick={bulkUploadClick} bsStyle="success">Bulk Upload Contacts</Button>
      </ButtonToolbar>
      <ButtonToolbar bsClass="btn-toolbar pull-right">
        <Button onClick={() => history.push("/contact/new")} bsStyle="success">New Contact</Button>
      </ButtonToolbar>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    cognitoUsername: state.cognitoUsername,
    contacts: state.contacts,
    contactForm: state.contactForm,
    currentStudio: state.currentStudio
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContacts: (model) => {
      dispatch(setContacts(model))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contacts)