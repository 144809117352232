import React from "react";
import { connect } from 'react-redux';
import { PageHeader } from "react-bootstrap";

const SupplyOverview = ({ project }) => {
  const leadsSent = Array.isArray(project.ProjectLeadSent) ? project.ProjectLeadSent.length : 0
  const leadsInterested = Array.isArray(project.ProjectLeadInterested) ? project.ProjectLeadInterested.length : 0
  const leadsNotInterested = Array.isArray(project.ProjectNotLeadInterested) ? project.ProjectNotLeadInterested.length : 0

  return (
    <>
      <PageHeader>Project: {project.ProjectName} ({project.Status})</PageHeader>
      <p>We are looking for {3 * project.NumberOfWeaverBuildersTendering} supply near {project.ProjectPostcode} with a budget range of {project.BudgetRange}. So far, {leadsSent} leads have been sent, {leadsInterested} are interested and {leadsNotInterested} are not.</p>
    </>
  )
}

export default connect()(SupplyOverview)
