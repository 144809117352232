import React from "react";
import { DataModelRedux } from '../model'
import AppliedRoute from "./AppliedRoute";

export default function DataAppliedRoute(props) {
  return (
    <DataModelRedux>
      <AppliedRoute {...props} />
    </DataModelRedux>
  );
}
