import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { AnalyticsContext } from "../services/Analytics";

function Tracker(props) {
  const analytics = useContext(AnalyticsContext);
  if (analytics.event) {
    analytics.event("PAGE_VIEW", props.location.pathname);
  }
  return props.children
}

export default function TrackedRoute(props) {
  return (
    <Route {...props} render={(renderProps) => <Tracker location={props.location}>{props.render(renderProps)}</Tracker>} />
  );
}
