import config from '../config';

export const lookupAddressesByPostcode = async postcode => {
  console.log(`Looking up ${postcode} from address.io`)
  return !postcode ? null : fetch(`https://api.getAddress.io/find/${postcode.replace(/[^A-Za-z0-9]/g, '')}?api-key=${config.addressIO.API_KEY}&expand=true`)
    .then(res => res.json())
    .then(json => {
      console.log("AddressIO Fetch Response: ", json)
      if (!json || !Array.isArray(json.addresses)) {
        return []
      }

      const addresses = json.addresses.map(each => ({
        area: each.town_or_city === "London" || each.county === "Greater London" ? each.district : each.county,
        full: each.formatted_address.filter(line => line).join("\n"),
      }))
      console.debug("Address options are: ", addresses)
      return addresses
    })
}