import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { FullProfessionalProfile, WeaverOverviewProfessionalProfileModal } from "../SourcingProfessionalProfile"
import config from '../../config';

export const StandaloneContext = React.createContext({})

const ShowProfessionalProfile = ({ full, match }) => {
  console.log("Rendering ShowProfessionalProfile", full, match)
  const [professional, setProfessional] = useState({});

  const defaultModel = {
    isLoading: true,
    lastUpdated: null,
    data: [],
  }
  const [standalone, setStandalone] = useState({
    professionalTags: defaultModel,
    addressAreas: defaultModel,
    budgetRanges: defaultModel,
  })
  const getById = model => id => model.data.filter(each => each.id === id)[0]
  const getNameById = model => id => model.getById(id) ? model.getById(id).name : null
  standalone.professionalTags.getById = getById(standalone.professionalTags)
  standalone.professionalTags.getNameById = getNameById(standalone.professionalTags)
  standalone.budgetRanges.getById = getById(standalone.budgetRanges)
  standalone.budgetRanges.getNameById = getNameById(standalone.budgetRanges)
  standalone.addressAreas.getById = getById(standalone.addressAreas)
  standalone.addressAreas.getNameById = getNameById(standalone.addressAreas)

  const proId = match.params.id
  useEffect(() => {
    (async () => {
      console.log("loading data")
      const firstCharLower = string => string.charAt(0).toLowerCase() + string.slice(1)
      const remapFieldsWithFirstCharLower = (object, mappers = {}) => Object.entries(object).reduce((acc, [key, value]) => ({
        ...acc,
        // Lower case the first character of each key, and if there is a mapper by the same name, call the mapper on the value
        [firstCharLower(key)]: mappers[firstCharLower(key)] ? mappers[firstCharLower(key)](value) : value
      }), {})
      const extractSingleValueIfArray = value => Array.isArray(value) ? value[0] : value
      const convertToDate = value => value ? new Date(value) : value
      const convertToBoolean = value => value ? true : false
      const extractSingleDateValueIfArray = value => convertToDate(extractSingleValueIfArray(value))
      const extractSingleBooleanValueIfArray = value => convertToBoolean(extractSingleValueIfArray(value))

      await API.get("apigw_anon", `/global`)
        .then(response => {
          setStandalone({
            ...standalone,
            professionalTags: {
              ...standalone.professionalTags,
              isLoading: false,
              data: response.ProfessionalTags.map(record => remapFieldsWithFirstCharLower(record)),
            },
            addressAreas: {
              ...standalone.addressAreas,
              isLoading: false,
              data: response.AddressAreas.map(record => remapFieldsWithFirstCharLower(record)),
            },
            budgetRanges: {
              ...standalone.budgetRanges,
              isLoading: false,
              data: response.BudgetRanges.map(record => remapFieldsWithFirstCharLower(record)),
            },
          })
        })
      await fetch(`${config.apiGateway.URL}/weaver/${proId}`)
        .then(res => res.json())
        .then(response => {
          console.debug("Got response from /weaver/{pro}", response)

          const arrayFromCSV = value => typeof value === "string" ? value.replace(/[^A-Za-z0-9,]+/g, "").split(",") : Array.isArray(value) ? value : []
          const numberOrZero = value => typeof value === "number" ? value : 0
          const roundHalf = value => Math.round(value * 2) / 2
          const mappedProfessionals = response.map(record => ({
            // Identify the source
            system: "WEAVER",
            // Compute the average rating for the professional
            averageRating: roundHalf((
              numberOrZero(extractSingleValueIfArray(record.BudgetRating)) +
              numberOrZero(extractSingleValueIfArray(record.CommsRating)) +
              numberOrZero(extractSingleValueIfArray(record.ProjectManagementRating)) +
              numberOrZero(extractSingleValueIfArray(record.TimingRating))
            ) / 4),
            // Initalise the arrays
            rolesId: [],
            addressAreasId: [],
            budgetRangesId: [],
            // Copying the fields passed in
            ...remapFieldsWithFirstCharLower(record, {
              "company": extractSingleValueIfArray,
              "firstName": extractSingleValueIfArray,
              "lastName": extractSingleValueIfArray,
              "website": extractSingleValueIfArray,
              "tenderCount": extractSingleValueIfArray,
              "budgetRating": extractSingleValueIfArray,
              "commsRating": extractSingleValueIfArray,
              "projectManagementRating": extractSingleValueIfArray,
              "timingRating": extractSingleValueIfArray,
              "reference1": extractSingleValueIfArray,
              "reference2": extractSingleValueIfArray,
              "portfolioImage1": extractSingleValueIfArray,
              "portfolioImage2": extractSingleValueIfArray,
              "portfolioImage3": extractSingleValueIfArray,
              "portfolioImage4": extractSingleValueIfArray,
              "insurancePublicLiabilityLimit": extractSingleValueIfArray,
              "insuranceEmployerLiabilityLimit": extractSingleValueIfArray,
              "insuranceRenewalDate": extractSingleDateValueIfArray,
              "companyEstablishedYear": extractSingleValueIfArray,
              "vettingCompanyDirectorBankruptcy": extractSingleBooleanValueIfArray,
              "vettingCompanyDebt": extractSingleBooleanValueIfArray,
              "dateAdded": extractSingleValueIfArray,
              "leadsVsTenderRatio": extractSingleValueIfArray,
              "lastLead": extractSingleValueIfArray,
              // TODO: The below for rolesId - which is currently just a single string array, which works fine for a pro with a single role.
              // Convert addressAreasId / budgetRangesId / tags to an array
              "addressAreasId": value => arrayFromCSV(extractSingleValueIfArray(value)),
              "budgetRangesId": value => arrayFromCSV(extractSingleValueIfArray(value)),
            }),
          }))

          console.log("mapped pros:", mappedProfessionals)
          setProfessional(mappedProfessionals[0])
        })
    })()
  }, [proId])

  return (
    <StandaloneContext.Provider value={standalone}>
      {full
        ? <FullProfessionalProfile professional={professional} isShowing={(typeof professional.id === "string")} close={() => setProfessional({})} />
        : <WeaverOverviewProfessionalProfileModal professional={professional} isShowing={(typeof professional.id === "string")} close={() => setProfessional({})} />
      }
    </StandaloneContext.Provider>
  )
}

export default ShowProfessionalProfile
