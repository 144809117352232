import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Button } from "react-bootstrap";

/**
 * A button which blurs itself after the onClick returned promise completed
 * 
 * @param {*} onClick The action performed when the button is clicked, must return a promise that we chain onto
 * @param {*} props All remaining properites are passed through to the button
 */
const SelfBlurringButton = (props) => {
  const [buttonRef, setButtonRef] = useState()
  return (<Button {...props} ref={setButtonRef} onClick={() => props.onClick().then(() => buttonRef && ReactDOM.findDOMNode(buttonRef).blur())} />)
}

export default SelfBlurringButton
