import React from "react";
import { Navbar, Panel, Button } from "react-bootstrap";
import "./App.css";

const App = () => {
  return (
    <div className="App container">
      <Navbar fluid collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <a href="https://my.weaver.build"><img className="logo" alt="" src="/logo.png" /></a>
          </Navbar.Brand>
        </Navbar.Header>
      </Navbar>
      <Panel bsStyle="default">
        <Panel.Body>
          <h3>A Faster &amp; Better MyWeaver</h3>
          <p>
            The software dev team led by Dan, Weaver's <b>Co-founder &amp; CTO</b>,
            have been working hard behind the scenes on a new platform.
          </p>
          <p>
            We're super excited to be releasing this today - we call it <b>v2.0</b>!
          </p>
          <p>
            You can <a href="https://www.notion.so/weaverbuild/What-s-new-since-v1-9ca91d5811674c59bb80ac20ca2ba93a">see the release notes</a> or <a href="https://my.weaver.build">dive straight in</a>!
          </p>
          <Button bsStyle="success" href="https://my.weaver.build">Take me to v2</Button>
        </Panel.Body>
      </Panel>
    </div>
  );
}

export default App;
