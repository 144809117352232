import React, { useEffect, useContext, useState } from "react";
import { AnalyticsContext } from "../services/Analytics";
import { Glyphicon, Button, Grid, Row, Col, Modal, Label } from "react-bootstrap";
import { RoleContext, AddressAreaContext, BudgetRangeContext, ProjectContext, ProfessionalContext, SourcingContext, SourcingInviteContext } from "../model"
import { ThisSourcingContext } from "./Sourcing"
import Debugger from "../components/Debugger";
import RatingStars from "../components/RatingStars";
import TimeAgo from 'react-timeago'
import "./SourcingInviter.css"

// -------------- PRIMARY ---------------- //

// COMPONENT

const EmptyCard = ({ hasFilters, system }) => {
  console.debug("Rendering EmptyCard", hasFilters)

  return (
    <Grid className="emptyCard">
      <Row>
        <Col md={12}>
          <p>
            There are no professionals with your current settings.
            Perhaps add some professionals which match the project's role, geographic area and budget range?
          </p>
          {system === "STUDIO" ? <p>Have you considered using the Weaver Marketplace to fill this role?</p> : null}
        </Col>
      </Row>
    </Grid>
  )
}
const InviteProfessionalCard = ({ card, onCardClick }) => {
  console.debug("Rendering InviteProfessionalCard", card)

  return (
    <Grid className={`inviteProfessionalCard${card.selected ? " selected" : ""}`} onClick={() => onCardClick(card)}>
      <Row>
        <Col md={1}>
          <Glyphicon className="selectionToggle" glyph={card.selected ? "star" : "star-empty"} />
        </Col>
        <Col md={11}>
          <Row>
            <Col md={8}>
              {card.fields.system === "STUDIO" ?
                <><b>Pro: </b>{card.fields.company} ({card.fields.name})</>
                :
                <><b>Pro #: </b>{card.fields.reference}</>
              }
            </Col>
            <Col md={3}><b>Avg Rating: </b><RatingStars value={card.fields.averageRating} /></Col>
          </Row>
          <Row>
            <Col md={11}>{card.fields.tags.map((each, i) => <Label key={i}>{each}</Label>)}</Col>
          </Row>
          <Row>
            <Col md={5}><b>Time as {card.fields.system === "STUDIO" ? "Studio" : "Weaver"} Pro: </b><TimeAgo date={card.fields.dateAdded} /></Col>
            <Col md={3}></Col>
            <Col md={3}><b># Tenders: </b>{card.fields.tenderCount}</Col>
          </Row>
          <Row>
            <Col md={11}>
              {card.fields.system === "STUDIO" ?
                <><b>Notes: </b>{card.fields.notes ? <pre>{card.fields.notes}</pre> : <pre><em>none</em></pre>}</>
                :
                <><b>Reference: </b>{card.fields.reference1 ? <pre>{card.fields.reference1}</pre> : <pre><em>none</em></pre>}</>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  )
}

// COMPONENT

const lengthIfUndefined = (array, ifUndefined = 0) => Array.isArray(array) ? array.length : ifUndefined

export const getStudioInvitations = ({ thisSourcing, professionals, sourcing, sourcingInvites, projects, roles }) => {
  const project = !thisSourcing || !thisSourcing.sourcing ? {} : projects.getByTeamMemberId(thisSourcing.sourcing.teamMemberId)
  const teamMember = project && project.team && project.team.filter(each => each.id === thisSourcing.sourcing.teamMemberId)[0]

  const role = roles.getById(teamMember && teamMember.roleId)

  const systemPros = professionals.getProfessionalsFromStudio()

  const systemFilteredPros = systemPros.filter(pro => !role || pro.rolesId.includes(role.id))

  const allInvites = sourcingInvites.getAllInvites(thisSourcing.sourcingId)                       // Get all the invites for this sourcing
  const allNetworkInvites = allInvites.filter(each => each.sourceType === "STUDIO")               // Filter the invites to just those in our network 
  const allNetworkInvitedIds = allNetworkInvites.map(each => each.professionalId)                 // Grab the professional ID for each invite
  const theUninvited = systemFilteredPros.filter(each => !allNetworkInvitedIds.includes(each.id)) // Filter out those who have already been invited (regardless of status)

  const remainingInviteCount = (lengthIfUndefined(thisSourcing.tenderSeatInvites) - lengthIfUndefined(thisSourcing.tenderSeatFilled)) * 3
  const outstandingNetworkInvites = allNetworkInvites.filter(each => each.status === "INVITED").length
  const inviteTarget = remainingInviteCount - outstandingNetworkInvites > 0 ? remainingInviteCount - outstandingNetworkInvites : 0

  return { theUninvited, inviteTarget, role, project, systemFilteredPros }
}

// This is now only for the STUDIO system
const SourcingInviter = ({ isShowing, close, system }) => {
  console.debug("Rendering SourcingInviter")
  const analytics = useContext(AnalyticsContext)
  const thisSourcing = useContext(ThisSourcingContext)
  const professionals = useContext(ProfessionalContext)
  const sourcing = useContext(SourcingContext)
  const sourcingInvites = useContext(SourcingInviteContext)
  const projects = useContext(ProjectContext)
  const roles = useContext(RoleContext)

  const { theUninvited, inviteTarget, role, project, systemFilteredPros } = getStudioInvitations({ thisSourcing, professionals, sourcing, sourcingInvites, projects, roles })

  // There are <b>{thisSourcing && thisSourcing.sourcing && thisSourcing.sourcing.tenderSeatCount}</b> tender seats, 
  // of which <b>{thisSourcing.tenderSeatFilled.length}</b> are already allocated, 
  // so we want a total of <b>{remainingInviteCount}</b> invites for those remaining seats, 
  // but <b>{outstandingNetworkInvites}</b> invites from <em>{systemNetwork}</em> are already out and waiting for a response, 
  // so we should invite up to <b>{inviteTarget}</b> more professionals.

  // TODO: Tidy up data model where it has double referencing (not needed, can be addressed by functions on the list end - sourcingInvites.getForSourcing() ... should simplify updates/creates )

  const [cards, setCards] = useState([])

  useEffect(() => {
    // Reset the selections to default when shown and any of filters change
    if (isShowing) {
      setCards(theUninvited.map((pro, index) => ({
        professional: pro,
        fields: {
          id: pro.id,
          system: pro.system,
          reference: pro.id, // Ultimately this will be the airtable record reference minus the "rec" ... doesnt matter as long as it's unique and ideally non-sequential
          company: pro.company,
          name: `${pro.firstName} ${pro.lastName}`,
          averageRating: pro.averageRating,
          tags: Array.isArray(pro.tags) ? pro.tags : [],
          dateAdded: pro.dateAdded, // The date the professional was added to the system - back dated in the case of Weaver pro
          tenderCount: pro.tenderCount,
          notes: pro.notes,
          reference1: pro.reference1,
        },
        selected: index < inviteTarget,
      })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing, role])

  const onCardClick = (clickedCard) => {
    setCards(cards.map(card => card !== clickedCard ? card : { ...card, selected: !card.selected }))
  }

  const save = () => {
    if (analytics && analytics.event) analytics.event("Shortlister", "Inviting from Studio")
    const inviteList = cards.filter(card => card.selected)
    sourcing.addInvites(thisSourcing.sourcingId, inviteList)
      .then(close)
  }

  return (
    <Modal show={isShowing} onHide={close} dialogClassName="inviter-modal">
      <Modal.Header closeButton>
        <Modal.Title>Shortlist Professionals from your Studio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Debugger models={{ info: { project, pros: systemFilteredPros } }} />
        <p>Below there are currently <b>{cards.filter(card => card.selected).length}</b> selected.</p>
        {cards.length ? cards.map(card => <InviteProfessionalCard key={card.professional.id} card={card} onCardClick={onCardClick} />) :
          <EmptyCard system="STUDIO" />
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => save()} bsStyle="success">Confirm Interest</Button>
        <Button onClick={() => close()} bsStyle="link">Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

export const SourcingStudioInviter = (props) => {
  console.debug("Rendering SourcingStudioInviter")
  return (<SourcingInviter {...props} system="STUDIO" />)
}

export const SourcingWeaverInviter = ({ isShowing, close }) => {
  console.debug("Rendering SourcingWeaverInviter")

  const system = "WEAVER"

  const analytics = useContext(AnalyticsContext)
  const thisSourcing = useContext(ThisSourcingContext)
  const professionals = useContext(ProfessionalContext)
  const sourcing = useContext(SourcingContext)
  const sourcingInvites = useContext(SourcingInviteContext)

  const projects = useContext(ProjectContext)
  const project = !thisSourcing || !thisSourcing.sourcing ? {} : projects.getByTeamMemberId(thisSourcing.sourcing.teamMemberId)
  const teamMember = project && project.team && project.team.filter(each => each.id === thisSourcing.sourcing.teamMemberId)[0]

  const roles = useContext(RoleContext)
  const addressAreas = useContext(AddressAreaContext)
  const budgetRanges = useContext(BudgetRangeContext)
  const role = roles.getById(teamMember && teamMember.roleId)
  const addressArea = addressAreas.getById(project && project.addressAreaId)
  const budgetRange = budgetRanges.getById(project && project.budgetRangeId)

  const systemPros = professionals.getProfessionalsFromWeaver()

  const filterRoleId = role && role.id
  const filterAddressAreaId = addressArea && addressArea.id
  const filterBudgetRangeId = budgetRange && budgetRange.id

  // Currently only sorting by Last Lead ascending - we're ignoring the LVTRatio descending for now
  const sortByLastLead = (a, b) => a.lastLead > b.lastLead ? 1 : a.lastLead < b.lastLead ? -1 : 0
  // const sortByLVTRatio = (a, b) => a.leadsVsTenderRatio < b.leadsVsTenderRatio ? 1 : a.leadsVsTenderRatio > b.leadsVsTenderRatio ? -1 : 0

  const systemFilteredPros = systemPros.filter(pro => {
    return (pro.rolesId.includes(filterRoleId)
      && pro.addressAreasId.includes(filterAddressAreaId)
      && pro.budgetRangesId.includes(filterBudgetRangeId)
      && pro.availableForUse
    )
  }).sort(sortByLastLead)

  const allInvites = sourcingInvites.getAllInvites(thisSourcing.sourcingId)                       // Get all the invites for this sourcing
  const allNetworkInvites = allInvites.filter(each => each.sourceType === system)                  // Filter the invites to just those in our network 
  const allNetworkInvitedIds = allNetworkInvites.map(each => each.professionalId)                 // Grab the professional ID for each invite
  const theUninvited = systemFilteredPros.filter(each => !allNetworkInvitedIds.includes(each.id)) // Filter out those who have already been invited (regardless of status)

  const remainingInviteCount = (lengthIfUndefined(thisSourcing.tenderSeatInvites) - lengthIfUndefined(thisSourcing.tenderSeatFilled)) * 3
  const outstandingNetworkInvites = allNetworkInvites.filter(each => each.status === "INVITED").length
  const inviteTarget = remainingInviteCount - outstandingNetworkInvites > 0 ? remainingInviteCount - outstandingNetworkInvites : 0

  const [cards, setCards] = useState([])

  useEffect(() => {
    // Reset the selections to default when shown and any of filters change
    if (isShowing) {
      setCards(theUninvited.map((pro, index) => ({
        professional: pro,
        fields: {
          id: pro.id,
          system: pro.system,
          reference: pro.id, // Ultimately this will be the airtable record reference minus the "rec" ... doesnt matter as long as it's unique and ideally non-sequential
          company: pro.company,
          name: `${pro.firstName} ${pro.lastName}`,
          averageRating: pro.averageRating,
          tags: Array.isArray(pro.tags) ? pro.tags : [],
          dateAdded: pro.dateAdded, // The date the professional was added to the system - back dated in the case of Weaver pro
          tenderCount: pro.tenderCount,
          notes: pro.notes,
          reference1: pro.reference1,
        },
        selected: index < inviteTarget,
      })).slice(0, inviteTarget))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing, sourcingInvites])

  const save = () => {
    if (analytics && analytics.event) analytics.event("Shortlister", "Inviting from Weaver")
    const inviteList = cards.filter(card => card.selected)
    sourcing.addInvites(thisSourcing.sourcingId, inviteList)
      .then(close)
  }

  return (
    <Modal show={isShowing} onHide={close} dialogClassName="inviter-modal">
      <Modal.Header closeButton>
        <Modal.Title>Shortlist Professionals from the Weaver Marketplace</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Debugger models={{ info: { project, addressAreas, addressArea, pros: systemFilteredPros } }} />
        {inviteTarget === 0 ?
          <p>The shortlist is currently complete and does not need any further professionals. Please contact the Weaver team if you need further help with sourcing for this team member.</p>
          : <>
            <p>
              There are <b>{systemFilteredPros.length}</b> professionals on the Weaver Marketplace that match the sourcing for this project's team member.
              {cards.length ?
                <>Click the button at the bottom to send out leads and get confirmation of professional availability.</>
                :
                <>Please contact the Weaver team to help with sourcing for this team member.</>}
            </p>
            {cards.length ? cards.map(card => <InviteProfessionalCard key={card.professional.id} card={card} onCardClick={() => {/* do nothing */ }} />) : null}
          </>}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => save()} bsStyle="success">Confirm Interest</Button>
        <Button onClick={() => close()} bsStyle="link">Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}
