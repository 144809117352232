import React, { useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import "./Login.css";
import { Auth } from "aws-amplify";
import FormControlPassword from "../components/FormControlPassword";
import queryString from 'query-string'
import './ResetPassword.css'

export default function ResetPassword(props) {
  console.debug("Rendering ResetPassword", props)
  const queryValues = queryString.parse(props.location.search)

  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    code: (queryValues.code) || "",
    email: (queryValues.email) || "",
    password: "",
    confirmPassword: "",
  });

  function validateForm() {
    return fields.code.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    try {
      await Auth.forgotPasswordSubmit(fields.email, fields.code, fields.password)
        .then(() => props.history.push("/login?from=reset"))
    } catch (e) {
      alert(e.message);
    }
    setIsLoading(false);
  }

  return (
    <div className="ResetPassword">
      <form onSubmit={handleSubmit}>
        <p>Check your email for the code to enter below and reset your password.</p>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="code" bsSize="large">
          <ControlLabel>Code</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={fields.code}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>New Password</ControlLabel>
          <FormControlPassword
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Reset Password
        </LoaderButton>
      </form>
    </div >
  );
}
